import { createSlice } from "@reduxjs/toolkit";

// initialize userToken from local storage
const userToken = localStorage.getItem("Access-Token")
  ? localStorage.getItem("Access-Token")
  : null;
const initialState = {
  loading: false,
  userInfo: {}, // for user object
  userToken, // for storing the JWT
  error: null,
  success: false, // for monitoring the registration process.
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("Access-Token"); 
      localStorage.removeItem("company-name");// deletes token from storage
      return {
        ...initialState
      }
    },
    setCredentials: (state, action) => {
      return {
        userInfo : action.payload,
        userToken : action.payload.token
      }
    },
  },
  extraReducers: {},
});
export const { setCredentials, logout } = authSlice.actions;
export default authSlice.reducer;
