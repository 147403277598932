import { Component, useState, useEffect } from "react";
import axios from "axios";
import Icons from "../../assets/icons/index";
import RegBackgroundImage from "../../assets/images/register-bg.png";
import UseRegFrom from "./UseRegForm";
import "react-toastify/dist/ReactToastify.css";
import Logo from "../../assets/images/ecom-brand-logo.png";
import LogoSmall from "../../assets/images/ecom-brand-small.png";
import { Notification } from "../common/Notification";
import GetCountryList from "../common/GetCountryList";
import { GetAxiosCall, PostAxiosCall } from "../../utils/commonutils";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../utils/commonutils";
import { Button, Checkbox, message } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const Register = () => {
  const { values, errors, countryData, handleChange, handleSubmit } =
    UseRegFrom(register);
  const [serverErrors, setErrors] = useState([]);
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [regBtnDisable, setRegBtnDisable] = useState(false);
  let apiUrl = ApiEndPoint();

  const handlePhone = (value, country, e) => {
    //validatePhoneLength(country, value, e);
    countryData.countrySelected = country.dialCode;
    handleChange(e);
  };

  const handleCountry = (e) => {
    values.phone = "";
    setRegBtnDisable(false);
    let countryCode = e.target.selectedOptions[0].getAttribute("country_code");
    values.country_code = countryCode;
    handleChange(e);
  };

  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "login") {
      navigate("/");
    }
  };

  /* Validate phone number length - onchange event*/
  function validatePhoneLength(countryObj, phone, event) {
    let dialCode = countryObj.dialCode; //Get dialcode of country (Ex: +91)
    dialCode = dialCode.toString();
    phone = phone.toString();
    let phoneNumber = phone.replace(dialCode, ""); //replace dialcode from user input
    //errors.phone = "";
    setRegBtnDisable(false);
    /* Minimum of 9 digits is required as of now as the component accepts phone numbers according to the country */
    if (phoneNumber.length == 0) {
      values.phone = "";
      values.country_code = null;
    }
    if (phoneNumber.length != 0 && phoneNumber.length < 9) {
      //setRegBtnDisable(true);
      //errors.phone = "Phone number is too short!";
    }
  }

  function register() {
    setButtonLoading(true);
    /* Request Body */
    let requestBody = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      phone: values.phone,
      company_name: values.company_name,
      country_id: values.country_id,
    };
    /* Service to register */
    let url = "partner/register";
    let response = "";
    //let response = PostAxiosCall("POST", url, JSON.stringify(requestBody), "");
    //axios call block
    let baseUrl = apiUrl + url;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": getApiKey(),
    };

    try {
      async function registerPartner() {
        response = await axios(baseUrl, {
          method: "POST",
          data: requestBody,
          headers: header,
        }).then((response) => {
          setButtonLoading(false);
          if (response) {
            if (response.success === true && response.status === 200) {
              setErrors([]);
              message.success(response.message);
              message.destroy();
              navigate("/");
            } else if (response.status == 422) {
              setErrors([]);
              /* Show server side validation errors */
              let validationErrors = response.data;
              var errorMsg = "Please fix the error(s)";
              /* Iterate errors and append to the error message */
              Object.keys(validationErrors).map((key, value) => {
                validationErrors[key].map((value, ind) =>
                  setErrors((data) => ({
                    ...data,
                    [key]: value,
                  }))
                );
              });
              setErrors(validationErrors);
              message.error(errorMsg);
              message.destroy();
            } else {
              setErrors([]);
              message.error("Oops!! Something went wrong");
              message.destroy();
            }
          }
        });
      }
      registerPartner();
      message.destroy();
    } catch (error) {
      throw new Error(error);
    }
    //axios call block
  }

  return (
    <div>
      {/*--- Header ---*/}
      <header className="header-auth w-auto">
        <img src={Logo} alt="logo" className="d-none d-lg-block" />
        <img src={LogoSmall} alt="logo" className="d-block d-lg-none" />
      </header>
      {/*--- End header ---*/}

      {/*--- Page Content ---*/}
      <div className="container register">
        <div className="row h-100 flex-column-reverse flex-lg-row">
          <div className="col-12 col-lg-6 pb-3">
            <div className="text-container">
              <h1 className="mb-xl-3 mb-2">What we provide!</h1>
              <p>
                A centralized platform to manage marketplaces, digital
                marketing, direct-to-consumer, global expansion, first-party
                retail, drop ship and fulfillment needs.
              </p>
              <ul>
                <li className="mt-xl-4 mt-3">
                   We help stores, brands and distributors find millions of new customers by connecting them to the biggest marketplaces
                </li>
                <li className="mt-xl-4 mt-3">
                   Increases occupancy, saves time, boosts direct bookings and maximizes online sales. 
                </li>
                <li className="mt-xl-4 mt-3">
                   Connecting with customers, supporting third-party partners and managing vendors.
                </li>
              </ul>
              <img src={RegBackgroundImage} alt="" className="mt-4 mb-4 w-50" />
              <br />
            </div>
          </div>
          <div
            className="col-12 col-lg-6 col-xl-6 pb-sm-1"
            style={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #0D4835e6",
            }}
          >
            <div className="login-container h-100 px-0">
              <div className="card auth">
                <form
                  onSubmit={handleSubmit}
                  onKeyDown={(e) =>
                    e.code === "Enter" || e.code === "NumpadEnter"
                      ? handleSubmit(e)
                      : ""
                  }
                >
                  <h1 className="h4 text-uppercase text-primary-color">
                    Create An Account
                  </h1>
                  <p className="text-secondary text-justify">
                    Enter your details in the form
                  </p>
                  <div className="container">
                    <div className="row">
                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          First Name<span className="text-danger"> *</span>
                        </label>
                        <div className="input-icon">
                          <img src={Icons.nameIcon} alt="" />
                          <input
                            type="text"
                            name="first_name"
                            placeholder="First Name"
                            maxLength={25}
                            value={values.first_name || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.first_name || serverErrors.first_name}
                        </span>
                      </div>
                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          Last Name<span className="text-danger"> *</span>
                        </label>
                        <div className="input-icon">
                          <img src={Icons.nameIcon} alt="" />
                          <input
                            type="text"
                            name="last_name"
                            placeholder="Last Name"
                            maxLength={25}
                            value={values.last_name || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.last_name || serverErrors.last_name || ""}
                        </span>
                      </div>
                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          Email<span className="text-danger"> *</span>
                        </label>
                        <div className="input-icon">
                          <img src={Icons.emailIcon} alt="" />
                          <input
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            maxLength={50}
                            value={values.email || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.email || serverErrors.email || ""}
                        </span>
                      </div>
                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          Country<span className="text-danger"> *</span>
                        </label>
                        <div className="input-icon">
                          <img src={Icons.countryIcon} alt="" />
                          <GetCountryList
                            name="country_id"
                            //onChange={handleChange}
                            onChange={handleCountry}
                            value={values.country_id}
                            country_code={values.country_code || ""}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.country_id || serverErrors.country_id || ""}
                        </span>
                      </div>
                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          Phone<span className="text-danger"> *</span>
                        </label>
                        <PhoneInput
                          country={values.country_code || ""}
                          disableDropdown={true}
                          countryCodeEditable={false}
                          name="phone"
                          value={values.phone || ""}
                          enableSearch={true}
                          placeholder="Phone Number"
                          inputProps={{ name: "phone" }}
                          containerStyle={{ width: "100px !important" }}
                          inputStyle={{ width: "100%" }}
                          onChange={handlePhone}
                        />
                        <span className="text-danger">
                          {errors.phone || serverErrors.phone || ""}
                        </span>
                      </div>

                      <div className="col-12 col-md-6 card-input mt-2 p-2">
                        <label className="text-primary-color">
                          Company<span className="text-danger"> *</span>
                        </label>
                        <div className="input-icon">
                          <img src={Icons.companyIcon} alt="" />
                          <input
                            type="text"
                            name="company_name"
                            placeholder="Company Name"
                            maxLength={50}
                            value={values.company_name || ""}
                            onChange={handleChange}
                          />
                        </div>
                        <span className="text-danger">
                          {errors.company_name || serverErrors.company_name}
                        </span>
                      </div>

                      <div className="form-action mt-2">
                        <Checkbox
                          name="partner_policy"
                          onClick={(event) => {
                            handleChange(event,"checkbox")
                          }}
                        />
                        <label
                          htmlFor="partner_policy"
                          className="text-justify text-secondary"
                        >
                          By checking the box, you confirm that you agree to the
                          storing and processing of your personnel data by My
                          E-com Wizard as described in the Privacy policy
                        </label>
                      </div>
                      <span className="text-danger">
                        {errors.partner_policy}
                      </span>
                    </div>
                  </div>
                  <div className="login-button mt-4">
                    <Button
                      className="bg-primary-color"
                      title="Register"
                      onClick={handleSubmit}
                      loading={buttonLoading}
                      disabled={regBtnDisable}
                    >
                      Register
                    </Button>
                  </div>
                </form>
                <p className="mt-2 text-center">
                  Already have an Account?{" "}
                  <a  className="text-primary-color"  onClick={onClick} name="login" title="Login">
                    Login
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*--- End Page Content ---*/}
    </div>
  );
};

export default Register;
