import {
    Result
  } from "antd";
const EmailVerification = () => {
    return(
        <>
        <Result
            status="warning"
            title="Partner's email not verified!"
            subTitle="Please follow up once the email is verified."
            
        ></Result>
        </>
    );

}
export default EmailVerification;