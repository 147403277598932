import { Form, Space, Input, Button } from "antd";
import { DataTable } from "../../../common/Table";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
function MarketplaceCategoryDashboard(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [mode, setMode] = useOutletContext();
  const categoryData = [];
  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const tableHeading = [
    {
      title: "Cat ID",
      dataIndex: "catid",
      sorter: (a, b) => a.catid - b.catid,
      ...getColumnSearchProps("catid", "SKU"),
    },
    {
      title: "Category Name",
      dataIndex: "catname",
      ...getColumnSearchProps("title", "Category Name"),
    },
    {
      title: "Category Path",
      dataIndex: "catpath",
      sorter: (a, b) => a.catpath - b.catpath,
    },
  ];
  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };
  useEffect(() => {
    setMode("");
  }, []);
  return (
    <div className="container">
      <div className="border-info card mb-4 mt-3 w-100">
        <div className="table-content">
          <div className="container">
            <div className="mt-3 table-wrapper w-100 overflow-auto mt-1">
              <Form form={form} component={false}>
                <DataTable
                  columns={tableHeading}
                  data={categoryData}
                  bordered
                  loading={loading}
                  pagination={{
                    position: ["bottomCenter"],
                    defaultPageSize: 100,
                    showSizeChanger: true,
                    showQuickJumper: true,
                  }}
                />
              </Form>
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
          <div>
            <nav aria-label="Page navigation"></nav>
          </div>
          <div>
            <p className="m-3"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MarketplaceCategoryDashboard;
