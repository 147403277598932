
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Slider from "react-slick";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import { Empty, message, notification, Spin } from "antd";
function RenewSubscription({ setUpgradePlan }) {
  const [subsctiptionPlans, setSubscriptionPlans] = useState("");
  const [subscriptionDuration, setSubscriptionDuration] = useState(30);
  const [availableDurations, setAvailableDurations] = useState({});
  const navigate = useNavigate();
  const [loading, setloading] = useState(false);
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  let counter = 0;
  function handleDurationClick(event) {
    let value = event.target.value;
    setSubscriptionDuration(value);
    fetchSubscriptions(true, value);
  }
  var settings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    arrows: true,
    className: "sub-card-slider sub--1",
    swipeToSlide: true,
    accessibility: true,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1008,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  //gets available subscriptions
  function fetchSubscriptions(group, subscription_duration) {
    let body = {
      group_by: group,
    };
    let url = "subscription";
    let baseUrl = apiUrl + url;
    let response = "";
    setloading(true);
    response = axios(baseUrl, {
      method: "GET",
      data: body,
      headers: header,
      params: body,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        if (
          response.data[subscription_duration] &&
          Object.keys(response.data[subscription_duration]).length > 0
        ) {
          let subscriptionDetails = response.data[subscription_duration];
          //console.log(subscriptionDetails);
          //  setSubscriptionPlans(subscriptionDetails);
          Object.keys(subscriptionDetails).map((key, value) => {
            //subscriptionDetails[key].map((value, ind) =>
            //  console.log(subscriptionDetails[value])
            //);
            setSubscriptionPlans((data) => ({
              ...data,
              [value]: subscriptionDetails[value],
            }));
          });
        } else {
          setSubscriptionPlans({});
          setloading(false);
        }
        // setloading(false);
      } else if (response.status === 401) {
        message.error("Sorry , Your link has been expired!");
        message.destroy();
        navigate("*");
        setloading(false);
      } else {
        navigate("*");
        setloading(false);
      }
    });
  }
  //get available durations
  function fetchAvailableDurations() {
    let body = "";
    let url = "subscription-days";
    let baseUrl = apiUrl + url;
    let response = "";
    response = axios(baseUrl, {
      method: "GET",
      data: body,
      headers: header,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setAvailableDurations(response.data);
      } else if (response.status === 401) {
        message.error("Sorry , Your link has been expired!");
        message.destroy();
        navigate("*");
      } else {
        navigate("*");
      }
    });
    // .catch((error) => {
    //   navigate("/PageNotFound");
    // });
  }
  //loads all subscription plans
  useEffect(() => {
    fetchAvailableDurations();
    fetchSubscriptions(true, 6);
  }, []);
  useEffect(() => {
    setloading(false);
  }, [subsctiptionPlans]);
  const handleSubcriptionClick = useCallback((event) => {
    setUpgradePlan(event.target.value);
  });
  let durationSettings = {
    infinite: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    slide: "ul",
    dots: true,
    className: "duration-list d-flex flex-row text-center p-0 mt-3 mt-lg-0",
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 4,
        },
      },
    ],
  };
  //subscription loading ends
  return (
    <Spin size="large" spinning={loading}>
      <div className="container">
        <div className="card">
          <div className="container">
            <div className="row">
              <div className="col-12 py-3">
                <div className="card-title">
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-12 col-xl-4 text-center text-sm-left ">
                        <h4>Choose your plan</h4>
                      </div>
                      <div className="col-12 col-lg-8 col-xl-8">
                         <Slider {...durationSettings}>
                          {Object.keys(availableDurations).length
                            ? Object.values(availableDurations).map((type) => {
                                counter++;
                                return (
                                  <li
                                    key={`${counter}+list`}
                                    className={
                                      type.id == subscriptionDuration
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <button
                                      onClick={handleDurationClick}
                                      value={type.id}
                                      key={`${counter}duration`}
                                      className="duration-list"
                                    >
                                      {type.description}
                                    </button>
                                  </li>
                                );
                              })
                            : ""}
                          </Slider>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-2">
                  <Slider {...settings}>
                    {Object.keys(subsctiptionPlans).length ? (
                      Object.values(subsctiptionPlans).map((type) => {
                        counter++;
                        return (
                          <div className="sub-card">
                            <div className="sub-card-tag">
                              <div className="sub-card-tag-price-regular">
                                {type.offers.length > 0
                                  ? "$" + `${type.amount}`
                                  : ""}
                              </div>
                              <div className="sub-card-tag-price">
                                {type.amount > 0
                                  ? type.offers.length > 0
                                    ? "$" + `${type.offers[0].offer_amount}`
                                    : "$" + `${type.amount}`
                                  : "Free"}
                              </div>
                              <div className="sub-card-tag-post-line">
                                {type.subscription_days} Days
                              </div>
                            </div>
                            {Object.keys(type.offers).length ? (
                              <div className="discount--badge">
                                <span>{type.offers[0].description}</span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="sub-card-title">
                              <h4>{type.plan_name}</h4>
                            </div>
                            <ul
                              className="sub-card-features"
                              key={`${counter}+featuremain`}
                            >
                              {Object.values(type.features).map((features) => {
                                counter++;
                                return (
                                  <li key={`${counter}+feature`}>
                                    {features.name}
                                  </li>
                                );
                              })}
                            </ul>
                            <a
                              value="one"
                              onClick={handleSubcriptionClick}
                              className="btn w-100"
                              key={`${counter}+choose`}
                            >
                              Choose
                            </a>
                          </div>
                        );
                      })
                    ) : (
                      <Empty />
                    )}
                  </Slider>
                  {/* <div className="sub-slider-btn d-flex justify-content-end">
                      <button className="prev-slide mr-2">
                        <span className="prev" />
                      </button>
                      <button className="next-slide">
                        <span className="next" />
                      </button>
                 </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  );
}

export default RenewSubscription;
