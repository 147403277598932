import { useState, useEffect } from "react";
import { validate } from "./OfferValidation";

const useOfferForm = (callBack) => {
    const [values, setValues] = useState({});
    const [errors, setErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [disabled, setDisabled] = useState(true);

    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmit){
            callBack();
        }
    }, [errors, isSubmit, values, disabled]);

    const handleSubmit = (event) => {
        if (event) event.preventDefault();
        setErrors(validate(values));
        setIsSubmit(true);
    }

    const handleChange = (event) => {
        setIsSubmit(false);
        const newValues = { ...values, [event.target.name]: event.target.value };
        setValues(newValues);
    }
    const handleCancel = (event) => {
        setDisabled(true);
        setIsSubmit(false);
    }

    return {
        handleChange,
        handleSubmit,
        handleCancel,
        values,
        errors,
        disabled
    };    
}
export default useOfferForm;