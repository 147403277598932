import Form from "../components/signIn/Form";
import Register from "../components/signUp/Register";
import { Dashboard } from "../components/dashboard/admin/Dashboard";
import MainLayout from "../layouts/MainLayout";
import PageNotFound from "../error/PageNotFound";
import ErrorPage from "../error/ErrorPage";
import GuestLayout from "../layouts/GuestLayout";
import UserRegisteration from "../components/user/UserRegisteration";
import ManagePlans from "../components/admin/subscription/ManagePlans";
import ManageSubsFeatures from "../components/admin/subscription/subsciption-features/ManageSubsFeatures";
import ManageMarketplaces from "../components/admin/marketplaces/ManageMarketplaces";
import PaymentDashboard from "../components/admin/payment/PaymentDashboard";
import ListSubscriptions from "../components/admin/subscription/ListSubscriptions";
import SubscriptionDetails from "../components/admin/subscription/SubscriptionDetails";
import SubscriptionOffers from "../components/admin/offer/create-offer/ManageSubscriptionOffers";
import ManageSubscriptionName from "../components/admin/subscription/subscription-name/ManageSubscriptionName";
import ManageSubscriptionDuration from "../components/admin/subscription/subscription-duration/ManageSubscriptionDuration";
import SubscriptionOfferForm from "../components/admin/offer/subscription-offer/SubscriptionOfferForm";
import ManageProducts from "../components/admin/Product/product-dashboard/ManageProducts";
import ManageSpecifications from "../components/admin/Product/specification/ManageSpecifications";
import ManageCategory from "../components/admin/Product/category/ManageCategory";
import AddCategory from "../components/admin/Product/category/AddCategory";
import CatagoryDashboard from "../components/admin/Product/category/CatagoryDashboard";
import AddSpecification from "../components/admin/Product/specification/AddSpecification";
import SpecificationDashboard from "../components/admin/Product/specification/SpecificationDashboard";
import ManagePartners from "../components/admin/partners/ManagePartners";
import ManageProfile from "../components/admin/partners/ManageProfile";
import PartnerSubscription from "../components/admin/partners/PartnerSubscription";
import ManageMarketplaceCategory from "../components/admin/Product/marketplace-category/ManageMarketplaceCategory";
import AddMarketplaceCategory from "../components/admin/Product/marketplace-category/AddMarketplaceCategory";
import CategoryMapForm from "../components/admin/Product/marketplace-category/CategoryMapForm";
import MarketplaceCategoryDashboard from "../components/admin/Product/marketplace-category/MarketplaceCategoryDashboard";
import ProtectedRoute from "./ProtectedRoute";
import ManageUsers from "../components/admin/users/ManageUsers";
import AddPartner from "../components/admin/partners/add-partner/AddPartner";
import ViewPartner from "../components/admin/partners/view-partner/ViewPartner";

let routes = [
  {
    path: "/",
    element: <Form />,
  },
  {
    path: "/signup",
    element: <Register />,
  },
  {
    path: "/user",
    element: <GuestLayout />,
    children: [
      {
        path: "/user/register",
        element: <UserRegisteration />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: "/dashboard",
        element: <MainLayout />,
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
        ],
      },
      {
        path: "/admin",
        element: <MainLayout />,
        children: [
          {
            path: "/admin/manage-partners",
            element: <ManagePartners />,
          },
          {
            path: "/admin/view-partner",
            element: <ViewPartner />,
          },
          {
            path: "/admin/manage-profile",
            element: <ManageProfile />,
          },
          {
            path: "/admin/add-partner",
            element: <AddPartner />,
          },
          {
            path: "/admin/manage-plans",
            element: <ManagePlans />,
          },
          {
            path: "/admin/plan-features",
            element: <ManageSubsFeatures />,
          },
          {
            path: "/admin/manage-marketplaces",
            element: <ManageMarketplaces />,
          },
          {
            path: "/admin/subscriptions",
            element: <ManagePlans />,
            children: [
              {
                path: "/admin/subscriptions/manage-subscription",
                element: <ListSubscriptions />,
              },
              {
                path: "/admin/subscriptions/create-subscription",
                element: <SubscriptionDetails />,
              },
            ],
          },
          {
            path: "/admin/offer/subscription-offer",
            element: <SubscriptionOffers />,
          },
          {
            path: "/admin/offer/view-offer",
            element: <SubscriptionOfferForm />,
          },
          {
            path: "/admin/manage-partner-subscription",
            element: <PartnerSubscription />,
          },
          {
            path: "/admin/manage-plan-name",
            element: <ManageSubscriptionName />,
          },
          {
            path: "/admin/manage-plan-duration",
            element: <ManageSubscriptionDuration />,
          },
          {
            path: "/admin/payment-dashboard",
            element: <PaymentDashboard />,
          },
          {
            path: "/admin/manage-users",
            element: <ManageUsers />,
          },
          {
            path: "/admin/product-dashboard",
            element: <ManageProducts />,
          },
          {
            path: "/admin/category",
            element: <ManageCategory />,
            children: [
              {
                path: "/admin/category/manage-category",
                element: <AddCategory />,
              },
              {
                path: "/admin/category/category-dashboard",
                element: <CatagoryDashboard />,
              },
            ],
          },
          {
            path: "/admin/specifications",
            element: <ManageSpecifications />,
            children: [
              {
                path: "/admin/specifications/manage-specifications",
                element: <AddSpecification />,
              },
              {
                path: "/admin/specifications/specifications-dashboard",
                element: <SpecificationDashboard />,
              },
            ],
          },
          {
            path: "/admin/marketplace-category",
            element: <ManageMarketplaceCategory />,
            children: [
              {
                path: "/admin/marketplace-category/marketplace-category-dashboard",
                element: <MarketplaceCategoryDashboard />,
              },
              {
                path: "/admin/marketplace-category/manage-marketplace-category",
                element: <AddMarketplaceCategory />,
              },
              {
                path: "/admin/marketplace-category/category-dashboard",
                element: <CategoryMapForm />,
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "*",
    element: <ErrorPage status="404" />,
  },
  {
    path: "/PageNotFound",
    element: <ErrorPage status="500" />,
  },
];
export default routes;
