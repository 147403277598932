import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Breadcrumb,
  Popconfirm,
  Space,
  Form,
  Input,
  Button,
  message,
  Avatar,
  List,
  Switch,
} from "antd";
import { DeleteOutlined, EditFilled } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { setUserDetails } from "../../../../features/userSlice";
import SubscriptionDurationModal from "./SubscriptionDurationModal";
import { PlusOutlined } from "@ant-design/icons";
import PageHeaderBreadCrumb from "../../../common/breadcrumb/PageHeaderBreadCrumb";

function ManageSubscriptionDuration(props) {
  const [subscriptionDurations, setSubscriptionDurations] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [defaultValues, setDefaultValues] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
    setMode("Add");
  };

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const breadcrumbsData = [
    {
      name: "home",
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "text-primary-color",
    },
    {
      name: "ManageDuration",
      pageName: "Manage Subscription Duration",
      className: "active-link",
    },
  ];
  /* Service call to retrieve markerplaces */
  useEffect(() => {
    setLoading(true);
    getSubscriptionDurations();
  }, []);
  useEffect(() => {
    if (!open) {
      getSubscriptionDurations();
    }
  }, [open]);
  async function getSubscriptionDurations() {
    let url = "subscription-days";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubscriptionDurations(response.data);
      }
    });
    setLoading(false);
  }
  const editSubscriptionDuration = (record) => {
    setModalLoading(true);
    getSubscriptionDuration(record.id);
    setMode("Edit");
    setOpen(true);
  };

  /* Service Call to delete partners */
  const deleteSubscriptionDuration = (record) => {
    let url = "subscription-days/" + record.id;
    url = apiUrl + url;
    let response = axios(url, {
      method: "DELETE",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        getSubscriptionDurations();
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  async function getSubscriptionDuration(id) {
    let url = `subscription-days/${id}`;
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setDefaultValues(response.data);
      }
    });
  }

  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-3 mb-0 pt-3 text-primary-color">
              Manage Subscription Duration
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
            <PageHeaderBreadCrumb breadcrumbsData={breadcrumbsData} />
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
              <Button
                className="bg-primary-color ml-2 btn"
                title="Add new Marketplace"
                onClick={showModal}
                icon={<PlusOutlined />}
              >
                Add Duration
              </Button>
              <SubscriptionDurationModal
                open={open}
                setOpen={setOpen}
                mode={mode}
                defaultValues={defaultValues}
                loading={modalLoading}
                setLoading={setModalLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-info card mb-4 mt-3 w-100">
          <div className="table-content">
            <div className="container">
              <div className="mt-3 w-100 overflow-auto mt-1">
                <List
                  className="demo-loadmore-list"
                  loading={loading}
                  itemLayout="horizontal"
                  dataSource={subscriptionDurations}
                  renderItem={(record) => (
                    <List.Item
                      className="p-3 mb-2 border card-sub-head"
                      actions={[
                        <Space>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<EditFilled />}
                            size="small"
                            title="Edit"
                            onClick={(e) => editSubscriptionDuration(record)}
                          />
                          <Popconfirm
                            title={"Remove " + record.description + "?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteSubscriptionDuration(record)}
                          >
                            <Button
                              type="danger"
                              shape="circle"
                              icon={<DeleteOutlined />}
                              size="small"
                              title="Remove"
                            />
                          </Popconfirm>
                        </Space>,
                      ]}
                    >
                      <List.Item.Meta title={<p>{record.description}</p>} />
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
            <div>
              <nav aria-label="Page navigation"></nav>
            </div>
            <div>
              <p className="m-3"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageSubscriptionDuration;
