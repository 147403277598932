import { Form, Spin, Space, Input, Button, message, Modal, Alert ,Pagination } from "antd";
import {
  SaveFilled,
  CloseCircleFilled,
  EditFilled,
  SearchOutlined,
  ClearOutlined,
  DownloadOutlined,
  UploadOutlined,
  RetweetOutlined,
} from "@ant-design/icons";
import { DataTable } from "../../../common/Table";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { ApiEndPoint, ApiHeaders } from "../../../../utils/commonutils";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import { saveAs } from "file-saver";

function CategoryTemplates() {
  const [form] = Form.useForm();
  const [pageLoader, setPageLoader] = useState(false);
  const [buttonLoading, setButtonLoading] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [modalLoader, setModalLoader] = useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [uploadFile, setUploadFile] = useState({});
  const fileTypes = ["XLS", "XLSX"];
  const [categoryId, setCategoryId] = useState("");
  const [catTemplateId, setCatTemplateId] = useState("");
  const [templateError, setTemplateError] = useState("");
  const [modalMode, setModalMode] = useState("");
  const [page,setPageChoice] = useState(1);
  const [totalCount,setTotalCount] = useState(1);
  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiUrl = ApiEndPoint();
  let headers = ApiHeaders(userToken);

  useEffect(() => {
    setPageLoader(true);
    loadSubCategoryData();
  }, []);

  const loadSubCategoryData = async () => {
    setPageLoader(true);
    let filterBody = {};
    //filterBody["leaf_node"] = true;
    filterBody["page"] = page;
    let url = "category?leaf_node=true?";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      params : filterBody,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubCategoryData(response.data);
        setPageLoader(false);
        setTotalCount(response.meta.total);
      } else {
        message.error("Failed to load Sub Categories!");
        message.destroy();
        setPageLoader(false);
      }
    });
  };

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input maxLength={50} />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please input ${title}!`,
              },
              {
                pattern: new RegExp(/^[a-zA-Z][a-zA-Z- &,'\s]*$/),
                message: "Category Name can only contain alphabets and &,-'",
              },
              {
                max: 50,
                message:
                  "Category Name must not be greater than 50 characters!",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const downloadCategoryTemplate = async (record) => {
    startLoading(record.id, true);
    let fileName = record.name;
    let filePath = record.category_template;
    // filePath =
    //   "http://api22.digitalmesh.co.in/storage/templates/Console-Sinks.xlsx";
    await fetch(filePath).then((response) => {
      response.blob().then((blob) => {
        saveAs(blob, fileName);
        message.success("File Downloaded successfully!");
        message.destroy();
        startLoading(record.id, false);
        setPageLoader(false);
      });
    });
  };

  function handleLoaders(status) {
    setPageLoader(status);
    setModalLoader(status);
    setDisableBtn(status);
  }

  function uploadCategoryTemplate() {
    handleLoaders(true);
    let url = "template";
    let formData = new FormData();
    formData.append("template", uploadFile);
    if (modalMode == "Replace") {
      /* Replace existing template - Pass category_template_id */
      url = url + "/" + catTemplateId;
    } else {
      /* Upload template - Pass category_id */
      formData.append("category_id", categoryId);
    }
    url = apiUrl + url;
    let reqHeaders = headers;
    response = axios(url, {
      method: "POST",
      data: formData,
      headers: reqHeaders,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setTimeout(() => {
          message.success(response.message);
          handleLoaders(false);
          setShowModal(false);
          setUploadFile({});
          loadSubCategoryData();
        }, 3000);
      } else if (response.status === 422) {
        let errors = response.data;
        Object.values(errors).map((value) => {
          message.error(value);
          setTemplateError(value);
        });
        setUploadFile({});
        handleLoaders(false);
      } else {
        message.error("Oops..!! Something went wrong.");
      }
      message.destroy();
    });
  }

  const handleModalSubmit = () => {
    let fileData = uploadFile;
    if (fileData.name) {
      uploadCategoryTemplate();
    } else {
      fileErrorMessage();
    }
  };

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  const handleFileChange = (file) => {
    validateFile(file);
  };

  function validateFile(file) {
    let fileName = file.name.split(".");
    let fileExtension = fileName[1];
    fileExtension = fileExtension.toUpperCase();
    let fileSize = file.size;
    setTemplateError("");
    if (!fileTypes.includes(fileExtension)) {
      setTemplateError(
        "Please upload an excel file having extentions: .xlsx or .xls!"
      );
    } else if (fileSize > 10000000) {
      setTemplateError("Template file must be smaller than 10 MB");
    } else {
      setUploadFile(file);
    }
  }

  function fileErrorMessage() {
    message.error("Please upload a valid Excel file!");
    message.destroy();
  }

  const handleModalCancel = () => {
    setUploadFile({});
    setShowModal(false);
    setDisableBtn(false);
    setTemplateError("");
  };

  const handleCancel = () => {
    setEditingKey(false);
  };

  const updateCategoryName = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...subCategoryData];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        updateCategory(record, row);
      } else {
        newData.push(row);
        setSubCategoryData(newData);
      }
      setEditingKey("");
    } catch (errInfo) {
      let errors = errInfo.errorFields;
      Object.values(errors).map((value) => {
        message.error(value.errors);
        message.destroy();
      });
    }
  };

  function updateCategory(record, row) {
    let url = `category/${record.id}`;
    url = apiUrl + url;
    let requestBody = {
      name: row.name,
    };
    response = axios(url, {
      method: "PUT",
      data: requestBody,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        message.destroy();
        /* Update view in front-end*/
        const newData = [...subCategoryData];
        const index = newData.findIndex((item) => record.id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setSubCategoryData(newData);
        }
      } else if (response.status === 422) {
        setServerErrors(response);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  }

  function setServerErrors(response) {
    let validationErrors = response.data;
    let errors = {};
    for (let key in validationErrors) {
      errors.error = validationErrors[key];
      message.error(errors.error);
    }
  }

  const editCategory = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleFileDownload = (record) => {
    setPageLoader(true);
    downloadCategoryTemplate(record);
  };

  const handleFileUpload = (record) => {
    setModalMode("Upload");
    setCategoryId(record.id);
    setShowModal(true);
  };

  const handleReplaceFile = (record) => {
    setModalMode("Replace");
    setCatTemplateId(record.category_template_id);
    setCategoryId(record.id);
    setShowModal(true);
  };

  const startLoading = (index, status) => {
    setButtonLoading((prevLoading) => {
      const newLoading = [...prevLoading];
      newLoading[index] = status;
      return newLoading;
    });
  };

  const handlePaginationClick = (pageNumber) => {
      setPageChoice(pageNumber)
      loadSubCategoryData();

  };

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const subCatTableHeading = [
    {
      title: "CatID",
      key: "catid",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id", "CatID"),
    },
    {
      title: "Category Name",
      key: "name",
      editable: true,
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "Category Name"),
    },
    {
      title: "Category Hierarchy",
      key: "breadcrumb",
      editable: true,
      dataIndex: "breadcrumb",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "Category Name"),
    },
    {
      title: "Catagory Template",
      dataIndex: "category_template",
      key: "category_template",
      render: (value, record) => {
        {
          return value ? value : "-";
        }
      },
    },
    {
      title: "Action",
      key: "action",
      dataIndex: "manage_template",
      render: (value, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space key={"space1_" + record.id}>
            <Button
              title="Save Changes"
              icon={<SaveFilled />}
              className="text-success lead ant-btn-circle"
              key={"saveFilled_" + record.id}
              onClick={() => updateCategoryName(record)}
            />
            <Button
              title="Cancel"
              icon={<CloseCircleFilled />}
              key={"cancel_" + record.id}
              className="text-danger lead ant-btn-circle"
              onClick={handleCancel}
            />
          </Space>
        ) : (
          <Space>
            <Space key={"space2_" + record.id}>
              <Button
                key={"edit_" + record.id}
                title="Edit"
                icon={<EditFilled />}
                className="text-primary-color ant-btn-circle"
                onClick={() => editCategory(record)}
              />
            </Space>
            {record.category_template ? (
              <>
                <Button
                  key={"download_" + record.id}
                  className="bg-primary-color ant-btn-circle"
                  onClick={() => handleFileDownload(record)}
                  icon={<DownloadOutlined />}
                  title="Download Template"
                  loading={buttonLoading[record.id]}
                />
                <Button
                  icon={<RetweetOutlined />}
                  key={"replace_" + record.id}
                  title="Replace Template"
                  onClick={() => handleReplaceFile(record)}
                  className="bg-secondary-color ant-btn-circle"
                />
              </>
            ) : (
              <Button
                key={"upload" + record.id}
                className="bg-primary-color ant-btn-circle"
                onClick={() => handleFileUpload(record)}
                icon={<UploadOutlined />}
                title="Upload Template"
                loading={buttonLoading[record.id]}
              />
            )}
          </Space>
        );
      },
    },
  ];

  const subCategoryTableHead = subCatTableHeading.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        maxLength: 50,
      }),
    };
  });

  return (
    <div>
      <Spin spinning={pageLoader}>
        <div className="border-info card mb-4 w-100 pb-3">
          <div className="table-content">
            <div className="container">
              <div className="mt-3 table-wrapper w-100 overflow-auto mt-1">
                <Form form={form} component={false}>
                  <DataTable
                    columns={subCategoryTableHead}
                    data={subCategoryData}
                    components={{
                      body: {
                        cell: EditableCell,
                      },
                    }}
                    bordered
                    pagination={false}
                  />
                  <Pagination defaultCurrent={1} 
                              current={page} 
                              total={totalCount} 
                              style={{textAlign:"center"}} 
                              onChange={handlePaginationClick}
                              pageSize={100}
                  />
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Spin>

      <Modal
        title={modalMode + " Category Template"}
        name="replace_template"
        okText={disableBtn ? "Uploading..." : "Upload"}
        onOk={handleModalSubmit}
        onCancel={handleModalCancel}
        confirmLoading={modalLoader}
        cancelButtonProps={{ disabled: disableBtn }}
        open={showModal}
        destroyOnClose={true}
        width={"30%"}
      >
        <span className="text-danger">{templateError}</span>
        <div className="row justify-content-center mt-2">
          {uploadFile.name ? (
            <div style={{ margin: "auto", padding: "10px" }}>
              <Alert
                showIcon={true}
                message="File validated successfully, please press the Upload button to proceed."
                type="info"
                className="text-center"
              />
              <p className="pt-2 text-center text-info">{uploadFile.name}</p>
            </div>
          ) : (
            <>
              <FileUploader
                name="template"
                types={fileTypes}
                label="Drag and drop files or click to browse files"
                hoverTitle="Drag and drop files to upload"
                maxSize="10"
                handleChange={handleFileChange}
                onTypeError={(error) => {
                  fileErrorMessage();
                }}
              ></FileUploader>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default CategoryTemplates;
