import { useLocation } from "react-router-dom";
import ChooseSubscription from "./ChooseSubscription";
import BusinessVerificationForm from "./BusinessVerificationForm";
import DomainDetails from "./DomainDetails";
import EmailVerification from "./EmailVerification";
import FinalPage from "./FinalPage";
import { Button, Form, message, Steps } from "antd";

import { useEffect, useRef, useState } from "react";
import {
  DollarCircleOutlined,
  CloudUploadOutlined,
  SmileOutlined,
  CheckCircleTwoTone,
  FileTextOutlined,
  MailTwoTone,
} from "@ant-design/icons";

import PageHeaderBreadCrumb from "../../common/breadcrumb/PageHeaderBreadCrumb";
import axios from "axios";
import { ApiEndPoint, getApiKey } from "../../../utils/commonutils";

const ManageProfile = () => {
  const location = useLocation();
  const partnerId = location.state ? location.state.partnerId : "";
  const [form] = Form.useForm();
  const [userData, setData] = useState({});
  const [subscriptionPlan, setSubscriptionPlan] = useState();
  const [planName, setPlanName] = useState();
  const [status, setStatus] = useState(0);
  const [values, setValues] = useState({});
  const [domainValues, setDomainValues] = useState({});
  const [buttonName, setButtonName] = useState("");
  const [functionName, setFunctionName] = useState("");
  const [questions, setQuestions] = useState({});
  let apiUrl = ApiEndPoint();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const breadcrumbsData = [
    {
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "",
    },
    {
      pageName: "Partner Dashboard",
      pageUrl: "/admin/manage-partners",
      className: "",
    },
    {
      pageName: "Manage Partner",
      pageUrl: "/admin/manage-profile",
      className: "active-link",
    },
  ];

  useEffect(() => {
    getPartnerDetails();
  }, []);

  useEffect(() => {
    getPartnerDetails();
  }, [status]);

  async function getPartnerDetails() {
    let url = "getPartnerStatus/" + partnerId;
    let method = "GET";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setData(response.data);
        getStatus(response.data.status);
      }
    });
  }

  function getStatus(value) {
    switch (value) {
      case "Registered":
        setStatus(1);
        break;
      case "Email Verified":
        setStatus(2);
        setFunctionName("saveRequirements()");
        break;
      case "Hold":
        setStatus(2);
        setFunctionName("saveRequirements()");
        break;
      case "Business Verified":
        setStatus(3);
        setButtonName("Save Subscription");
        setFunctionName("saveSubscription()");
        break;
      case "Subscription Added":
        setStatus(4);
        break;
      case "Domain Completed":
        setStatus(5);
        break;
      default:
        break;
    }
  }

  async function saveRequirements() {
    let postData = {
      all_questions_answers: questions,
      marketing_remarks: values,
      partner_id: partnerId,
    };
    let url = "saveBusinessLogics";
    let method = "POST";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: postData,
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setStatus(3);
      }
    });
  }

  async function saveSubscription() {
    if (!subscriptionPlan) {
      message.destroy();
      message.error("Please select a Subscription Plan!");
      return false;
    }
    const postData = {
      subscriptionplan: subscriptionPlan,
      subscription: planName,
      partner_id: partnerId,
      status: "Subscription Added",
    };
    let url = "savePartnerSubscriptions";
    let method = "POST";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: postData,
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setStatus(4);
      }
    });
  }

  async function sendMail() {
    let postData = {
      domain_infos: domainValues,
      status: "Domain Completed",
      partner_id: partnerId,
    };
    let url = "saveDomainInfos";
    let method = "POST";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: postData,
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setStatus(5);
      }
    });
  }

  const handleClick = () => {
    status == 3 ? saveSubscription() : sendMail();
  };

  const handleDomainSubmit = () => {
    sendMail();
  };

  const handleRequirementSubmit = () => {
    saveRequirements();
  };

  const items = [
    {
      title: `Registration`,
      content: "",
      icon: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    },
    {
      title: `Email Verification`,
      content: <EmailVerification />,
      icon:
        status == 1 ? (
          <MailTwoTone />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
    },
    {
      title: `Business Verification`,
      content: (
        <BusinessVerificationForm
          data={userData}
          values={values}
          setValues={setValues}
          questions={questions}
          setQuestions={setQuestions}
          handleSubmit={handleRequirementSubmit}
        />
      ),
      icon:
        status == 2 ? (
          <FileTextOutlined />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
    },
    {
      title: `Subscription Plan`,
      content: (
        <ChooseSubscription
          data={userData}
          subscriptionPlan={subscriptionPlan}
          setSubscriptionPlan={setSubscriptionPlan}
          setPlanName={setPlanName}
          partnerId={partnerId}
        />
      ),
      icon:
        status <= 3 ? (
          <DollarCircleOutlined />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
    },
    {
      title: "Domain Setup",
      content: (
        <DomainDetails
          domainValues={domainValues}
          setDomainValues={setDomainValues}
          handleSubmit={handleDomainSubmit}
        />
      ),
      icon:
        status <= 4 ? (
          <CloudUploadOutlined />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
    },
    {
      title: "Done",
      content: <FinalPage />,
      icon:
        status <= 5 ? (
          <SmileOutlined />
        ) : (
          <CheckCircleTwoTone twoToneColor="#52c41a" />
        ),
    },
  ];
  return (
    <>
      <div className="row">
        <div className="">
          <PageHeaderBreadCrumb
            pageTitle="Manage Partner"
            breadcrumbsData={breadcrumbsData}
          />
        </div>
      </div>
      <div className="card p-2">
        <div className="table-content">
          <div className="container">
            <Steps
              size="small"
              current={status}
              style={{ paddingLeft: 10, paddingTop: 50, xs: 24, xl: 12 }}
            >
              {items.map((item) => (
                <Steps.Step
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              ))}
            </Steps>
            <div style={{ paddingTop: "50px !important" }}>
              {items[status].content}
            </div>
            <div className="text-center pt-3 pb-3">
              {status == 3 ? (
                <Button
                  type="primary"
                  className="text-bold"
                  style={{
                    borderRadius: "15px",
                  }}
                  onClick={handleClick}
                >
                  {buttonName}
                </Button>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageProfile;
