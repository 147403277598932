export const validate = (formValues) => {
  let errors = {};
  const regexName = /^[^-\s][a-zA-Z_\s-]+$/;
  if (!formValues.plan) {
    errors.plan = "Subscription cannot be blank!";
  } else if (!regexName.test(formValues.plan)) {
    errors.plan = "Subscription Name is invalid!";
  }
  if (formValues.priority == "" && formValues.priority != 0 ) {
    errors.priority =
      "Level cannot be blank!";
  }
  return errors;
};
