import { Result } from "antd";

const FinalPage = () => {
  return (
    <>
      <Result
        status="success"
        title="Done!"
        subTitle="We've completed all the necessary operations and have dispatched a password reset email to our partner!"
      ></Result>
    </>
  );
};
export default FinalPage;
