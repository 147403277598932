export const validate = (formValues) => {
  let errors = {};
  const regexDescription = /^[a-zA-Z0-9][a-zA-Z0-9@#$&%()\-.+,"'_ ]*$/;

  if (!formValues.description) {
    errors.description = "Offer description cannot be blank!";
  } else if (
    formValues.description != "" &&
    !regexDescription.test(formValues.description)
  ) {
    errors.description = "Offer description is invalid!";
  }
  if (!formValues.offer_duration || formValues.offer_duration.length === 0) {
    errors.offer_duration = "Offer duration cannot be blank!";
  }
  if (!formValues.is_subscription_offer) {
    errors.is_subscription_offer = "Please select the type of offer!";
  }
  return errors;
};
