import { useState, useEffect } from "react";
import { validate } from "./MarketplaceValidation";

const UseMarketplaceForm = (callBack) => {
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [fileDetails, setFileDetails] = useState("");

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      callBack();
    }
  }, [errors, isSubmit, values, defaultValues, disabled]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, fileDetails));
    setIsSubmit(true);
  };

  const handleChange = (event) => {
   
    setIsSubmit(false);
    setFileDetails("");
    /* get input file details, if input is of type:file */
    if (event.nativeEvent.type == "change") {
      setFileDetails(event.target.files[0]);
    }
    const newValues = { ...values, [event.target.name]: event.target.value };
    setErrors(validate(newValues, fileDetails));
    setValues(newValues);    
  };

  const handleCancel = (event) => {
    setDisabled(true);
    setIsSubmit(false);
    setErrors("");
    if (event.type == "click") {
      setValues(defaultValues);
    }
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    values,
    defaultValues,
    errors,
    disabled
  };
};
export default UseMarketplaceForm;
