import { Space, Table, Tag } from "antd";
import { render } from "fusioncharts";

export const DataTable = (props) => {
  const data = props.data.map((value, key) => {
    if (value.hasOwnProperty("key")) delete value.key;
    return { key: key, ...value };
  });
  return (
    <Table
      columns={props.columns}
      dataSource={data}
      expandable={props.expandable}
      components={props.components}
      loading={props.loading}
      rowClassName={props.rowClassName ? props.rowClassName : "text-primary-color"}     
      pagination={props.pagination} 
      className={props.className}
      scroll={props.scroll}
      size={props.size}
      bordered={props.bordered}
      rowSelection={props.rowSelection}
    />
  );
};
