import React, { useEffect, useState } from "react";
import "./style.css";
import Select from "react-select";

const TimezoneSelector = ({
  label,
  required,
  id,
  onChange,
  leftIcon,
  defaultValue,
  disabled,
  placeholder,
  options,
  Validation,
  ValidationMessage,
}) => {
  const [selectedTimezone, setSelectedTimezone] = useState(null);

  const handleTimezoneSelect = (val) => {
    setSelectedTimezone(val);
    onChange(val.value);
  };

  const inputPadding = selectedTimezone ? "28px" : "6px";

  return (
    <div className="mew-input-container">
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div
        className={`mew-country-selector ${
          leftIcon && !selectedTimezone ? "mew-left-icon" : ""
        }`}
      >
        {leftIcon && !selectedTimezone && (
          <span className="mew-select-left-icon">
            <img src={leftIcon} alt="" />
          </span>
        )}
        <div className="mew-flag-img"></div>

        <div className="mew-country-select-container">
          <Select
            isSearchable={true}
            options={options}
            value={selectedTimezone}
            defaultValue={defaultValue}
            isDisabled={disabled}
            placeholder={placeholder}
            onChange={handleTimezoneSelect}
            styles={{
              dropdownIndicator: (base) => ({
                ...base,
                display: "none",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#A0AEC0",
              }),

              option: (provided, { isSelected, isFocused }) => ({
                ...provided,
                cursor: "pointer",
                fontWeight: isSelected ? "600" : "inherit",
                backgroundColor: isSelected
                  ? "#e6f7ff"
                  : isFocused
                  ? "#D8F2F0"
                  : "inherit",
                color: isSelected ? "rgba(0, 0, 0, 0.85)" : "inherit",
                "&:active": {
                  backgroundColor: isSelected ? "#e6f7ff" : "inherit",
                },
              }),
            }}
          />
        </div>
      </div>
      {Validation ? (
        <div className="mew-validationMessage pt-1">
          <span className="text-danger pt-0">{ValidationMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default TimezoneSelector;
