import { Tabs } from "antd";
import { FileExcelOutlined, PartitionOutlined } from "@ant-design/icons";
import { useOutletContext } from "react-router-dom";
import ParentCategories from "./ParentCategories";
import CategoryTemplates from "./CategoryTemplates";

function CatagoryDashboard(props) {
  const [mode, setMode] = useOutletContext();

  return (
    <div className="container">
      <Tabs
        className="container"
        defaultActiveKey="1"
        items={[PartitionOutlined, FileExcelOutlined].map((Icon, i) => {
          const id = String(i + 1);
          const tabName =
            id == 1 ? "Manage Catagory" : "Manage Catagory Template";
          return {
            label: (
              <span>
                <Icon />
                {tabName}
              </span>
            ),
            key: id,
            children: id == 1 ? <ParentCategories /> : <CategoryTemplates />,
          };
        })}
      />
    </div>
  );
}

export default CatagoryDashboard;
