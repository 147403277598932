import { Select, Form, Button, Divider, Descriptions, message } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEndPoint, getApiKey } from "../../../utils/commonutils";
import SkeletonInput from "antd/lib/skeleton/Input";

const ChooseSubscription = ({
  data,
  subscriptionPlan,
  setSubscriptionPlan,
  setPlanName,
  partnerId,
}) => {
  const [formLayout, setFormLayout] = useState("horizontal");
  const [subList, setSubList] = useState();
  const [allSubcriptions, setAllSubscriptions] = useState({});
  const [selectedSubscription, setSelectedSubscription] = useState();
  const [marketplacesMapped, setMarketplacesMapped] = useState();
  const [form] = Form.useForm();
  const formRef = useRef();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  let partnerMarketplace = [];
  const navigate = useNavigate();
  let apiUrl = ApiEndPoint();

  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  function getPartnerSelectedMarketplaces() {
    const partnerMarketplaces = data
      ? data.all_questions_answers.find(
          (question) => question.question === "Marketplaces"
        )
      : "";
    const marketPlaces = data.all_marketplaces;

    if (partnerMarketplaces) {
      const marketplaces = partnerMarketplaces
        ? partnerMarketplaces.answer.split(", ")
        : "";
      const filteredData = marketPlaces.filter((item) =>
        marketplaces.includes(item.label)
      );
      // Extract the ids from the filteredData
      const selectedIds = filteredData.map((item) => item.value);
      partnerMarketplace = selectedIds ? selectedIds : null;
      setMarketplacesMapped(partnerMarketplace);
    }
  }

  const handleClick = (event) => {
    navigate("/admin/subscriptions/create-subscription", {
      state: {
        mode: "Add",
        userData: data.all_questions_answers,
        partnerId: partnerId,
        marketplaces: marketplacesMapped,
      },
    });
  };

  const getValueLabel = (array, targetValue) => {
    const matchingItem = array.find((item) => item.value === targetValue);
    setPlanName(matchingItem.label);
  };

  const handleChange = (value) => {
    getValueLabel(subList, value);
    setSubscriptionPlan(value);
    getSubscription(value);
  };

  useEffect(() => {
    getSubscriptionList();
    getPartnerSelectedMarketplaces();
  }, []);

  useEffect(() => {}, [selectedSubscription]);

  async function getSubscription(id) {
    let url = "subscription/" + id;
    let method = "GET";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setSelectedSubscription(response.data);
      }
    });
  }

  async function getSubscriptionList() {
    let url = "subscription";
    let method = "GET";
    let response = "";
    url = apiUrl + url;
    response = await axios(url, {
      method: method,
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success && response.status == 200) {
        setAllSubscriptions(response.data);
        const subscriptionPlans = Object.values(response.data);
        const output = subscriptionPlans.map((item) => ({
          value: item.id,
          label: item.plan_name,
        }));
        setSubList(output);
      }
    });
  }

  return (
    <>
      <Form
        {...formItemLayout}
        layout={formLayout}
        id="SaveSubscriptions"
        ref={formRef}
        form={form}
      >
        <div className="container">
          <div className="row pt-5">
            <div className="col-12 col-md-4 d-flex flex-column mt-5 mt-lg-0">
              <label className="text-left h1">
                Subscription Plan<span className="text-danger">*</span>
              </label>
              <Select
                name="name"
                value={subscriptionPlan ? subscriptionPlan : null}
                size="large"
                style={{
                  alignItems: "initial",
                }}
                placeholder="Select Subscription"
                onChange={handleChange}
                options={subList}
              ></Select>
            </div>
            <div className="col-12 col-md-2 d-flex mt-lg-0">
              <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
                <Button
                  className="bg-primary-color ml-2 btn"
                  name="addplan"
                  onClick={handleClick}
                  title="Add new Subscription"
                  icon={<PlusOutlined />}
                >
                  Add Subscription
                </Button>
              </div>
            </div>
          </div>
          <Divider dashed />
          {selectedSubscription ? (
            <div className="">
              <div style={{ marginBottom: "1%" }}>
                <Descriptions
                  title={
                    selectedSubscription ? "Selected Plan: " + selectedSubscription.plan_name : ""
                  }
                  className="text-left card border-info"
                  style={{ padding: "1%" }}
                  bordered
                >
                  <Descriptions.Item label="Duration">
                    {selectedSubscription
                      ? selectedSubscription.subscription_days
                      : ""}{" "}
                    days
                  </Descriptions.Item>
                  <Descriptions.Item label="Amount">
                    {selectedSubscription ? selectedSubscription.amount : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="No of Products">
                    {selectedSubscription
                      ? selectedSubscription.no_of_products
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Item update frequency">
                    {selectedSubscription
                      ? selectedSubscription.item_update_frequency
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Item inventory update frequency">
                    {selectedSubscription
                      ? selectedSubscription.item_inventory_update_frequency
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Order update frequency">
                    {selectedSubscription
                      ? selectedSubscription.order_fetch_frequency
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Order fetch frequency">
                    {selectedSubscription
                      ? selectedSubscription.order_update_frequency
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="No of Marketplaces">
                    {selectedSubscription
                      ? selectedSubscription.no_of_marketplaces
                      : ""}
                  </Descriptions.Item>
                  <Descriptions.Item label="Available Marketplaces">
                    {" "}
                    {selectedSubscription
                      ? selectedSubscription.marketplaces.map(
                          (item) => item.name + ","
                        )
                      : ""}
                  </Descriptions.Item>
                </Descriptions>
              </div>
            </div>
          ) : (
            ""
          )}
            <div className="mb-2">
              <Descriptions
                title="Partner Requirements"
                className="text-left card border-info"
                style={{ padding: "1%" }}
                bordered
              >
                {data.all_questions_answers
                  ? data.all_questions_answers.map((item) => (
                      <Descriptions.Item label={item.question}>
                        {item.answer}
                      </Descriptions.Item>
                    ))
                  : ""}
              </Descriptions>
            </div>
          </div>
      </Form>
    </>
  );
};
export default ChooseSubscription;
