import {
  ColumnChart,
  DonutChart,
  GroupedColumnChart,
} from "../../common/charts/ChartType";
import { DataTable } from "../../common/Table";
import { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Notification } from "../../common/Notification";
import store from "../../../data/store";
import { ApiEndPoint, getApiKey } from "../../../utils/commonutils";
import SubscriptionOverview from "../../../components/admin/subscription/SubscriptionOverview";
import PaymentSummary from "../../admin/payment/PaymentSummary";
import { Spin } from "antd";

export const Dashboard = () => {
  const [subscriptionRevenueData, setSubscriptionRevenueData] = useState({});
  const [subscriptionChartData, setSubscriptionChartData] = useState({});
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  let apiUrl = ApiEndPoint();
  let response = "";
  let baseUrl = "";
  let userToken = localStorage.getItem("Access-Token")
    ? localStorage.getItem("Access-Token")
    : "";
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  //authenticate the user
  useEffect(() => {
    async function getSubscriptionRevenue() {
      let url = "dashboard/sales-chart";
      baseUrl = apiUrl + url;
      response = await axios(baseUrl, {
        method: "GET",
        data: "",
        headers: header,
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          let chartData = response.data;
          chartData["chart"] = {
            caption: "Subscription - Revenue",
            subcaption: "For the year 2022",
            xaxisname: "Month",
            yaxisname: "Revenue ($)",
            dataFormat: "json",
            formatnumberscale: "1",
            numberPrefix: "$",
            plottooltext: "<b>$dataValue</b> from <b>$seriesName</b> in $label",
            theme: "fusion",
          };
          setSubscriptionRevenueData(chartData);
        }
      });
    }

    async function getSubscriptionChartData() {
      let url = "dashboard/subscription-chart";
      baseUrl = apiUrl + url;
      response = await axios(baseUrl, {
        method: "GET",
        data: "",
        headers: header,
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          let chartData = response.data;
          chartData["chart"] = {
            caption: "Subscription Overview",
            subcaption: "Total Subscriptions",
            enablesmartlabels: "1",
            showlabels: "1",
            usedataplotcolorforlabels: "1",
            plottooltext: "$label, <b>$value</b>",
            theme: "fusion",
          };
          setSubscriptionChartData(chartData);
        }
      });
      setLoading(false);
    }
    setLoading(true);
    getSubscriptionRevenue();
    getSubscriptionChartData();
  }, []);
  //user authentication ended

  return (
    <div>
      <h1 className="h4 mb-0 mt-3 text-primary-color">Dashboard</h1>
      <Spin size="large" spinning={loading}>
        <div className="mt-3">
          <div className="row mb-3">
            <div className="col-12 ">
              <div id="main_col" className="row">
                <div className="col-12 pb-1">
                  <SubscriptionOverview size="small" />
                </div>
                <div className="col-12 mt-2 previous-sales-revenue">
                  <div className="bg-secondary-color card-header">
                    <h3 className="h5 w-100 mb-0">
                      Subscription Revenue - 2022
                    </h3>
                  </div>
                  <div className="border-left border-right border-bottom chart p-3">
                    <GroupedColumnChart
                      type="mscolumn3d"
                      dataSource={subscriptionRevenueData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-12 col-xl-5">
              <div id="chart-card" className="card">
                <div className="bg-secondary-color card-header">
                  <h3 className="h5 w-100 mb-0">Subscription Overview</h3>
                </div>
                <div className="img-container p-3 overflow-hidden">
                  <DonutChart
                    type="doughnut3d"
                    dataSource={subscriptionChartData}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-7 mt-3 mt-xl-0">
              <div className="card db-table ">
                <div className="bg-secondary-color card-header d-flex flex-row align-items-center justify-content-between">
                  <h3 className="h5 mb-0">Transaction Summary</h3>
                  <span className="small-p ml-3">Last 7 days</span>
                </div>
                <div className="w-100 overflow-auto">
                  <div id="table-card">
                    <PaymentSummary pagination={false} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Spin>
    </div>
  );
};

//export default Dashboard;
