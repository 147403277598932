import { Checkbox, Form, Input, Select, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";

function MarketplaceForm({
  pageDetails,
  setPageDetails,
  setErrorStatus,
  setCurrent,
  adsource,
  setAdsource,
  catName,
  setCatName,
  catCode,
  setCatCode,
  catPath,
  setCatPath,
}) {
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [loading, setLoading] = useState(false);
  const OPTIONS = [];
  const { Option } = Select;
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let response;
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const onFinish = (values) => {
    setCurrent(1);
    setErrorStatus(false);
  };
  const onSelectChange = useCallback((value) => {
    setAdsource(value);
    setErrorStatus(true);
  });
  const onInputChange = useCallback((event) => {
    setErrorStatus(true);
    if (event.target.name == "cat_name") {
      setCatName(event.target.value);
    }
    if (event.target.name == "cat_code") {
      setCatCode(event.target.value);
    }
    if (event.target.name == "catPath") {
      setCatPath(event.target.value);
    }
  });
  const handleFailure = useCallback(() => {
    setErrorStatus(true);
  });
  useEffect(() => {
    async function listMarketplaces() {
      let url = `marketplace`;
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      })
        .then((response) => {
          if (response.success == true && response.status == 200) {
            const options = [];
            Object.values(response.data).map((type) => {
              options.push({
                label: type.id,
                value: type.name,
              });
            });
            setMarketplaceList(options);
            setLoading(true);
          }
        })
        .catch((error) => {});
    }
    listMarketplaces();
    setPageDetails({
      pagename: "specification-add",
    });
    form.setFieldsValue({
      cat_name: catName,
      adsource: adsource,
      cat_code: catCode,
      catPath: catPath,
    });
  }, []);
  let marketplaceListControl;
  if (loading) {
    Object.values(marketplaceList).map((data) => {
      OPTIONS.push(<Option key={data.label}>{data.value}</Option>);
    });
    marketplaceListControl = (
      <Select
        placeholder="Please select a marketplace"
        className="w-100"
        onChange={onSelectChange}
        value={adsource}
        name="adsource"
      >
        {OPTIONS}
      </Select>
    );
  } else {
    marketplaceListControl = <Spin size="large" />;
  }
  return (
    <Form
      {...layout}
      id="SaveMarketplaceCategory"
      form={form}
      layout="horizontal"
      initialValues={{
        size: "small",
      }}
      style={{ paddingTop: 50 }}
      onFinish={onFinish}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 16,
      }}
      onFinishFailed={handleFailure}
    >
      <Form.Item
        label="Category Name"
        name="cat_name"
        wrapperCol={{
          offset: 2,
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: "The category name is required.",
          },
          {
            pattern: new RegExp(/^[a-zA-Z_\s-]+$/),
            message: "category name is invalid!",
          },
        ]}
      >
        <Input
          type="text"
          name="cat_name"
          maxLength={30}
          className="w-100"
          placeholder="Category Name"
          onChange={onInputChange}
          value={catName}
        />
      </Form.Item>
      <Form.Item
        label="Marketplace"
        name="adsource"
        wrapperCol={{
          offset: 2,
          span: 8,
        }}
        rules={[
          {
            required: true,
            message: "Marketplace name is required.",
          },
        ]}
      >
        {marketplaceListControl}
      </Form.Item>
      <Form.Item
        label="Marketplace Category Code"
        name="cat_code"
        wrapperCol={{
          offset: 2,
          span: 8,
        }}
        rules={[
          {
            pattern: new RegExp(/^[a-zA-Z0-9_.-]*$/),
            message: "Category code is invalid!",
          },
        ]}
      >
        <Input
          type="text"
          name="cat_code"
          maxLength={30}
          className="w-100"
          placeholder="Marketplace Category Code"
          onChange={onInputChange}
          value={catCode}
        />
      </Form.Item>
      <Form.Item
        label="Marketplace Category Path"
        name="catPath"
        wrapperCol={{
          offset: 2,
          span: 8,
        }}
        rules={[
          {
            pattern: new RegExp(/^[a-zA-Z_\s-]+$/),
            message: "Category path is invalid!",
          },
        ]}
      >
        <Input
          type="text"
          name="catPath"
          maxLength={30}
          className="w-100"
          placeholder="Marketplace Category Path"
          onChange={onInputChange}
          value={catPath}
        />
      </Form.Item>
    </Form>
  );
}

export default MarketplaceForm;
