import { message, Spin } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import {
  ApiEndPoint,
  ApiHeaders,
  ApiCallErrorMessage,
} from "../../../utils/commonutils";
import { DataTable } from "../../common/Table";
import { useLocation } from "react-router-dom";

function ManagePermissions() {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);
  const userToken = localStorage.getItem("Access-Token") || "";
  const location = useLocation();

  useEffect(() => {
    getUserPermissions();
  }, [location.key]);

  const columnHeaders = [
    {
      key: "index",
      title: "#",
      dataIndex: "index",
      width: "5%",
      align: "center",
      render: (id, record, index) => {
        ++index;
        return index;
      },
    },
    {
      key: "permission",
      title: "Permission",
      dataIndex: "Permission",
      sorter: (a, b) => a.Permission.localeCompare(b.Permission),
    },
  ];

  const getUserPermissions = async () => {
    try {
      setLoading(false);
      const url = ApiEndPoint() + "admin/permissions";
      const response = await axios(url, {
        method: "GET",
        data: "",
        headers: ApiHeaders(userToken),
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          setPermissions(response.data);
        } else {
          message.error(ApiCallErrorMessage());
        }
      });
    } catch (error) {
      setLoading(false);
      message.error(ApiCallErrorMessage());
    }
  };

  return (
    <div>
      <Spin spinning={loading}>
        <div className="w-100 overflow-auto py-2">
          <div className="table-wrapper">
            <DataTable
              columns={columnHeaders}
              rowClassName="editable-row text-primary-color"
              data={permissions}
              loading={loading}
              pagination={{
                position: ["bottomCenter"],
                defaultPageSize: 100,
              }}
            />
          </div>
        </div>
      </Spin>
    </div>
  );
}
export default ManagePermissions;
