import React, { useState, useEffect } from "react";
import { Form, Input, Table, Space, Popconfirm, message, Button } from "antd";
import {
  DeleteFilled,
  CloseCircleFilled,
  SaveFilled,
  EditFilled,
  PlusOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { setUserDetails } from "../../../../features/userSlice";
import { Notification } from "../../../common/Notification";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import { validate } from "./SubsFeatureValidation";
import PageHeaderBreadCrumb from "../../../common/breadcrumb/PageHeaderBreadCrumb";

function ManageSubsFeatures() {
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subsFeatureData, setSubsFeatureData] = useState([]);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [feature, setFeature] = useState("");
  const [featureError, setFeatureError] = useState({});
  const [searchText, setSearchText] = useState("");

  const handleChange = (e) => {
    setFeature(e.target.value);
    setFeatureError(validate(e.target.value));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let errors = validate(feature);
    setFeatureError(errors);
    if (Object.keys(errors).length === 0) {
      addFeature(feature);
    }
  };

  function addFeature(feature) {
    setButtonLoading(true);
    let response = "";
    let url = "feature";
    url = apiUrl + url;
    let requestBody = {
      name: feature,
    };
    response = axios(url, {
      method: "POST",
      data: requestBody,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        message.destroy();
        setTimeout(() => {
          setFeature("");
          setButtonLoading(false);
          getSubsFeatures();
        }, 2000);
      } else if (response.status === 422) {
        /* server side errors */
        setServerErrors(response, false);
        setButtonLoading(false);
      } else {
        message.error("Oops!! Something went wrong");
        message.destroy();
        setButtonLoading(false);
      }
    });
  }

  function setServerErrors(response, edit = false) {
    let validationErrors = response.data;
    let errors = {};
    for (let key in validationErrors) {
      errors.error = validationErrors[key];
      if (edit) {
        message.error(errors.error);
      } else {
        setFeatureError(errors);
      }
    }
  }

  function updateFeature(record, row) {
    var url = `feature/${record.id}`;
    url = apiUrl + url;
    let response = axios(url, {
      method: "PUT",
      data: row,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        //Update in view
        const newData = [...subsFeatureData];
        const index = newData.findIndex((item) => record.id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setSubsFeatureData(newData);
        }
      } else if (response.status === 422) {
        setServerErrors(response, true);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  }

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const breadcrumbsData = [
    {
      name: "home",
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "text-primary-color",
    },
    {
      name: "ManageFeature",
      pageName: " Subscription Features",
      pageUrl: "/admin/plan-features",
      className: "active-link",
    },
  ];
  /* Service call to retrieve subscription features */
  useEffect(() => {
    setLoading(true);
    getSubsFeatures();
  }, []);
  async function getSubsFeatures() {
    setLoading(true);
    let url = "feature";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubsFeatureData(response.data);
      }
    });
    setLoading(false);
  }
  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input maxLength={50} />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please Input ${title}!`,
              },
              {
                pattern: new RegExp(
                  /^[a-zA-Z0-9][a-zA-Z0-9@#$&%()\-.+,"'_ ]*$/
                ),
                message: "Please enter a valid Subscription feature!",
              },
              {
                max: 50,
                message:
                  "Subscription feature name must not be greater than 50 characters!",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;

  const editFeature = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const deleteFeature = (record) => {
    let url = `feature/${record.id}`;
    url = apiUrl + url;
    let response = axios(url, {
      method: "DELETE",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        const filteredData = subsFeatureData.filter(
          (item) => item.id !== record.id
        );
        setSubsFeatureData(filteredData);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  const cancel = () => {
    setEditingKey("");
  };
  const updateSubscriptionFeature = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...subsFeatureData];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        /* Service call to update subscription feature */
        updateFeature(record, row);
        setEditingKey("");
      } else {
        newData.push(row);
        setSubsFeatureData(newData);
        setEditingKey("");
      }
    } catch (errInfo) {}
  };
  const columns = [
    {
      title: "Subscription Feature",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      width: "70%",
      maxLength: 50,
      editable: true,
      key: "name",
      filteredValue: [searchText],
      onFilter: (value, record) => {
        return String(record.name)
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      },
    },
    {
      title: "Created",
      dataIndex: "created_at",
      sorter: (a, b) => a.created_at.localeCompare(b.created_at),
      key: "created_at",
      width: "20%",
      render: (value, row, index) => {
        return <Moment format="MMMM Do YYYY">{value}</Moment>;
      },
    },
    {
      title: "Action",
      dataIndex: "operation",
      align: "center",
      key: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space key={"span_" + record.id}>
            <SaveFilled
              title="Save Changes"
              key={"save_" + record.id}
              className="text-success mr-2 lead"
              onClick={() => updateSubscriptionFeature(record)}
            ></SaveFilled>
            <CloseCircleFilled
              title="Cancel"
              key={"close_" + record.id}
              className="text-danger lead"
              onClick={cancel}
            ></CloseCircleFilled>
          </Space>
        ) : (
          <Space key={"space_" + record.id}>
            <EditFilled
              title="Edit"
              key={"edit_" + record.id}
              className="text-primary-color lead"
              disabled={editingKey !== ""}
              onClick={() => editFeature(record)}
            ></EditFilled>
            <Popconfirm
              title="Delete this feature?"
              key={"pop_" + record.id}
              onConfirm={() => deleteFeature(record)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteFilled
                title="Delete"
                key={"delete_" + record.id}
                className="text-danger lead"
                disabled={editingKey !== ""}
              ></DeleteFilled>
            </Popconfirm>
          </Space>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        maxLength: 50,
      }),
    };
  });
  return (
    <div>
      <div className="row container">
        <PageHeaderBreadCrumb
          pageTitle="Subscription Features"
          breadcrumbsData={breadcrumbsData}
        />
      </div>

      <div className="container">
        <form onSubmit={handleSubmit}>
          <div className="card">
            <div className="container pb-2">
              <div className="row align-items-center">
                <div className="bg-secondary-color container pt-3 pb-1">
                  <h5>Add a Feature</h5>
                </div>
                <table className="w-75 overflow-scroll m-4">
                  <thead>
                    <tr>
                      <th>Subscription Feature</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <input
                          type="text"
                          name="subscription_feature_name"
                          onChange={handleChange}
                          className="w-100 mt-1"
                          placeholder="Enter Subscription Feature"
                          value={feature || ""}
                          maxLength={50}
                        />
                      </td>
                      <td>
                        <Button
                          loading={buttonLoading}
                          onClick={handleSubmit}
                          className="ml-3 mt-1 btn bg-primary-color"
                          title="Add a Feature"
                          icon={<PlusOutlined />}
                        >
                          Add
                        </Button>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-danger">
                          {featureError.error}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="container">
        <div className="border-info card mb-4 mt-3 p-2 w-100">
          <div className="mb-3 w-100">
            <div className="table-content">
              <div className="container">
                <Form form={form} component={false}>
                  <div className="mt-3 w-100 mt-1">
                    <p className="text-primary-color mb-1 text-bold">
                      Search Feature
                    </p>
                    <Input.Search
                      placeholder="Search Feature"
                      className="mb-2"
                      onSearch={(value) => {
                        setSearchText(value);
                      }}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      maxLength="50"
                    ></Input.Search>
                    <Table
                      components={{
                        body: {
                          cell: EditableCell,
                        },
                      }}
                      bordered
                      loading={loading}
                      dataSource={subsFeatureData}
                      columns={mergedColumns}
                      rowClassName="editable-row text-primary-color"
                      rowKey="id"
                      pagination={{
                        position: ["bottomCenter"],
                        defaultPageSize: 100,
                        showSizeChanger: true,
                      }}
                    />
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageSubsFeatures;
