import React, { useEffect } from "react";
import img from "./../assets/icons/index";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Menu, message, Space } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Notification } from "../components/common/Notification";
import { logout } from "../features/auth/authSlice";
import { useSelector } from "react-redux";
import { ApiEndPoint } from "../utils/commonutils";
import store from "../data/store";

function ProfileMenu(props) {
  const navigate = useNavigate();
  const { auth } = useSelector((state) => state);
  let user = auth.userInfo ? auth.userInfo : null;
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    Authorization: `Bearer ${userToken}`,
  };

  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "profile") {
      navigate("/partner/profile/personal-details");
    }
    if (name == "") {
    }
    //initiates calls to log out user
    if (name == "logout") {
      let partnertoken = user.token;
      let baseUrl = apiUrl + "logout";
      let header = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
        Authorization: `Bearer ${partnertoken}`,
      };
      async function logoutUser() {
        await axios(baseUrl, {
          method: "POST",
          data: "",
          headers: header,
        }).then((response) => {
          if (response) {
            //validate axios call status
            if (response.success === true && response.status == 200) {
              store.dispatch(logout());
              navigate("/");
            } else {
              store.dispatch(logout());
              navigate("/")
            }
          }
        });
      }
      logoutUser();
    }
  };
  let menu = "";
  if (user !== null && user.role == "Partner") {
    menu = (
      <div className="ml-3 ml-md-0 dropdown-menu" aria-labelledby="navbarDropdown">
        <a className="dropdown-item" href="" name="profile" onClick={onClick}>Profile</a>
        <a className="dropdown-item" href="" name="logout" onClick={onClick} style={{ color: "red" }}>Log out</a>
      </div>
    );
  } else if (user !== null && user.role == "Super-Admin") {
    menu = (
      <div className="ml-3 ml-md-0 dropdown-menu" aria-labelledby="navbarDropdown">
        <a className="dropdown-item" href="" name="logout" onClick={onClick} style={{ color: "red" }}>Log out</a>
      </div>
    );
  } else if (user !== null && user.role == "Manager") {
    menu = (
      <div className="ml-3 ml-md-0 dropdown-menu" aria-labelledby="navbarDropdown">
        <a className="dropdown-item" href="" name="logout" onClick={onClick} style={{ color: "red" }}>Log out</a>
      </div>
    );
  }

  return (
    <div className="nav-item dropdown">
      <a className="nav-link" href="" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        <div className="user-icon">
          <img src={img.profileIcon} alt="profile-icon"></img>
          <span>{user.name}</span>
          <img src={img.downarrowIcon} alt="down-arrow-icon"></img>
        </div>
      </a>
      {menu}
    </div>
  );
}

export default ProfileMenu;
