import { Form, Tag, Space, Input, Button, message } from "antd";
import {
  SaveFilled,
  CloseCircleFilled,
  EditFilled,
  SearchOutlined,
  ClearOutlined,
} from "@ant-design/icons";
import { DataTable } from "../../../common/Table";
import { useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import { ApiEndPoint, ApiHeaders } from "../../../../utils/commonutils";
import axios from "axios";

function ParentCategories(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [parentCategoryData, setParentCategoryData] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const isEditing = (record) => record.id === editingKey;

  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiUrl = ApiEndPoint();
  let headers = ApiHeaders(userToken);

  useEffect(() => {
    setLoading(true);
    async function getParentCategoryData() {
      let url = "category?all=true";
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          setParentCategoryData(response.data);
          setLoading(false);
        } else {
          message.error("Failed to load Parent Categories!");
          message.destroy();
          setLoading(false);
        }
      });
    }
    getParentCategoryData();
  }, []);

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const editCategory = (record) => {
    form.setFieldsValue({
      name: "",
      ...record,
    });
    setEditingKey(record.id);
  };

  const handleCancel = () => {
    setEditingKey(false);
  };

  function setServerErrors(response) {
    let validationErrors = response.data;
    let errors = {};
    for (let key in validationErrors) {
      errors.error = validationErrors[key];
      message.error(errors.error);
    }
  }

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  const updateCategoryName = async (record) => {
    try {
      const row = await form.validateFields();
      const newData = [...parentCategoryData];
      const index = newData.findIndex((item) => record.id === item.id);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        updateCategory(record, row);
      } else {
        newData.push(row);
        setParentCategoryData(newData);
      }
      setEditingKey("");
    } catch (errInfo) {
      let errors = errInfo.errorFields;
      Object.values(errors).map((value) => {
        message.error(value.errors);
        message.destroy();
      });
    }
  };

  function updateCategory(record, row) {
    let url = `category/${record.id}`;
    url = apiUrl + url;
    let requestBody = {
      name: row.name,
    };
    response = axios(url, {
      method: "PUT",
      data: requestBody,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        message.destroy();
        /* Update view in front-end*/
        const newData = [...parentCategoryData];
        const index = newData.findIndex((item) => record.id === item.id);
        if (index > -1) {
          const item = newData[index];
          newData.splice(index, 1, {
            ...item,
            ...row,
          });
          setParentCategoryData(newData);
        }
      } else if (response.status === 422) {
        setServerErrors(response);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  }

  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const catTableHeading = [
    {
      title: "CatID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id", "CatID"),
    },
    {
      title: "Category Name",
      dataIndex: "name",
      editable: true,
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...getColumnSearchProps("name", "Category Name"),
    },
    {
      title: "Category Path",
      dataIndex: "breadcrumb",
    },
    {
      title: "Leaf Category",
      dataIndex: "leaf_node",
      align: "center",
      sorter: (a, b) => a.leaf_node - b.leaf_node,
      render: (value, record) =>
        value == 0 ? (
          <Tag color="geekblue">No</Tag>
        ) : (
          <Tag color="pink">Yes</Tag>
        ),
      filters: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      onFilter: (value, record) => {
        return record.leaf_node === value;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space key={"space1_" + record.id}>
            <Button
              title="Save Changes"
              icon={<SaveFilled />}
              className="text-success lead ant-btn-circle"
              key={"saveFilled_" + record.id}
              onClick={() => updateCategoryName(record)}
            />
            <Button
              title="Cancel"
              icon={<CloseCircleFilled />}
              key={"cancel_" + record.id}
              className="text-danger lead ant-btn-circle"
              onClick={handleCancel}
            />
          </Space>
        ) : (
          <Space key={"space2_" + record.id}>
            <Button
              key={"edit_" + record.id}
              icon={<EditFilled />}
              title="Edit"
              className="text-primary-color ant-btn-circle"
              onClick={() => editCategory(record)}
            />
          </Space>
        );
      },
    },
  ];

  const categoryTableHead = catTableHeading.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record, rowIndex) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        maxLength: 50,
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = <Input maxLength={50} />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{
              margin: 0,
            }}
            rules={[
              {
                required: true,
                message: `Please input ${title}!`,
              },
              {
                pattern: new RegExp(/^[a-zA-Z][a-zA-Z- &,'\s]*$/),
                message: "Category Name can only contain alphabets and &,-'",
              },
              {
                max: 50,
                message:
                  "Category Name must not be greater than 50 characters!",
              },
            ]}
          >
            {inputNode}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  };

  return (
    <div className="border-info card mb-4 w-100 pb-3">
      <div className="table-content">
        <div className="container">
          <div className="mt-3 table-wrapper w-100 overflow-auto mt-1">
            <Form form={form} component={false}>
              <DataTable
                components={{
                  body: {
                    cell: EditableCell,
                  },
                }}
                columns={categoryTableHead}
                rowClassName="editable-row text-primary-color"
                rowKey="id"
                data={parentCategoryData}
                bordered
                loading={loading}
                pagination={{
                  position: ["bottomCenter"],
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  showQuickJumper: true,
                }}
              />
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ParentCategories;
