export const validate = (formValues) => {
    let errors = {};
    const regexCompanyName =  /^[a-zA-Z]{2,40}( [a-zA-Z]{2,40})+$/;
    const regexEmail = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+/;
    if (!formValues.name){
        errors.name = "Name cannot be blank"
    }
    if (!regexCompanyName.test(formValues.name)){
        errors.name = "Invalid Name"
    }
    if(!formValues.email){
        errors.email = "E-mail cannot be blank!";
    }
    else if (!regexEmail.test(formValues.email)) {
        errors.email = "E-mail is invalid!";
    }
    if (!formValues.password){
        errors.password = "Password cannot be blank!";
    }
    if (!formValues.passwordAgain){
        errors.passwordAgain = "Password cannot be blank!";
    }
    return errors;
}