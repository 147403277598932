import { Button, Result } from "antd";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";

const ErrorPage = (props) => (
  <Result
    status={props.status || 404}
    title={props.title || "Page Not Found!"}
    subTitle={props.subTitle || "Sorry, the page you visited does not exist."}
    extra={
      props.status != 500 ? (
        <Button type="primary" href={props.href || "/"}>
          Back Home
        </Button>
      ) : (
        ""
      )
    }
  />
);

export default ErrorPage;
