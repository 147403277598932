export default {
    nameIcon: require('./name-icon.png'),
    emailIcon: require('./email-icon.png'),
    companyIcon: require('./company-icon.png'),
    countryIcon: require('./country-icon.png'),
    phoneIcon: require('./phone-icon.png'),
    dashboardIcon: require('./dashboard-icon.png'),
    settingsIcon: require('./settings-icon.png'),
    mobileIcon: require('./mobile-icon.png'),
    tickDisabledIcon: require('./tick-disabled.png'),
    tickIcon: require('./tick-icon.png'),
    passwordIcon: require('./password-icon.png'),
    accountIcon: require('./account-icon.png'),
    editIcon: require('./edit-icon.png'),
    infoIcon: require('./info.png'),
    hiddenEye: require('./hide-Password.png'),
    openEye: require('./show-Password.png'),
    profileIcon: require('./profile-icon.png'),
    downarrowIcon: require('./down-arrow-icon.png'),
    searchIcon: require('./search-icon.png'),
    downArrowLight: require('./down-arrow-light-icon.png'),
    uploadIcon: require('./upload-icon.png'),
    upIcon: require('./up.png')
}
