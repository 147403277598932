import { useState, useEffect } from "react";
import { validate } from "./PlansValidation";

const PlanForm = (callBack) => {  
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      callBack();
    }
  }, [errors, isSubmit, values]);
  const handleSubmit = (event) => {
   // if (event) event.preventDefault();
    setErrors(validate(values));    
    setIsSubmit(false);
    setIsSubmit(true);
  };
  const handleChange = (value, event) => {
    setIsSubmit(false);
    setDisabled(false);
    let newValues = {};
    if (event != null) {
      newValues = { ...values, [event.target.name]: event.target.value ? event.target.value : value };
    } else if(value != null) {
      newValues = { ...values, [value.name]: value.value };
    }
    setErrors(validate(newValues));
    setValues(newValues);
  };

  const handleCancel = (event) => {
    setDisabled(true);
    setIsSubmit(false);
    setErrors("");
    if (event.type == "click") {
      setValues(defaultValues);
    }
  };

  return {
    handleChange,
    handleCancel,
    handleSubmit,
    values,
    defaultValues,
    errors,
    disabled
  };
};
export default PlanForm;
