import { Button, notification, Space, message } from "antd";
import Login_Background from "../../assets/images/login-bg.png";
import Logo from "../../assets/images/ecom-brand-logo.png";
import LogoSmall from "../../assets/images/ecom-brand-small.png";
import useForm from "./useForm";
import { Notification } from "../common/Notification";
import ShowHidePassword from "../common/html/ShowHidePassword";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, json } from "react-router-dom";
import store from "../../data/store";
import { setCredentials } from "../../features/auth/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { ApiEndPoint,getApiKey } from "../../utils/commonutils";
import Icons from "../../assets/icons/index";

const Form = () => {
  const { values, errors, handleChange, handleSubmit } = useForm(login);
  const [serverErrors, setErrors] = useState([]);
  const [apiResponse, setapiResponse] = useState([]);
  const [disableLogin, setDisableLogin] = useState(false);
  const [counter, setCounter] = useState(60);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  let apiUrl = ApiEndPoint();
  const handleRememberMe = (e) => {
    let checked = e.target.checked;
    values.remember_me = checked;
  };

  function timer() {
    if (counter > 0) {
      const id = setInterval(() => setCounter((counter) => counter - 1), 1000);
    }
  }
  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "forgot") {
      navigate("/forgotPassword");
    }
    if (name == "register") {
      navigate("/signup");
    }
  };

  //check the user is already loged in
  useEffect(() => {
    let body = "";
    let url = "get-user";
    let baseUrl = apiUrl + url;
    let response = "";
    let userToken = localStorage.getItem("Access-Token")
      ? localStorage.getItem("Access-Token")
      : "";
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": getApiKey(),
      Authorization: `Bearer ${userToken}`,
    };
    async function fetchUserData() {
      response = await axios(baseUrl, {
        method: "GET",
        data: "",
        headers: header,
      })
        .then((response) => {
          if (response.success === true && response.status == 200) {
            store.dispatch(
              setCredentials({
                name: response.data.name,
                token: response.data.token,
                role: response.data.role,
              })
            );
            localStorage.setItem("Access-Token", response.data.token);
            if (
              response.data.role == "Partner" ||
              response.data.role == "Manager"
            ) {
              navigate("/partner/dashboard");
            } else {
              navigate("/dashboard");
            }
          } else if (response.status == 401) {
            navigate("/");
          } else if (response.status == 403) {
            navigate("/");
          }
        })
        .catch((error) => {
         
        });
    }
    fetchUserData();
  }, []);
  //end user logged in check
  function login() {
    setButtonLoading(true);
    //axios call to set up login
    let url = "login";
    let response = "";
    //let response = PostAxiosCall("POST", url, JSON.stringify(requestBody), "");
    // useEffect(() => {
    //axios call block
    let baseUrl = apiUrl + url;
    let header = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    };
    let requestBody = {
      email: values.userName,
      password: values.password,
      remember_me: values.remember_me,
    };
    try {
      async function loginUser() {
        response = await axios(baseUrl, {
          method: "POST",
          data: requestBody,
          headers: header,
        }).then((response) => {
          setButtonLoading(false);
          if (response) {
            //validate axios call status
            if (response.success === true && response.status == 200) {
              let apiResponse = response.data;
              //set token details into redux  store to access in all stages
              store.dispatch(
                setCredentials({
                  name: response.data.name,
                  token: response.data.token,
                  role: response.data.role,
                })
              );
              let role = apiResponse.role;
              localStorage.setItem("Access-Token", apiResponse.token);
              // token setting in redux store completed
              //Navigate based on role
              switch (role) {
                case "Partner":
                  message.error("Invalid Credentials!");
                  break;
                default:
                  message.success("You are successfully logged in");
                  //Super-admin
                  navigate("/dashboard");
                  break;
              }
            } else if (response.status == 422) {
              /* Show server side validation errors */
              let validationErrors = response.data;
              var errorMsg = "Please fix the error(s)";
              /* Iterate errors and append to the error message */
              Object.keys(validationErrors).map((key, value) => {
                validationErrors[key].map((value, ind) =>
                  setapiResponse((data) => ({
                    ...data,
                    [key]: value,
                  }))
                );
              });
              setErrors(validationErrors);
              message.error(errorMsg);
            } else if (response.status == 403) {
              message.error({ content: response.message, duration: 1 });
            } else if (response.status === 429) {
              message.error(
                "You’ve reached the maximum login attempts. Please try again after one minute"
              );
              setDisableLogin(true);
              timer();
              setTimeout(() => {
                setDisableLogin(false);
              }, 60000);
            } else {
              message.error("Oops!! Something went wrong");
            }
          }
        });
      }
      loginUser();
      message.destroy();
    } catch (error) {
      throw new Error(error);
    }

    //  }, []);
    //axios call end
  }
  return (
    <div>
      {/*--- Header ---*/}
      <header className="header-auth w-100">
        <img src={Logo} alt="logo" className="d-none d-lg-block" />
        <img src={LogoSmall} alt="logo" className="d-block d-lg-none" />
      </header>
      {/*--- End Header ---*/}
      {/*--- Page Content ---*/}
      <form
        onSubmit={handleSubmit}
        noValidate
        onKeyDown={(e) =>
          e.code === "Enter" || e.code === "NumpadEnter" ? handleSubmit(e) : ""
        }
      >
        <div
          className="container login"
          style={{ backgroundImage: `url(${Login_Background})` }}
        >
          <div className="row h-100">
            <div className="d-none d-lg-block col-lg-6"></div>
            <div className="col-12 col-md-12 col-lg-6 p-0">
              <div className="login-container h-100">
                <div className="card auth">
                  <h2 className="h4 text-primary-color">
                    Login to your Account
                  </h2>
                  <p className="text-secondary text-justify">
                    Welcome back! Please enter your credentials
                  </p>
                  <div className="card-input mt-3 input-icon">
                    <label className="text-primary-color">
                      Username <span className="text-danger"> *</span>
                    </label>
                    <div className="login-input-container">
                      <input
                        type="text"
                        className={`form-control ${
                          errors.userName && "is-invalid"
                        }`}
                        placeholder="Username"
                        name="userName"
                        value={values.userName || ""}
                        onChange={handleChange}
                        required
                        maxLength="50"
                      />
                      <img src={Icons.nameIcon} />
                    </div>
                    {errors.userName && (
                      <div className="text-danger">
                        {errors.userName || apiResponse.userName}
                      </div>
                    )}
                  </div>
                  <div className="card-input mt-3 input-icon">
                    <label className="text-primary-color">
                      Password <span className="text-danger"> *</span>
                    </label>
                    <div className="login-input-container">
                      <input
                        type={isRevealPwd ? "text" : "password"}
                        className={`form-control ${
                          errors.password && "is-invalid"
                        }`}
                        placeholder="Password"
                        name="password"
                        onChange={handleChange}
                        value={values.password || ""}
                        required
                        maxLength="10"
                      />
                      <img
                        src={isRevealPwd ? Icons.openEye : Icons.hiddenEye}
                        onClick={() =>
                          setIsRevealPwd((prevState) => !prevState)
                        }
                      />
                    </div>
                    {errors.password && (
                      <div className="text-danger">
                        {errors.password || apiResponse.password}
                      </div>
                    )}
                  </div>
                  <div className="form-action mt-2">
                    <div>
                      {
                        <input
                          type="checkbox"
                          name="remember_me"
                          id="remember_me"
                          title="Remember Credentials"
                          value={values.remember_me || ""}
                          //onChange={handleChange}
                          onChange={handleRememberMe}
                        />
                      }
                      <label
                        htmlFor="remember-me"
                        className="text-primary-color"
                      >
                        Remember Me
                      </label>
                    </div>
                  </div>
                  <div className="login-button mt-4">
                    <Button
                      type="submit"
                      className="bg-primary-color"
                      title="Login"
                      loading={buttonLoading}
                      disabled={disableLogin}
                      onClick={handleSubmit}
                    >
                      Login
                    </Button>
                  </div>
                  <span>
                    {disableLogin && counter > 0 ? (
                      <p className="font-italic pt-1 text-black-50 text-center">
                        (Button will enable in {counter} seconds)
                      </p>
                    ) : (
                      ""
                    )}
                  </span>
                  <p className="text-center mt-2"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {/*--- End Page Content ---*/}
    </div>
  );
};
export default Form;
