import { useState, useEffect, useRef } from "react";
import { Modal, Form, DatePicker, Spin, Radio, message } from "antd";
import useOfferForm from "./UseOfferForm";
import moment from "moment";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import axios from "axios";

function CreateOfferModal({ open, setOpen, mode, loading, setLoading }) {
  const formRef = useRef();
  const { values, errors, handleChange, handleSubmit } =
    useOfferForm(saveOffer);
  const [form] = Form.useForm();
  const { RangePicker } = DatePicker;
  const [serverErrors, setErrors] = useState([]);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const handleCancel = (e) => {
    clearInputAndErros();
    setOpen(false); //Close modal
  };
  const [offerDuraion, setOfferDuration] = useState([]);
  //values.offer_duration = JSON.stringify(offerDuraion);
  values.offer_duration = offerDuraion;

  const clearInputAndErros = () => {
    //clear input values
    values.description = "";
    values.offer_duration = "";
    values.is_subscription_offer = "";
    //clear input erros
    errors.description = "";
    errors.offer_duration = "";
    errors.is_subscription_offer = "";
  };
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let response = "";
  let apiUrl = ApiEndPoint();
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  function saveOffer() {
    /* Split duration by start and end dates on the request*/
    let offerRequest = {
      description: values.description,
      start_date: values.offer_duration[0],
      end_date: values.offer_duration[1],
      is_subscription_offer: values.is_subscription_offer,
    };
    let url = "offer";
    url = apiUrl + url;
    let response = axios(url, {
      method: "POST",
      data: offerRequest,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setErrors([]);
        clearInputAndErros();
        message.success(response.message);
        message.destroy();
        setOpen(false); //Close modal
        setTimeout(() => {
          window.location.reload();
        }, 2000);        
      } else if (response.status === 422) {
        let validationErrors = response.data;
        var errorMsg = "Please fix the error(s)";
        /* Iterate errors and append to the error message */
        Object.keys(validationErrors).map((key, value) => {
          validationErrors[key].map((value, ind) =>
            setErrors((data) => ({
              ...data,
              [key]: value,
            }))
          );
        });
        setErrors(validationErrors);
        message.error(errorMsg);
        message.destroy();
      } else {
        setErrors([]);
        message.error("Oops!! Something went wrong");
        message.destroy();
      }
    });
  }

  return (
    <div>
      <Modal
        forceRender={true}
        title={mode + ` an offer`}
        open={open}
        okText="Save"
        onOk={handleSubmit}
        onCancel={handleCancel}
        confirmLoading={confirmLoading}
        closable={false}
        destroyOnClose={true}
        okButtonProps={{
          disabled: false,
        }}
      >
        <Spin size="large" spinning={loading}>
          <Form
            layout="vertical"
            name="form_in_modal"
            onSubmit={handleSubmit}
            id="saveOffer"
            form={form}
            ref={formRef}
          >
            <Form.Item label="Offer Description" required>
              <input
                type="text"
                name="description"
                value={values.description || ""}
                placeholder="Offer description"
                maxLength={50}
                onChange={handleChange}
                className="w-100"
              />
              <span className="text-danger">
                {errors.description || serverErrors.description}
              </span>
            </Form.Item>
            <Form.Item label="Offer Duration" required>
              <RangePicker
                showTime
                inputReadOnly={true}
                name="offer_duration"
                size="large"
                allowClear={true}
                onChange={(values) => {
                  if (values) {
                    setOfferDuration(
                      values.map((item) => {
                        return moment(item).format("YYYY-MM-DD HH:mm:ss");
                      })
                    );
                  } else {
                    setOfferDuration([]);
                  }
                }}
              />
              <span className="text-danger">
                {errors.offer_duration ||
                  serverErrors.start_date ||
                  serverErrors.end_date}
              </span>
            </Form.Item>
            <Form.Item label="Offer Type" required>
              <Radio.Group
                className="pt-2"
                onChange={handleChange}
                name="is_subscription_offer"
              >
                <Radio value="1">Subscription Based</Radio>
                <Radio value="0">Initial Amount based</Radio>
              </Radio.Group>
              <br></br>
              <span className="text-danger pt-2">
                {errors.is_subscription_offer ||
                  serverErrors.is_subscription_offer}
              </span>
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </div>
  );
}
export default CreateOfferModal;
