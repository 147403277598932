import { useState, useEffect } from "react";
import { DataTable } from "../../../common/Table";
import {
  Form,
  Input,
  Table,
  Breadcrumb,
  Space,
  Popconfirm,
  message,
  Button,
  Tag,
} from "antd";
import {
  SearchOutlined,
  ClearOutlined,
  DeleteFilled,
  InfoCircleFilled,
  PlusOutlined
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { setUserDetails } from "../../../../features/userSlice";
import { Notification } from "../../../common/Notification";
import { useSelector } from "react-redux";
import Moment from "react-moment";
import CreateOfferModal from "./CreateOfferModal";

function ManageSubscriptionOffers() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [subscriptionOffers, setSubscriptionOffers] = useState([]);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Create");
  const [modalLoading, setModalLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
    setMode("Create");
  };
  const handleOfferView = (record) => {
    navigate("/admin/offer/view-offer", {
      state: {
        offer_id: record.id,
      },
    });
  };

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  /* Service call to retrieve subscription offers */
  useEffect(() => {
    setLoading(true);
    let response = "";
    getSubscriptionOffers();
  }, []);
  async function getSubscriptionOffers() {
    setLoading(true);
    let url = "offer";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubscriptionOffers(response.data);
      }
    });
    setLoading(false);
  }

  /* Delete offers */
  const deleteOffer = (record) => {
    let url = "offer/" + record.id;
    url = apiUrl + url;
    let response = axios(url, {
      method: "DELETE",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        const filteredData = subscriptionOffers.filter(
          (item) => item.id !== record.id
        );
        setSubscriptionOffers(filteredData);
      } else {
        message.error("Ooops!! Something went wrong");
      }
    });
  };

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /* Table headers for listing */
  const tableHeading = [
    {
      title: "Offer",
      dataIndex: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      ...getColumnSearchProps("description", "Offer"),
    },
    {
      title: "Start-Date",
      dataIndex: "start_date",
      sorter: (a, b) => a.start_date.localeCompare(b.start_date),
      render: (value, row, index) => {
        return value != null ? (
          <Moment format="MMMM Do YYYY HH:mm:ss">{value}</Moment>
        ) : (
          <p>-</p>
        );
      },
    },
    {
      title: "End-Date",
      dataIndex: "end_date",
      sorter: (a, b) => a.end_date.localeCompare(b.end_date),
      render: (value, row, index) => {
        return value != null ? (
          <Moment format="MMMM Do YYYY HH:mm:ss">{value}</Moment>
        ) : (
          <p>-</p>
        );
      },
    },
    {
      title: "Offer-Type",
      dataIndex: "is_subscription_offer",
      align: "center",
      render: (value, record) =>
        value === 1 ? (
          <Tag color="pink" title="Subscription">
            Subscription
          </Tag>
        ) : (
          <Tag color="blue" title="Initial-Price">
            Initial-Price
          </Tag>
        ),
      filters: [
        { text: "Subscription", value: 1 },
        { text: "Initial-Price", value: 0 },
      ],
      onFilter: (value, record) => {
        return record.is_subscription_offer === value;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return subscriptionOffers.length >= 1 ? (
          <Space>
            <Popconfirm
              title={"Remove offer " + record.description + "?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => deleteOffer(record)}
            >
              <DeleteFilled title="Remove Offer" className="text-danger lead" />
            </Popconfirm>
            <InfoCircleFilled
              title="Offer Details"
              onClick={() => handleOfferView(record)}
              className="lead text-info"
            />
          </Space>
        ) : null;
      },
    },
  ];

  const subscriptionOffersData = [
    {
      offer_name: "3 months 10 % OFF",
      start_date: "2022-12-10 07:00:15",
      end_date: "2022-12-31 07:00:15",
      is_subscription_offer: 1,
    },
    {
      offer_name: "1 months 50 % OFF",
      start_date: "2022-12-01 07:00:15",
      end_date: "2022-12-31 07:00:15",
      is_subscription_offer: 0,
    },
    {
      offer_name: "Christmas Offer",
      start_date: "2022-12-24 00:00:00",
      end_date: "2022-12-25 11:59:00",
      is_subscription_offer: 0,
    },
    {
      offer_name: "New Year Offer",
      start_date: "2022-12-31 00:00:00",
      end_date: "2022=3-01-01 11:59:00",
      is_subscription_offer: 1,
    },
  ];

  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-2 pt-3 text-primary-color">
              Subscription Offers
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
            <Breadcrumb className="text-primary-color">
              <Breadcrumb.Item>
                <a href="/dashboard">Home</a>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="active-link">
                Subscription Offers
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end">
              <Button
                className="bg-primary-color ml-2 btn"
                title="Create a new offer"
                onClick={showModal}
                icon={<PlusOutlined />}
              >
                Create Offer
              </Button>
              <CreateOfferModal
                open={open}
                setOpen={setOpen}
                mode={mode}
                loading={modalLoading}
                setLoading={setModalLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-info card mb-4 mt-3 w-100">
          <div className="table-content">
            <div className="container">
              <div className="mt-3 table-wrapper w-100 overflow-auto mt-1">
                <Form form={form} component={false}>
                  <DataTable
                    columns={tableHeading}
                    rowClassName="editable-row"
                    data={subscriptionOffers}
                    bordered
                    loading={loading}
                    pagination={{
                      position: ["bottomCenter"],
                      defaultPageSize: 100,
                      showSizeChanger: true,
                      showQuickJumper: true,
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
            <div>
              <nav aria-label="Page navigation"></nav>
            </div>
            <div>
              <p className="m-3"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageSubscriptionOffers;
