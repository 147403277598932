import { useEffect, useState } from "react";
import { DataTable } from "../../../common/Table";
import axios from "axios";
import { Notification } from "../../../common/Notification";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setUserDetails } from "../../../../features/userSlice";
import Moment from "react-moment";
import {
  Form,
  Breadcrumb,
  Popconfirm,
  Space,
  Input,
  Button,
  Switch
} from "antd";
import {
  SearchOutlined,
  ClearOutlined,
  CloseOutlined,
  CheckOutlined
} from "@ant-design/icons";
import { data } from "jquery";

function ManageProducts() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  //const [productData, setProductData] = useState([]);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  useEffect(() => {
    setLoading(true);
    async function getAllProducts() {
      let url = "product";
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers
      }).then((response) => {
        if (response.success === true && response.status === 200){
          setLoading(false);
          //setProductData(response.data);
        }
      })
    }
    getAllProducts();
  }, []);

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  }

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  }

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const tableHeading = [
    {
      title: "SKU",
      dataIndex: "sku",
      sorter: (a, b) => a.sku - b.sku,
      ...getColumnSearchProps("sku", "SKU"),
    },
    {
      title: "Product-Title",
      dataIndex: "title",
      ...getColumnSearchProps("title", "Product-Title"),
    },
    {
      title: "UPC",
      dataIndex: "identifier",
      sorter: (a, b) => a.identifier - b.identifier,
    },
    {
      title: "Partner-Company",
      dataIndex: "brand",
      sorter: (a, b) => a.brand.localeCompare(b.brand),
      ...getColumnSearchProps("brand", "Partner-Company"),
    },    
    {
      title: "Status",
      dataIndex: "is_active",
      align: "center",
      sorter: (a, b) => a.is_active - b.is_active,
      render: (value, record) =>
          <Switch
            defaultChecked={value}
            checkedChildren={<CheckOutlined title="Active" />}
            unCheckedChildren={<CloseOutlined title="Inactive" />}
          ></Switch>
    },
  ]

  const productData = [
    {
      "sku": "14229",
      "title": "White Corner Bathroom Wall Mount Sink Vessel Counter Round",
      "identifier": "096962376277",
      "brand": "Rodriguez Group",
      "is_active": 1
    },
    {
      "sku": "17942",
      "title": "Corner Wall Mount Bathroom Sink Above Counter Vessel White",
      "identifier": "096962376284",
      "brand": "Spencer-Grant",
      "is_active": 1
    },
    {
      "sku": "97898",
      "title": "White Console Sink Belle Epoque with Black Nickel Legs and Faucet",
      "identifier": "0671839641704",
      "brand": "Harvey Ltd",
      "is_active": 0
    },
    {
      "sku": "65237",
      "title": "Children Bathroom Console Sink Amber Glass Vanity | Renovator's Supply",
      "identifier": "096962343385",
      "brand": "Green-Herzog",
      "is_active": 1
    },
    {
      "sku": "41326",
      "title": "Biscuit Belle Epoque Porcelain Wall Mount Double Console Sink",
      "identifier": "096962368937",
      "brand": "Vicoria Wills",
      "is_active": 0
    }
  ]

  return (
    <div>         
      {/*--- Page Content ---*/}
      <div className="catalog">
        <div className="container">
          <h1 className="h4 mb-0 mt-4 mb-2 text-primary-color">Product Dashboard</h1>               
            <Breadcrumb className="text-primary-color">
              <Breadcrumb.Item><a href="/dashboard">Home</a></Breadcrumb.Item>
              <Breadcrumb.Item className="active-link">Product Dashboard</Breadcrumb.Item>
            </Breadcrumb>              
        </div>
      </div>
      <div className="container">
        <div className="border-info card mb-4 mt-3 w-100">
          <div className="table-content">
            <div className="container">
              <div className="mt-3 table-wrapper w-100 overflow-auto mt-1">
                <Form form={form} component={false}>
                  <DataTable
                    columns={tableHeading}
                    data={productData}
                    bordered
                    loading={loading}
                    pagination={{
                      position: ["bottomCenter"],
                      defaultPageSize: 100,
                      showSizeChanger: true,
                      showQuickJumper: true,
                    }}
                  />
                </Form>
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
            <div>
              <nav aria-label="Page navigation"></nav>
            </div>
            <div>
              <p className="m-3"></p>
            </div>
          </div>
        </div>
      </div>
      {/*--- End Page Content ---*/}
    </div>
  );
}
export default ManageProducts;
