import { Modal } from 'antd';
import { useCallback } from 'react';
import RenewSubscription from '../subscription/RenewSubscription';
function UpgradeSubscription({ open, setOpen, upgradeSubscriptionPlan, setUpgradePlan }) {
    const handleCancel = useCallback(
        (event) => {
            setOpen(false);
            Modal.destroyAll();
        });
    return (
        <Modal
            title="Upgrade Subscription"
            open={open}
            onOk={upgradeSubscriptionPlan}
            onCancel={handleCancel}
            okText="Upgrade Plan"
            width={1500}
            style={{ top: 20, width: 1500, overflowx: true }}
        >
            <RenewSubscription setUpgradePlan={setUpgradePlan} />
        </Modal>
    );
}
export default UpgradeSubscription;