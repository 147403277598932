import axios from "axios";
import { useNavigate } from "react-router-dom";
export function RequestInterceptor() {
  axios.interceptors.request.use(
    function (response) {
      if (response) {
        //console.log(response);
        //store.dispatch(serviceResponse(response.data));
      }
    },
    function (error) {
      if (error.response) {
        //store.dispatch(serviceResponse(error.response.data));
      }
    }
  );
}

export function ResponseInterceptor() {
  axios.interceptors.response.use(
    function (response) {
      if (response) {
        //store.dispatch(serviceResponse(response.data));
        return response.data;
      }
    },
    function (error) {
      // const statusCode = error.response.status;
      // if(statusCode == 401){
      //   window.location = "/dashboard"
      // }
      if (error.response) {
       // store.dispatch(serviceResponse(error.response.data));
        return error.response.data;
      }
    }
  );
}
