export const validate = (formValues) => {
  let errors = {};
  const regexName = /^[0-9]+$/;
  if (!formValues.days) {
    errors.days = "Days cannot be blank!";
  } else if (!regexName.test(formValues.days)) {
    errors.days = "Days is invalid!";
  }else if (formValues.days % 30 != 0){
    errors.days = "Please enter the multiples of 30 (1 month)";
  }
  return errors;
};
