import { Button, Checkbox, Form, Input, message, Select, Spin } from "antd";
import axios from "axios";
import { data } from "jquery";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ApiEndPoint, ApiHeaders } from "../../../../utils/commonutils";

function AddCategory(props) {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [mode, setMode] = useOutletContext();
  const [checked, setChecked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [parentCategories, setParentCategories] = useState([
    {
      id: 0,
      parent_id: null,
      name: "None",
    },
  ]);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 8,
    },
  };
  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiUrl = ApiEndPoint();
  let headers = ApiHeaders(userToken);

  useEffect(() => {
    setMode("Add");
  }, []);

  useEffect(() => {
    /* Load Parent Categories */
    getParentCategories();
  }, []);

  async function getParentCategories() {
    setLoading(true);
    let url = "category/?all=true";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setParentCategories([...parentCategories, ...response.data]);
        setLoading(false);
      } else {
        message.error("Failed to load Parent Categories!");
        message.destroy();
        setLoading(false);
      }
    });
  }

  async function saveCategory(values) {
    setButtonLoading(true);
    setButtonDisable(true);
    let url = "category";
    url = apiUrl + url;
    let cat_name = values.name;
    let leaf_cat = checked == true ? 1 : 0;
    let parent_cat = values.parent_id;
    let requestBody = {
      name: cat_name.trim(),
      leaf_node: leaf_cat,
    };
    if (parent_cat !== 0) {
      requestBody.parent_id = parent_cat;
    }
    response = await axios(url, {
      method: "POST",
      data: requestBody,
      headers: headers,
    })
      .then((response) => {
        if (response.success === true && response.status === 200) {
          message.success(response.message);
          message.destroy();
          form.setFieldsValue({
            name: "",
            parent_id: "",
            leaf_node: "",
          });
          setTimeout(() => {
            navigate("/admin/category/category-dashboard");
          }, 2000);
        } else if (response.status === 422) {
          message.error("Please fix the error(s)");
          handleServerValidationErrors(response.data);
        } else if (response.status === 401) {
          message.error(response.message);
        }
        message.destroy();
        setButtonLoading(false);
        setButtonDisable(false);
      })
      .catch((error) => {
        setButtonLoading(false);
        setButtonDisable(false);
        navigate("/");
      });
  }

  function handleServerValidationErrors(validationErrors) {
    Object.keys(validationErrors).map((key, value) => {
      validationErrors[key].map((val, ind) => {
        form.setFields([
          {
            name: key,
            errors: [val],
          },
        ]);
      });
    });
  }

  const onFinish = (values) => {
    saveCategory(values);
  };
  const onHandleClick = useCallback(() => {
    setMode("");
    navigate("/admin/category/category-dashboard");
  });
  const onChange = (e) => {
    setChecked(e.target.checked);
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="container m-2">
          <div className="card bg-light">
            <legend className="container">
              <Form
                {...layout}
                form={form}
                layout="horizontal"
                initialValues={{
                  size: "small",
                }}
                style={{ paddingTop: 50 }}
                onFinish={onFinish}
              >
                <Form.Item
                  className="pb-1"
                  label="Category Name"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Category Name cannot be blank!",
                    },
                    {
                      pattern: /^[a-zA-Z][a-zA-Z- &,'\s]*$/,
                      message:
                        "Category Name can only contain alphabets and &,-'",
                    },
                  ]}
                >
                  <Input
                    type="text"
                    name="name"
                    maxLength={30}
                    className="w-100"
                    placeholder="Category Name"
                  />
                </Form.Item>
                <Form.Item
                  label="Parent Category"
                  name="parent_id"
                  rules={[
                    {
                      required: true,
                      message: "The parent category is required.",
                    },
                  ]}
                >
                  <Select
                    name="parent_id"
                    showSearch
                    style={{
                      width: 200,
                    }}
                    className="w-100"
                    placeholder="Select Parent Category"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").includes(input)
                    }
                  >
                    {Object.values(parentCategories).map((categoryItem) => (
                      <Select.Option
                        name="parent_id"
                        key={categoryItem.id}
                        value={categoryItem.id}
                      >
                        {categoryItem.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label="Is Leaf Category"
                  name="leaf_node"
                  valuePropName="checked"
                >
                  <Checkbox
                    name="leaf_node"
                    onChange={onChange}
                    value={checked || false}
                  />
                </Form.Item>

                <div className="text-center pb-3">
                  <Button
                    className="bg-primary-color ml-2 btn"
                    htmlType="submit"
                    title="Save"
                    loading={buttonLoading}
                    disabled={buttonDisable}
                  >
                    Save
                  </Button>
                  <Button
                    className="btn ml-2 btn-cancel"
                    onClick={onHandleClick}
                    title="Cancel"
                    disabled={buttonDisable}
                  >
                    Cancel
                  </Button>
                </div>
              </Form>
            </legend>
          </div>
        </div>
      </Spin>
    </>
  );
}

export default AddCategory;
