import {
  ClearOutlined,
  ArrowUpOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import { DataTable } from "../../common/Table";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import { useEffect, useRef, useState } from "react";
import {
  Breadcrumb,
  Button,
  Input,
  message,
  Popconfirm,
  Space,
  Switch,
} from "antd";
import Highlighter from "react-highlight-words";
import UpgradeSubscription from "./UpgradeSubscription";
import SubscriptionOverview from "../subscription/SubscriptionOverview";
import Moment from "react-moment";

function PartnerSubscription(props) {
  const navigate = useNavigate();
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const [partnerSubscriptions, setPartnerSubscriptions] = useState([]);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [open, setOpen] = useState(false);
  const [partnerSubscriptionDetails, setPartnerDetails] = useState({});
  const [upgradePlan, setUpgradePlan] = useState({});
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  useEffect(() => {
    getPartnerSubscriptions();
    async function getPartnerSubscriptions() {
      let url = "admin/getPartner";
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          //setPartnerSubscriptions(response.data);
          formatPartnerSubscriptions(response.data);
        } else {
          message.error("Oops..! Something went wrong");
        }
      });
    }
  }, []);

  function formatPartnerSubscriptions(response) {
    var partnerSubscriptionData = [];
    Object.values(response).forEach(function (data, key) {
      let subscriptionInfo = data["subscriptions"][0];
      let subscription_id,
        subscription_plan,
        subscription_start_date,
        subscription_end_date;
      subscription_id =
        subscription_plan =
        subscription_start_date =
        subscription_end_date =
          "-";
      if (subscriptionInfo) {
        for (var index in subscriptionInfo) {
          subscription_id = subscriptionInfo["subscription_id"];
          subscription_plan = subscriptionInfo["plan"];
          subscription_start_date = subscriptionInfo["start_date"];
          subscription_end_date = subscriptionInfo["end_date"];
        }
      }
      partnerSubscriptionData.push({
        partner_id: data["partner_id"],
        first_name: data["first_name"],
        partner_name: data["partner_name"],
        company_name: data["company_name"],
        subscription_id: subscription_id,
        subscription_plan: subscription_plan,
        subscription_start_date: subscription_start_date,
        subscription_end_date: subscription_end_date,
      });
    });
    setPartnerSubscriptions(partnerSubscriptionData);
  }

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /* Service Call to upgrade subscription plan */
  const listAvailableSubscriptionPlans = (record) => {
    setPartnerDetails(record);
    setOpen(true);
  };
  const upgradeSubscriptionPlan = () => {
    console.log("inside the service call");
    console.log(partnerSubscriptionDetails);
  };
  //temporary data for table
  const columns = [
    {
      title: "Partner",
      dataIndex: "partner_name",
      sorter: (a, b) => a.partner_name.localeCompare(b.partner_name),
      ...getColumnSearchProps("partner_name", "Partner Name"),
    },
    {
      title: "Company",
      dataIndex: "company_name",
      sorter: (a, b) => a.company_name.localeCompare(b.company_name),
      ...getColumnSearchProps("company_name", "Company"),
    },
    {
      title: "Subscription",
      align: "center",
      dataIndex: "subscription_plan",
      sorter: (a, b) => a.subscription_plan.localeCompare(b.subscription_plan),
    },
    {
      title: "Start Date",
      dataIndex: "subscription_start_date",
      sorter: (a, b) =>
        a.subscription_start_date.localeCompare(b.subscription_start_date),
      render: (value, row, index) => {
        return value !== "-" ? (
          <Moment format="MMMM Do YYYY, h:mm:ss">{value}</Moment>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Renewal Date",
      dataIndex: "subscription_end_date",
      sorter: (a, b) =>
        a.subscription_end_date.localeCompare(b.subscription_end_date),
      render: (value, row, index) => {
        return value !== "-" ? (
          <Moment format="MMMM Do YYYY, h:mm:ss">{value}</Moment>
        ) : (
          "-"
        );
      },
    },
    {
      title: "Action",
      dataIndex: "Action",
      align: "center",
      render: (_, record) => {
        return partnerSubscriptions.length >= 1 ? (
          <Space>
            <Popconfirm
              title={"Upgrade Subscription for " + record.first_name + " ?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => listAvailableSubscriptionPlans(record)}
            >
              <Button
                className="bg-primary-color btn"
                icon={<ArrowUpOutlined />}
                title="Upgrade Subscription"
              >
                Upgrade
              </Button>
            </Popconfirm>
            <UpgradeSubscription
              open={open}
              setOpen={setOpen}
              upgradeSubscriptionPlan={upgradeSubscriptionPlan}
              setUpgradePlan={setUpgradePlan}
            />
          </Space>
        ) : null;
      },
    },
  ];

  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-2 mb-2 pt-3 text-primary-color">
              Partner Subscriptions
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
            <Breadcrumb className="text-primary-color">
              <Breadcrumb.Item>
                <a href="/dashboard"> Home </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="active-link">
                Partner Subscriptions
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
      </div>
      <div className="card border-info p-2 mt-3">
        <SubscriptionOverview />
      </div>
      <div className="card w-100 mt-4 mb-4 border-info">
        <div className="table-content">
          <div className="container">
            <div className="row align-items-center pb-3"></div>
            <div className="table-wrapper w-100 overflow-auto mt-1">
              <DataTable
                data={partnerSubscriptions}
                columns={columns}
                pagination={{
                  position: ["bottomCenter"],
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  showQuickJumper: true,
                }}
              />
            </div>
          </div>
        </div>
        <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
          <div>
            <nav aria-label="Page navigation"></nav>
          </div>
          <div>
            <p className="m-3"></p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PartnerSubscription;
