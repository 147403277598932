import { Breadcrumb, Button } from "antd";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { Outlet, useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import { Notification } from "../../common/Notification";
import { PlusOutlined } from "@ant-design/icons";
import PageHeaderBreadCrumb from "../../common/breadcrumb/PageHeaderBreadCrumb";
import { useLocation } from "react-router-dom";

function ManagePlans() {
  //initialization section started
  const navigate = useNavigate();
  const location = useLocation();
  const stateFulObject = location.state ? location.state.mode : "";
  const partnerRequirement = location.state ? location.state.userData : "";
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const [mode, setMode] = useState(stateFulObject);
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState("");
  let buttonStatus = "";
  //const [planDetaileToEdit, setPlanDetailsToEdit] = useState({});
  const handleClick = (event) => {
    setMode("Add");
    navigate("/admin/subscriptions/create-subscription");
  };
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const breadcrumbsData = [
    {
      name: "home",
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "text-primary-color",
    },
    {
      name: "ManageSub",
      pageName: "Manage Subscriptions",
      className: "active-link",
    },
  ];
  //initialization section ended
  //handles redirection from the page
  const handleRedirection = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "home") {
      navigate("/dashboard");
    }
    if (name == "ManageSubscription") {
      setMode("");
      navigate("/admin/subscriptions/manage-subscription");
    }
  };

  //service call to list available subscription plans
  async function getSubscriptionPlans() {
    let url = "subscription";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setPlanDetails(response.data);
        // prepareExportData(response.data);
      }
    });
    setLoading(false);
  }
  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-3 mb-0 pt-3 text-primary-color">
              Manage Subscriptions
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
           <PageHeaderBreadCrumb breadcrumbsData={breadcrumbsData}/>
          </div>
          {}
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
              <Button
                className="bg-primary-color ml-2 btn"
                name="addplan"
                onClick={handleClick}
                title="Add new plan"
                style={
                  (mode == "Add") ? { display: "none" } : { display: "inline" }
                }
                icon={<PlusOutlined />}
              >
                Add Subscription
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Outlet context={[mode, setMode]} />
      </div>
    </div>
  );
}

export default ManagePlans;
