import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Image,
  Popconfirm,
  Space,
  Button,
  Upload,
  message,
  Spin,
} from "antd";
import UseMarketplaceForm from "./validate-marketplaces/UseMarketplaceForm";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEndPoint } from "../../../utils/commonutils";
import { useSelector } from "react-redux";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { flushSync } from "react-dom";

function MarketplaceModal({
  open,
  setOpen,
  mode,
  defaultValues,
  loading,
  setLoading,
}) {
  const [form] = Form.useForm();
  const { values, errors, handleChange, handleSubmit } =
    UseMarketplaceForm(saveMarketplace);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [logoVisible, setLogoVisible] = useState("block");
  const [serverErrors, setErrors] = useState([]);
  const [fileError, setFileError] = useState(0);
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const formRef = useRef();
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    Authorization: `Bearer ${userToken}`,
  };

  const removeLogo = () => {
    values.logo = "";
    setLogoVisible("none");
  };

  const clearInputAndErros = () => {
    //clear input values
    values.name = "";
    values.description = "";
    values.logo = "";
    //clear input erros
    errors.name = "";
    errors.description = "";
    errors.logo = "";
  };

  const handleCancel = (e) => {
    clearInputAndErros();
    setOpen(false); //Close modal
  };

  const handleFile = async (e) => {
    let fileError = 0;
    setFileError(0);
    serverErrors.logo = "";
    errors.logo = "";
    values.logo = "";
    const file = e.target.files[0];

    if (file) {
      errors.logo = "";
      serverErrors.logo = "";
      let fileSize = file.size;
      let fileType = file.type;
      let allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];

      if (fileSize > 1000000) {
        setFileError(1);
        fileError = fileError + 1;
        errors.logo = "Logo file must be smaller than 1 MB";
      }
      if (allowedImageTypes.indexOf(fileType) == -1) {
        errors.logo = "Please upload file having extensions: .jpeg/.jpg/.png";
        setFileError(1);
        fileError = fileError + 1;
      }
      if (fileError === 0) {
        errors.logo = "";
        values.logo = file;
      }
    }
  };

  function saveMarketplace() {
    setConfirmLoading(true);
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append(
      "description",
      values.description ? values.description : ""
    );
    if(values.logo){
      formData.append("logo", values.logo ? values.logo : "");
    }
    //API Call to add marketplace
    let response = "";
    let url =
      mode == "Edit"
        ? `marketplace/${defaultValues.id}`
        : "marketplace/";
    url = apiUrl + url;
    headers["Content-Type"] = "multipart/form-data";
    response = axios(url, {
      method: "POST",
      data: formData,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setErrors([]);
        clearInputAndErros();
        message.success(response.message);
        message.destroy();
        setOpen(false); //Close modal
      } else if (response.status === 422) {
        /* Show server side validation errors */
        setErrors("");
        let validationErrors = response.data;
        var errorMsg = "Please fix the error(s)";
        /* Iterate errors and append to the error message */
        Object.keys(validationErrors).map((key, value) => {
          validationErrors[key].map((value, ind) =>
            setErrors((data) => ({
              ...data,
              [key]: value,
            }))
          );
        });
        setErrors(validationErrors);
        message.error(errorMsg);
        message.destroy();
      } else {
        setErrors([]);
        message.error("Oops!! Something went wrong");
        message.destroy();
      }
    });
    setConfirmLoading(false);
  }
  useEffect(() => {
    if (open) {
      if (mode == "Edit") {
        values.name = defaultValues.name;
        values.description = defaultValues.description;
        form.setFieldsValue(values);
        setLoading(false);
      }
    } else {
      values.name = "";
      values.description = "";
      form.setFieldsValue(values);
    }
  }, [defaultValues]);
  return (
    <Modal
      forceRender={true}
      title={mode + ` Marketplace`}
      open={open}
      okText={mode == "Add" ? "Save" : "Edit"}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      closable={false}
      destroyOnClose={true}
      okButtonProps={{
        disabled: false,
      }}
    >
      <Spin size="large" spinning={loading}>
        <Form
          layout="vertical"
          name="form_in_modal"
          onSubmit={handleSubmit}
          id="saveMarketplaces"
          form={form}
          ref={formRef}
          initialValues={defaultValues}
          encType="multipart/form-data"
        >
          <Form.Item label="Marketplace" required>
            <input
              type="text"
              name="name"
              value={values.name || ""}
              placeholder="Marketplace Name"
              maxLength={30}
              onChange={handleChange}
              className="w-100"
            />
            <span className="text-danger">
              {errors.name || serverErrors.name}
            </span>
          </Form.Item>
          <Form.Item label="Description">
            <input
              type="textarea"
              name="description"
              value={values.description || ""}
              placeholder="Description"
              maxLength={50}
              onChange={handleChange}
              className="w-100"
            />
            <span className="text-danger">
              {errors.description || serverErrors.description}
            </span>
          </Form.Item>
          <Form.Item label="Logo">
            {defaultValues.logo != null ? (
              <div style={{ display: logoVisible }}>
                <Image
                  className="img-thumbnail mb-2"
                  width={100}
                  src={defaultValues.logo}
                ></Image>
                <a
                  className="float-right h5 text-danger"
                  title="Delete"
                  onClick={removeLogo}
                >
                  {/* <DeleteOutlined></DeleteOutlined> */}
                </a>
              </div>
            ) : (
              ""
            )}
            <input
              type="file"
              name="logo"
              style={{ paddingRight: "40%", paddingTop: "5px" }}
              onChange={(e) => {
                handleFile(e);
              }}
              className="w-100"
            ></input>
            <span className="text-danger">
              {errors.logo || serverErrors.logo}
            </span>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default MarketplaceModal;
