
import SideBarMenu from "./SideBarMenu";
function Sidebar(props) {
  return (
    <div>
     <SideBarMenu/>
    </div>
  );
}

export default Sidebar;
