//import logo from './logo.svg';
//import './App.css';
import { useRoutes, Outlet } from "react-router-dom";
import routes from "./routes/routes";
import 'antd/dist/antd.css';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "./assets/style/core.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastProvider } from "react-toast-notifications";
import { ThemeProvider } from "./context/themeContext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
const App = () => {
  let useCustomRoutes = useRoutes(routes);
  return (
    <ToastProvider>
      <ThemeProvider value = "">
        {useCustomRoutes}
      </ThemeProvider>
    </ToastProvider>
  );
};

export default App;
