import { useSelector } from "react-redux";
import { DashboardOutlined, UserOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import { useNavigate } from "react-router-dom";
import img from "./../assets/icons/index";
function SideBarMenu(props) {
  const { auth } = useSelector((state) => state);
  let user = auth.userInfo ? auth.userInfo : null;
  const navigate = useNavigate();
  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name; 
    if (name == "dashboard") {
      if (user.role == "Partner" || user.role == "Manager"){
        navigate("/partner/dashboard");
      }else{
        navigate("/dashboard");
      }
    }
    if (name == "profile") {
      navigate("/partner/profile/personal-details");
    }
    if(name == "support"){
      if (user.role == "Partner" || user.role == "Manager"){
        navigate("/partner/dashboard");
      }else{
        navigate("/dashboard");
      }
    }
  };
  if (user !== null && user.role == "Super-Admin") {
    return (
      <div className="side-menu">
        <ul>
          <li
            className="side-menu-icon d-flex flex-column align-items-center"
            data-toggle="tooltip"
            data-placement="top"
            title="Dashboard"
          >
            <a href="" onClick={onClick}>
              <img src={img.dashboardIcon} className="side-icon" alt="" name="dashboard" />
            </a>
            <span className="d-lg-none">Dashboard</span>
          </li>
        </ul>
      </div>
    );
    } else {
    return (
      <div>
        <div className="side-menu">
          <ul></ul>
        </div>
      </div>
    );
  }
}

export default SideBarMenu;
