import { useState, useEffect } from "react";
import { DataTable } from "../../common/Table";
import { useSelector } from "react-redux";
import axios from "axios";
import {
  Breadcrumb,
  Popconfirm,
  Space,
  Form,
  Input,
  Button,
  message,
  Avatar,
  Image,
  List,
  Switch,
} from "antd";
import {
  DeleteOutlined,
  SearchOutlined,
  ClearOutlined,
  EditFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint } from "../../../utils/commonutils";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import MarketplaceModal from "./MarketplaceModal";

function ManageMarketplaces(props) {
  const [marketplaceData, setMarketplaceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("Add");
  const [defaultValues, setDefaultValues] = useState({});
  const [modalLoading, setModalLoading] = useState(false);
  const showModal = () => {
    setOpen(true);
    setMode("Add");
  };

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    Authorization: `Bearer ${userToken}`,
  };

  /* Service call to retrieve markerplaces */
  useEffect(() => {
    setLoading(true);
    getMarketplaces();
  }, []);
  useEffect(() => {
    if (!open) {
      getMarketplaces();
    }
  }, [open]);
  async function getMarketplaces() {
    let url = "marketplace";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setMarketplaceData(response.data);
      }
    });
    setLoading(false);
  }
  const editMarketplace = (record) => {
    setModalLoading(true);
    getMarketplaceDetails(record.id);
    setMode("Edit");
    setOpen(true);
  };
  const handleStatus = (value, record) => {
    //Setting status
    updateMarketplaceStatus(value, record);
    message.destroy();
  };

  const updateMarketplaceStatus = (value, record) => {
    let url = `marketplace/${record.id}`;
    url = apiUrl + url;
    let requestBody = {
      is_active: value,
    };
    let response = axios(url, {
      method: "POST",
      data: requestBody,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  /* Service Call to delete partners */
  const deleteMarketplace = (record) => {
    let url = `marketplace/${record.id}`;
    url = apiUrl + url;
    let response = axios(url, {
      method: "DELETE",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        const filteredData = marketplaceData.filter(
          (item) => item.id !== record.id
        );
        setMarketplaceData(filteredData);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  async function getMarketplaceDetails(id) {
    let url = `marketplace/+${id}`;
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setDefaultValues(response.data);
      }
    });
  }

  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-3 mb-0 pt-3 text-primary-color">
              Manage Marketplaces
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
            <Breadcrumb className="text-primary-color">
              <Breadcrumb.Item><a href="/dashboard">Home</a></Breadcrumb.Item>
              <Breadcrumb.Item className="active-link">Manage Marketplaces</Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
              <Button
                className="bg-primary-color ml-2"
                title="Add new Marketplace"
                onClick={showModal}
              >
                Add Marketplace
              </Button>
              <MarketplaceModal
                open={open}
                setOpen={setOpen}
                mode={mode}
                defaultValues={defaultValues}
                loading={modalLoading}
                setLoading={setModalLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="border-info card mb-4 mt-3 w-100">
          <div className="table-content">
            <div className="container">
              <div className="mt-3 w-100 overflow-auto mt-1">
                <List
                  className="demo-loadmore-list"
                  loading={loading}
                  itemLayout="horizontal"
                  dataSource={marketplaceData}
                  renderItem={(record) => (
                    <List.Item
                      className="p-3 mb-2 border card-sub-head"
                      actions={[
                        <Space>
                          <Button
                            type="primary"
                            shape="circle"
                            icon={<EditFilled />}
                            size="small"
                            title="Edit"
                            onClick={(e) => editMarketplace(record)}
                          />

                          <Popconfirm
                            title={"Remove " + record.name + "?"}
                            okText="Yes"
                            cancelText="No"
                            onConfirm={() => deleteMarketplace(record)}
                          >
                            <Button
                              type="danger"
                              shape="circle"
                              icon={<DeleteOutlined />}
                              size="small"
                              title="Remove"
                            />
                          </Popconfirm>
                        </Space>,
                      ]}
                    >
                      <List.Item.Meta
                        avatar={
                          record.logo ? (
                            // <Avatar
                            //   src={record.logo}
                            //   style={{ width: 155, height: 50 }}
                            // />
                            <Image 
                            src={record.logo}
                            className="ml-4 w-50 rounded"
                            width={100}
                            >
                          </Image>
                          ) : (
                            ""
                          )
                        }
                        title={<p>{record.name}</p>}
                        description={
                          record.description != "null"
                            ? record.description
                            : "-"
                        }
                      />
                      <div>
                        <Space>
                          <Switch
                            onChange={(e) => handleStatus(e, record)}
                            defaultChecked={record.is_active}
                            checkedChildren={<CheckOutlined title="Active" />}
                            unCheckedChildren={
                              <CloseOutlined title="Inactive" />
                            }
                          ></Switch>
                        </Space>
                      </div>
                    </List.Item>
                  )}
                />
              </div>
            </div>
          </div>
          <div className="w-100 d-flex flex-column-reverse flex-md-row-reverse justify-content-between align-items-center">
            <div>
              <nav aria-label="Page navigation"></nav>
            </div>
            <div>
              <p className="m-3"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ManageMarketplaces;
