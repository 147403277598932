import { Button, Checkbox, Col, Input, Row, Space, Spin } from "antd";
import PhoneInput from "../../../common/PhoneInputField";
import { useNavigate } from "react-router-dom";
import PageHeaderBreadCrumb from "../../../common/breadcrumb/PageHeaderBreadCrumb";
import CountrySelector from "../../../common/CountrySelector/CountrySelector";
import TimezoneSelector from "../../../common/Timezone";
import AddPartnerForm from "./AddPartnerForm";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";

const AddPartner = () => {
  const navigate = useNavigate();
  const {
    values,
    errors,
    timezones,
    handleChange,
    handleSubmit,
    handleCountry,
    handlePhone,
    handleTimezoneChange,
    spinning,
  } = AddPartnerForm();

  const breadcrumbsData = [
    {
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "",
    },
    {
      pageName: "Manage Partner",
      pageUrl: "/admin/manage-partners",
      className: "",
    },
    {
      pageName: "Add Partner",
      pageUrl: "/admin/add-partner",
      className: "active-link",
    },
  ];

  return (
    <>
      <div className="row container col-12">
        <div className="col-6">
          <PageHeaderBreadCrumb
            pageTitle="Add Partner"
            breadcrumbsData={breadcrumbsData}
          />
        </div>
        <div className="col-6 mt-5">
          {/* Search */}
          <div className="container text-right"></div>
        </div>
      </div>

      <Spin spinning={spinning}>
        <div className="container">
          <div className="card p-4">
            <form
              onSubmit={handleSubmit}
              noValidate
              onKeyDown={(e) =>
                e.code === "Enter" || e.code === "NumpadEnter"
                  ? handleSubmit(e)
                  : ""
              }
            >
              <h5>Enter the partner details</h5>
              <hr />

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <label>
                    First Name<span className="text-danger">*</span>
                  </label>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{errors.firstName}</span>
                </Col>
                <Col span={8}>
                  <label>
                    Last Name<span className="text-danger">*</span>
                  </label>
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{errors.lastName}</span>
                </Col>
                <Col span={8}>
                  <label>
                    Email<span className="text-danger">*</span>
                  </label>
                  <Input
                    name="email"
                    placeholder="Email"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{errors.email}</span>
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="pt-4">
                <Col span={8}>
                  <CountrySelector
                    label="Country"
                    id="country_selector"
                    required={true}
                    name="country"
                    onChange={handleCountry}
                    value={values.country || ""}
                    country_code={""}
                    Validation="true"
                    ValidationMessage={errors.country || ""}
                    style={{
                      border: "1px solid #B3D2D9",
                      width: "50%",
                      height: 30,
                    }}
                    disabled={false}
                  />
                </Col>
                <Col span={8}>
                  <PhoneInput
                    defaultValue={values.phone || ""}
                    country={values.country_code || ""}
                    disableDropdown={true}
                    countryCodeEditable={false}
                    value={values.phoneNumber || ""}
                    inputStyle={{ width: "100%", height: "40px" }}
                    enableSearch={true}
                    label="Phone Number"
                    placeholder="Phone"
                    name="phoneNumber"
                    onChange={handlePhone}
                    className="mew-text-input mew-left-icon"
                    required={true}
                    Validation="true"
                    ValidationMessage={errors.phone || ""}
                  />
                </Col>
                <Col span={8}>
                  <TimezoneSelector
                    name="timezone"
                    label="Timezone"
                    placeholder="Select Timezone"
                    required={true}
                    Validation="true"
                    ValidationMessage={errors.timezone || ""}
                    options={timezones.map((tz) => ({ value: tz, label: tz }))}
                    className="mew-select"
                    dropdownMatchSelectWidth={true}
                    placement="bottomLeft"
                    value={values.timezone || ""}
                    onChange={handleTimezoneChange}
                  />
                </Col>
              </Row>

              <Row gutter={[16, 16]} className="pt-4">
                <Col span={8}>
                  <label>
                    Company<span className="text-danger">*</span>
                  </label>
                  <Input
                    placeholder="Company"
                    name="company"
                    onChange={handleChange}
                  />
                  <span className="text-danger">{errors.company_name}</span>
                </Col>
                <Col span={8}>
                  <label>Email Verified</label>
                  <br></br>
                  <Checkbox checked={values.emailVerified} disabled />
                </Col>
              </Row>

              <div className="pt-3 text-center">
                <Space>
                  <Button
                    className="btn bg-primary-color"
                    icon={<PlusOutlined />}
                    onClick={handleSubmit}
                    loading={spinning}
                  >
                    Add
                  </Button>
                  <Button
                    className="btn bg-secondary-color"
                    onClick={() => navigate("/admin/manage-partners")}
                    icon={<CloseOutlined />}
                  >
                    Cancel
                  </Button>
                </Space>
              </div>
            </form>
          </div>
        </div>
      </Spin>
    </>
  );
};
export default AddPartner;
