export const validate = (formValues, fileDetails = "") => {
  let errors = {};
  const regexName = /^[^-\s][a-zA-Z_\s-]+$/;
  const regexDescription = /^[ a-zA-Z0-9-'-_.]+$/;
  if (!formValues.name) {
    errors.name = "Marketplace cannot be blank!";
  } else if (!regexName.test(formValues.name)) {
    errors.name = "Marketplace Name is invalid!";
  }
  if (
    formValues.description !== "" &&
    !regexDescription.test(formValues.description)
  ) {
    errors.description =
      "Description must contain only alpha-numeric characters!";
  }
  /* Validating file input */
  if (fileDetails !== "") {
    let fileSize = fileDetails.size;
    let fileType = fileDetails.type;
    let allowedImageTypes = ["image/png", "image/jpg", "image/jpeg"];

    if (fileSize > 1000000) {
      errors.logo = "Logo file must be smaller than 1 MB";
    }
    if (allowedImageTypes.indexOf(fileType) == -1) {
      errors.logo = "Please upload file having extensions: .jpeg/.jpg/.png";
    }
  }
  return errors;
};
