import React from "react";
import { useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import img from "./../assets/icons/index";
function MainMenu(props) {
  const { auth } = useSelector((state) => state);
  let user = auth.userInfo ? auth.userInfo : null;
  const navigate = useNavigate();
  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "Home") {
      navigate("/dashboard");
    }
    if (name == "PartnerHome") {
      navigate("/partner/dashboard");
    }
    if (name == "ManagePartner") {
      navigate("/admin/manage-partners");
    }
    if (name == "ManageUser") {
      navigate("/partner/manage-user");
    }
    if (name == "OrderDashboard") {
      navigate("/partner/manage-orders");
    }
    if (name == "ManagePlans") {
      navigate("/admin/subscriptions/manage-subscription");
    }
    if (name == "SubscriptionOffers") {
      navigate("/admin/offer/subscription-offer");
    }
    if (name == "PlanFeatures") {
      navigate("/admin/plan-features");
    }
    if (name == "ManageMktplaces") {
      navigate("/admin/manage-marketplaces");
    }
    if (name == "PaymentDashboard") {
      navigate("/admin/payment-dashboard");
    }
    if (name == "ManageUsers") {
      navigate("/admin/manage-users");
    }
    if (name == "PartnerSubscriptions") {
      navigate("/admin/manage-partner-subscription");
    }
    if (name == "PlanName") {
      navigate("/admin/manage-plan-name");
    }
    if (name == "PlanDuration") {
      navigate("/admin/manage-plan-duration");
    }
    if (name == "ProductDashboard") {
      navigate("/admin/product-dashboard");
    }
    if (name == "MyProducts") {
      navigate("/partner/products");
    }
    if (name == "ProductCatalog") {
      navigate("/partner/product-catalog");
    }
    if (name == "managecategories") {
      navigate("/admin/category/category-dashboard");
    }
    if (name == "managespec") {
      navigate("/admin/specifications/specifications-dashboard");
    } 
    if (name == "managemarketplacecategory") {
      navigate("/admin/marketplace-category/marketplace-category-dashboard");
    }
  };
  if (user !== null) {
    if (user.role == "Super-Admin") {
      //admin menu
      return (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a
              className="nav-link"
              href=""
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              title="Home"
              name="Home"
              onClick={onClick}
            >
              Home
            </a>
          </li>
          <li className="nav-item dropdown menu">
            <a
              className="nav-link"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Products{" "}
              <span>
                <img
                  src={img.downarrowIcon}
                  alt=""
                  className="dark drop-icon"
                />{" "}
                <img
                  src={img.downArrowLight}
                  alt=""
                  className="light drop-icon"
                />
              </span>
            </a>
            <div
              className="ml-3 ml-md-0 dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                href=""
                name="ProductDashboard"
                title="Product Dashboard"
                onClick={onClick}
              >
                Product Dashboard
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                href=""
                name="managespec"
                title="Manage Specifications"
                onClick={onClick}
              >
                Manage Specifications
              </a>
            </div>
          </li>
          <li className="nav-item dropdown menu">
            <a
              className="nav-link"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Category{" "}
              <span>
                <img
                  src={img.downarrowIcon}
                  alt=""
                  className="dark drop-icon"
                />{" "}
                <img
                  src={img.downArrowLight}
                  alt=""
                  className="light drop-icon"
                />
              </span>
            </a>
            <div
              className="ml-3 ml-md-0 dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                href=""
                name="managecategories"
                title="Manage E-Comm Wizard Category"
                onClick={onClick}
              >
                E-Comm Wizard Category
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                href=""
                name="managemarketplacecategory"
                title="Manage Marketplace Category"
                onClick={onClick}
              >
                Marketplace Category
              </a>
            </div>
          </li>
          <li className="nav-item dropdown menu">
            <a
              className="nav-link"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Partners{" "}
              <span>
                <img
                  src={img.downarrowIcon}
                  alt=""
                  className="dark drop-icon"
                />{" "}
                <img
                  src={img.downArrowLight}
                  alt=""
                  className="light drop-icon"
                />
              </span>
            </a>
            <div
              className="ml-3 ml-md-0 dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="ManagePartner"
                onClick={onClick}
                href=""
                title="Manage Partner"
              >
                Manage Partner
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="PartnerSubscriptions"
                onClick={onClick}
                href=""
                title="Partner Subscriptions"
              >
                Partner Subscriptions
              </a>
            </div>
          </li>

          <li className="nav-item dropdown menu">
            <a
              className="nav-link"
              href=""
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Subscriptions{" "}
              <span>
                <img
                  src={img.downarrowIcon}
                  alt=""
                  className="dark drop-icon"
                />{" "}
                <img
                  src={img.downArrowLight}
                  alt=""
                  className="light drop-icon"
                />
              </span>
            </a>
            <div
              className="ml-3 ml-md-0 dropdown-menu"
              aria-labelledby="navbarDropdown"
            >
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="ManagePlans"
                onClick={onClick}
                href=""
                title="Manage Subscriptions"
              >
                Manage Subscriptions
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="SubscriptionOffers"
                onClick={onClick}
                href=""
                title="Subscription Offers"
              >
                Subscription Offers
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="PlanFeatures"
                onClick={onClick}
                href=""
                title="Subscription Features"
              >
                Subscription Features
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="PlanName"
                onClick={onClick}
                href=""
                title="Subscription Name"
              >
                Subscription Name
              </a>
              <a
                className="dropdown-item"
                data-toggle="collapse"
                data-target=".navbar-collapse.show"
                name="PlanDuration"
                onClick={onClick}
                href=""
                title="Subscription Duration"
              >
                Subscription Duration
              </a>
            </div>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href=""
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              name="ManageMktplaces"
              onClick={onClick}
            >
              Manage Marketplaces
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href=""
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              name="PaymentDashboard"
              onClick={onClick}
            >
              Payment Dashboard
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link"
              href=""
              data-toggle="collapse"
              data-target=".navbar-collapse.show"
              name="ManageUsers"
              onClick={onClick}
            >
              Manage Users
            </a>
          </li>
        </ul>
      );
    }
  } else {
    return <div></div>;
  }
}

export default MainMenu;
