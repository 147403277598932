
import logo from "../assets/images/ecom-brand-logo.png";
const { Outlet } = require("react-router-dom");
function UserRegisterLayout(props) {
  return (
    <div>
      {/*--- Header ---*/}
      <header className="header-auth w-auto">
        <img src={logo} alt="logo" />
      </header>
      {/*--- End header ---*/
      /*--- Page Content ---*/}
      <div className="container user-register">
        <div className="row">
          <div className="col-12 col-lg-12 pb-sm-1">
            <div className="login-container h-100">
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      {/*--- End Page Content ---*/}
    </div>
  );
}

export default UserRegisterLayout;
