import { Form, Select, Spin } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";

function CategoryMapForm({
  pageDetails,
  setPageDetails,
  mappedCategories,
  setMappedCategories,
}) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [leafCategoryList, setCategoryList] = useState([]);
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const OPTIONS = [];
  const { Option } = Select;
  useEffect(() => {
    async function listChildCategories() {
      let url = `category?leaf_node=true`;
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      })
        .then((response) => {
          if (response.success == true && response.status == 200) {
            const options = [];
            Object.values(response.data).map((type) => {
              options.push({
                label: type.id,
                value: type.name,
              });
            });
            setCategoryList(options);
            setLoading(true);
          }
        })
        .catch((error) => {});
    }
    listChildCategories();

    setPageDetails({
      pagename: "specification-map",
    });
    form.setFieldsValue({
      spec_cat: mappedCategories,
    });
  }, []);
  const onSelectChange = useCallback((value) => {
    setMappedCategories(value);
  });
  let leafCategoryControl;
  if (loading) {
    Object.values(leafCategoryList).map((data) => {
      OPTIONS.push(<Option key={data.label}>{data.value}</Option>);
    });
    leafCategoryControl = (
      <Select
        style={{ width: "100%" }}
        name="spec_cat"
        placeholder="Please select the curresponding E-Comm Wizard category"
        onChange={onSelectChange}
      >
        {OPTIONS}
      </Select>
    );
  } else {
    leafCategoryControl = <Spin size="large" />;
  }

  return (
    <div style={{ minHeight: 250 }}>
      <Form
        {...layout}
        form={form}
        layout="horizontal"
        initialValues={{
          size: "small",
        }}
        style={{ paddingTop: 50 }}
        id="SaveSpecMap"
      >
        <Form.Item
          label="Speification Name"
          name="spec_cat"
          wrapperCol={{
            offset: 2,
            span: 8,
          }}
          rules={[
            {
              required: true,
              message: "Specification must be mapped with atleast one category",
            },
          ]}
        >
          {leafCategoryControl}
        </Form.Item>
      </Form>
    </div>
  );
}

export default CategoryMapForm;
