import { Chart } from "./Chart";

export const ColumnChart = (props) => {
  const dataSource = {
    chart: {
      caption: "Countries With Most Oil Reserves [2017-18]",
      subcaption: "In MMbbl = One Million barrels",
      xaxisname: "Country",
      yaxisname: "Reserves (MMbbl)",
      numbersuffix: "K",
      theme: "fusion",
    },
    data: [
      {
        label: "Venezuela",
        value: "290",
      },
      {
        label: "Saudi",
        value: "260",
      },
      {
        label: "Canada",
        value: "180",
      },
      {
        label: "Iran",
        value: "140",
      },
      {
        label: "Russia",
        value: "115",
      },
      {
        label: "UAE",
        value: "100",
      },
      {
        label: "US",
        value: "30",
      },
      {
        label: "China",
        value: "30",
      },
    ],
  };
  return <Chart dataSource={dataSource} {...props} />;
};

export const GroupedColumnChart = (props) => {
  return <Chart dataSource={props.dataSource} {...props} />;
};

export const PieChart = (props) => {
  return <Chart dataSource={props.dataSource} {...props} />;
};

export const DonutChart = (props) => {
  return <Chart dataSource={props.dataSource} {...props} />;
};
