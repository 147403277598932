import {
  Select,
  Form,
  Button,
  InputNumber,
  Input,
  Descriptions,
  Avatar,
  List,
  Divider,
  Comment,
} from "antd";
import { useRef, useState } from "react";
import {
  UserOutlined,
  SaveOutlined,
  CommentOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import moment from "moment";
const BusinessVerificationForm = ({
  data,
  values,
  setValues,
  questions,
  setQuestions,
  handleSubmit,
}) => {
  const { TextArea } = Input;
  const [formLayout, setFormLayout] = useState("vertical");
  const [reqErrors, setReqErros] = useState({});
  const [form] = Form.useForm();
  const formRef = useRef();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const options = [];
  for (let i = 0; i < 100000; i++) {
    const value = `${i.toString(36)}${i}`;
    options.push({
      label: value,
      value,
      disabled: i === 10,
    });
  }
  const comments = [];

  data.all_comments.map((item) => {
    const newValues = {}; // Create a new object for each iteration

    // newValues["title"] = item.user_data.email;
    newValues["user"] = item.user_data.email;
    newValues["content"] = item.comments;
    newValues["date"] = moment(item.created_at).format("MMM DD, YYYY h:mm A");
    comments.push(newValues);
  });

  const handleInputChange = (value, item) => {
    const newValues = {
      ...questions,
      [item]: { ["qn_id"]: item, ["value"]: value },
    };
    setQuestions(newValues);
  };
  const handleChange = (value, item) => {
    const newValues = {
      ...questions,
      [item]: { ["qn_id"]: item, ["value"]: value.toString() },
    };
    setQuestions(newValues);
  };
  const handleStatusChange = (value) => {
    const newValues = { ...values, ["status"]: value };
    setValues(newValues);
  };
  const handleComment = (event) => {
    const newValues = { ...values, ["comment"]: event.target.value };
    setValues(newValues);
  };

  const validateRequirements = () => {
    let submit = true;
    const allQuestionsAnswers = data.all_questions_answers;
    let result = {};
    allQuestionsAnswers.forEach((questionAns) => {
      const qn_id = questionAns.question_id.toString();
      if (values.status == "Business Verified") {
        if (qn_id in questions) {
          const value = questions[qn_id].value;
          const type = questionAns.type;
          if (
            type != "multiselect" &&
            (value <= 0 || Number.isInteger(value) == false)
          ) {
            result[qn_id] = `${questionAns.question} is invalid!`;
            submit = false;
          } else if (value == "") {
            result[qn_id] = `${questionAns.question} cannot be blank!`;
            submit = false;
          }
        } else {
          result[qn_id] = `${questionAns.question} cannot be blank!`;
          submit = false;
        }
      }
    });
    setReqErros(result);
    if (submit) {
      handleSubmit();
    }
  };

  return (
    <>
      <div className="card border-info p-2 mt-5" style={{ margin: "0.5%" }}>
        <div className="row">
          <div className="col-12">
            <div className=" row card-listing">
              <Descriptions
                title="Partner Details"
                className="text-left "
                style={{ padding: "1%" }}
                labelStyle={{ fontWeight: "bold" }}
              >
                <Descriptions.Item label="Name">
                  {data.first_name} {data.last_name}
                </Descriptions.Item>
                <Descriptions.Item label="Phone">
                  {data.phone}
                </Descriptions.Item>
                <Descriptions.Item label="Email">
                  {data.email}
                </Descriptions.Item>
                <Descriptions.Item label="Company">
                  {data.company_name}
                </Descriptions.Item>
                <Descriptions.Item label="Registered On">
                  {moment(data.created_at).format("DD-MMMM-YYYY hh:mm A")}
                </Descriptions.Item>
                <Descriptions.Item label="Address">
                  {data.address || data.address1 || data.city || data.zipcode
                    ? `${data.address || ""} ${data.address1 || ""} ${
                        data.city || ""
                      } ${data.zipcode || ""}`
                    : `N/A (Country: ${data.country})`}
                </Descriptions.Item>
              </Descriptions>
            </div>
          </div>
        </div>
      </div>
      <Form
        {...formItemLayout}
        layout={formLayout}
        id="SaveSubscriptions"
        ref={formRef}
        form={form}
        className="pt-2"
        // onFinish={handleSubmit}
        onFinish={validateRequirements}
        onFinishFailed={validateRequirements}
      >
        {data ? (
          <div className="container">
            <div className="card">
              <div className="container">
                <div className="row align-items-center">
                  <div className="bg-secondary-color container pt-3">
                    <h5>Partner Requirements</h5>
                  </div>
                  <div className="p-3">
                    <div className="row offer-detail">
                      {data.all_questions_answers.map((item, index) =>
                        item.type === "number" ? (
                          <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
                            <label
                              className="text-left mt-2 h1"
                              style={{ marginTop: "1%" }}
                            >
                              {item.question}
                            </label>
                            <InputNumber
                              className="pl-0 mt-1"
                              style={{ width: "75%" }}
                              name={item.question_id}
                              placeholder={item.question}
                              id={item.question_id}
                              size="medium"
                              defaultValue={item.answer ? item.answer : ""}
                              maxLength={4}
                              onChange={(value) =>
                                handleInputChange(value, item.question_id)
                              }
                            />
                            <span className="text-danger">
                              {reqErrors[index + 1]}
                            </span>
                          </div>
                        ) : (
                          <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
                            <label
                              className="text-left mt-2 h1"
                              style={{ marginTop: "1%" }}
                            >
                              {item.question}
                            </label>
                            <Select
                              mode="multiple"
                              defaultValue={item.answer ? item.answer : []}
                              className="pl-0 mt-1"
                              style={{
                                width: "75%",
                              }}
                              placeholder={`Select ${item.question}`}
                              onChange={(value) =>
                                handleChange(value, item.question_id)
                              }
                              options={
                                item.question == "Categories"
                                  ? data.all_categories
                                  : data.all_marketplaces
                              }
                            />
                            <span className="text-danger">
                              {reqErrors[index + 1]}
                            </span>
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="container">
                <div className="row align-items-center">
                  <div className="bg-secondary-color container pt-3">
                    <h5>Marketing Team Comments</h5>
                  </div>
                  <div className="container">
                    {comments.length > 0 ? (
                      <div className="card m-2 mt-3 p-1">
                        <fieldset>
                          <legend className="p-2 text-bold text-dark">Previous Comments</legend>
                          {comments.map((comment, index) => (
                            <>
                              <Comment
                                key={index}
                                author={
                                  <span className="h6 text-dark">
                                    {comment.user}
                                  </span>
                                }
                                avatar={
                                  <Avatar
                                    icon={
                                      <UserOutlined
                                        style={{ color: "#0d4835" }}
                                      />
                                    }
                                  />
                                }
                                content={
                                  <>
                                    <div>
                                      <CommentOutlined className="pr-1 mt-2" />{" "}
                                      {comment.content}
                                      <br />
                                      <CalendarOutlined className="pr-2 mt-2" />
                                      {comment.date}
                                    </div>
                                  </>
                                }
                              />
                            </>
                          ))}
                        </fieldset>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <Divider type="dashed" />

              <div className="container mt-3">
                <Form.Item
                  label="Status"
                  labelAlign="left"
                  name="status"
                  rules={[
                    {
                      required: true,
                      message: "Status cannot be blank!",
                    },
                  ]}
                >
                  <Select
                    placeholder={`Select Status`}
                    style={{
                      width: "75%",
                    }}
                    onChange={handleStatusChange}
                    options={[
                      {
                        value: "Business Verified",
                        label: "Verified",
                      },
                      {
                        value: "Hold",
                        label: "Hold",
                      },
                      {
                        value: "Closed",
                        label: "Closed",
                      },
                    ]}
                  />
                </Form.Item>
                <Form.Item
                  labelAlign="left"
                  label="Your Comment"
                  name="comment"
                  rules={[
                    {
                      required: true,
                      message: "Please enter your comment!",
                    },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Input.TextArea
                    rows={4}
                    onChange={handleComment}
                    placeholder="Enter your comment here"
                  />
                </Form.Item>
              </div>
            </div>

            <div className="text-center pt-4">
              <Button
                type="primary"
                htmlType="submit"
                icon={<SaveOutlined />}
                style={{ borderRadius: "15px" }}
                className="text-bold"
              >
                Save Requirement
              </Button>
            </div>
          </div>
        ) : (
          ""
        )}
      </Form>
    </>
  );
};
export default BusinessVerificationForm;
