import { useState, useEffect, useRef } from "react";
import axios from "axios";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Descriptions,
  Card,
  Space,
  Breadcrumb,
  message,
  Modal,
  Popconfirm,
  Table,
  Text,
  Typography
} from "antd";
import {
  DeleteFilled
} from "@ant-design/icons";
import { useNavigate, useLocation } from "react-router-dom";
import { ApiEndPoint } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { setUserDetails } from "../../../../features/userSlice";
import { Notification } from "../../../common/Notification";
import { useSelector } from "react-redux";
import Moment from "react-moment";

function SubscriptionOfferForm() {
  const formRef = useRef();
  const [form] = Form.useForm();
  const [offerData, setOfferData] = useState([]);
  const [subscriptions, setSubscriptions] = useState({});
  const [subscriptionDays, setSubscriptionDays] = useState({});
  const [subscriptionDuraions, setSubscriptionDuraions] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [existingRecords, setExistingRecords] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const { Text } = Typography;
  const [previewData, setPreviewData] = useState([
    {
      subscription_id: "",
      validity_in_month: "",
      offer_type_id: "",
      offer_amount: "",
      cap_limit: ""
    },
  ]);
  const [formValues, setFormVal] = useState([
    {
      subscription_id: "",
      validity_in_month: "",
      offer_type_id: "",
      offer_amount: "",
      cap_limit: "",
      offerAmountText: "Amount",
      capAmountDisabled: false
    },
  ]);
  const [serverErrors, setServerErrors] = useState([]);

  const previewHeaders = [
    {
      title: 'Subscription',
      dataIndex: 'subscription_id',
      key: 'subscription_id',
    },
    {
      title: 'Subscription-Amount',
      dataIndex: 'subscription_amount',
      align: "right",
      key: 'subscription_id',
    },
    {
      title: 'Offer-Type',
      dataIndex: 'offer_type_id',
      key: 'offer_type_id',
    },
    {
      title: 'Offer-Amount',
      dataIndex: 'offer_amount',
      align: "right",
      key: 'offer_amount',
    },
    {
      title: 'CAP-Limit',
      dataIndex: 'cap_limit',
      align: "right",
      key: 'cap_limit',
    },
    {
      title: 'Duration',
      dataIndex: 'validity_in_month',
      key: 'validity_in_month',
    },
  ];  

  const handleAddRow = () => {
    setFormVal([
      ...formValues,
      {
        subscription_id: "",
        validity_in_month: "",
        offer_type_id: "",
        offer_amount: "",
        cap_limit: "",
        offerAmountText: "Amount",
        capAmountDisabled: false
      },
    ]);
  };

  const deleteInputRow = (rowIndex) => {
    const newForm = [...formValues];
    newForm.splice(rowIndex, 1);
    setFormVal(newForm);
  };

  const handleCancel = () => {
    navigate("/admin/offer/subscription-offer");
  }

  const deleteMappedSubscription = (subscription_id) => {
    let url = "delete-offer-subscription";
    url = apiUrl + url;
    response = axios(url, {
      method: "DELETE",
      data: {
        subscription_id: subscription_id,
        offer_id: location.state.offer_id
      },
      headers: headers
    }).then((response) => {
      if (response.success === true && response.status === 200){
        message.success("Subscription successfully removed from the offer");
        setTimeout(() => {          
          window.location.reload();
        }, 2000);        
      }
      else if (response.status === 422){
        let validationErrors = response.data;
        for (var key in validationErrors){
          if (validationErrors.hasOwnProperty(key)){
            message.error(validationErrors[key]);
          }
        }
      }
      else{
        message.error("Oops..!! Somwthing went wrong!");
      }
    })
  }

  const handlePreview = () => {
    const valid = formValidation(formValues);
    if (valid) {
      setShowModal(true);
    }else{
      message.info("Please enter subscription details!");
      message.destroy();
    }
  }

  const closeModal = () => {
    setShowModal(false);
  }

  const handleChange = ( event, index) => {
    let newData = [...formValues];
    let previewInfo = [...previewData];
    /* Setting offer amount text */    
    if (event.name == 'offer_type_id'){
      if (event.value == 1){
        newData[index]['offerAmountText'] = 'Offer Amount';
        newData[index]['capAmountDisabled'] = true;
        newData[index]['cap_limit'] = "0";
        previewInfo[index]['cap_limit'] = "0";
      }else{
        newData[index]['offerAmountText'] = 'Percentage Amount';
        newData[index]['capAmountDisabled'] = false;
      }
    }    
    newData[index][event.name] = event.value;
    if (previewInfo[index]){
      previewInfo[index]['key'] = index;
      previewInfo[index][event.name] = event.key ? event.children : event.value;
    } else{
      previewInfo[index] = {
        ['key'] : index,
        [event.name] : event.key ? event.children : event.value
      }
    }
    setFormVal(newData);
    setPreviewData(previewInfo);
  };

  const formValidation = (formValues) => {
    const data = [...formValues];
    const amount = /^[0-9.]+$/;
    let valid = true;
    for (let key = 0; key < data.length; key++) {
      data[key].subscription_id_error = '';
      data[key].validity_in_month_error = '';
      data[key].offer_type_id_error = '';
      data[key].offer_amount_error = '';
      data[key].cap_limit_error  = '';
      if (data[key].subscription_id == "") {
        data[key].subscription_id_error = "Subscription cannot be blank";
        valid = false;
      }
      if (data[key].validity_in_month == "") {
        data[key].validity_in_month_error = "Duration cannot be blank";
        valid = false;
      }
      if (data[key].offer_type_id == "") {
        data[key].offer_type_id_error = "Offer Type cannot be blank";
        valid = false;
      }
      if (data[key].offer_amount == "") {
        data[key].offer_amount_error = "Amount cannot be blank";
        valid = false;
      }
      else if (!amount.test(data[key].offer_amount) || data[key].offer_amount <= 0){
        data[key].offer_amount_error = "Please enter valid Amount";
        valid = false;
      }      
      if ( (data[key].offer_type_id == 2 || data[key].offer_type_id == '') && data[key].cap_limit == "") {        
        data[key].cap_limit_error = "CAP Limit cannot be blank";
        valid = false;
      }
      else if (data[key].offer_type_id == 2) {
        if (!amount.test(data[key].cap_limit) || data[key].cap_limit <= 0) {
          data[key].cap_limit_error = "Please enter valid CAP Limit";
          valid = false;
        }
        if (parseFloat(data[key].offer_amount) == parseFloat(data[key].cap_limit)){
          data[key].cap_limit_error = "Offer Amount and CAP Limit cannot be the same!";
          valid = false;
        }
        if (parseFloat(data[key].cap_limit) > parseFloat(data[key].offer_amount)){
          data[key].cap_limit_error = "CAP Limit cannot be greater than the Offer Amount!";
          valid = false;
        }
      }
    }
    setFormVal(data);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const valid = formValidation(formValues);
    if (valid) {
      mapOffer(formValues);
    }
  };

  /* Filter keys that are required for the API call */
  function filterFormValues(formValues){
    const keysToKeep = ['subscription_id','offer_type_id','validity_in_month','cap_limit','offer_amount'];
    const output = list => list.map(ob => Object.fromEntries(
      keysToKeep.map(key => [key, ob[key]])
    ));
    return output(formValues);
  }

  function mapOffer(formValues){
    let offerSubscriptionObj = filterFormValues(formValues);
    let offerSubscriptionMapRequest = {
      offers: {
        offer_id: location.state.offer_id,
        subscriptions: offerSubscriptionObj
      }
    }
    let url = "map-subscription-offer";
    url = apiUrl + url;
    response = axios(url, {
      method: "POST",
      data: offerSubscriptionMapRequest,
      headers: headers
    }).then((response) => {
      if (response.success === true && response.status === 200){
        setServerErrors([]);
        message.success(response.message);
        message.destroy();
        setTimeout(() => {
          navigate("/admin/offer/subscription-offer");
        }, 2000);
      }
      else if (response.status === 422){
        let validationErrors = response.data;
        let errorMsgs = [];
        for (var key in validationErrors) {
          if (validationErrors.hasOwnProperty(key)) {
            var msg = validationErrors[key][0];
            errorMsgs.push(msg);
          }
        }
        Modal.warning({
          title: "Please fix the following errors",
          content: errorMsgs.join(", ")
        });
      }
      else{
        setServerErrors([]);
        message.error("Oops!! Something went wrong");
        message.destroy();
      }
    })
  }

  let apiUrl = ApiEndPoint();
  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    Authorization: `Bearer ${userToken}`,
  };

  useEffect(() => {
    setLoading(true);
    let response = "";
    /* Checking Authorization */
    function checkAuthorization() {
      let url = "get-user";
      url = apiUrl + url;
      response = axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      })
        .then((response) => {
          if (response.success == true && response.status == 200) {
            localStorage.setItem("Access-Token", response.data.token);
            store.dispatch(
              setUserDetails({
                name: response.data.name,
                token: response.data.token,
                role: response.data.roles,
              })
            );
            let offer_id = location.state.offer_id;
            getOfferDetails(offer_id);
            getSubscriptionNames();
            getSubscriptionDays();
            getSubscriptionDuration();
          } else if (response.status == 401) {
            Notification({
              type: "error",
              message: "Sorry , Your session has been expired!",
            });
            navigate("/");
          }
        })
        .catch((error) => {
          navigate("/PageNotFound");
        });
    }
    checkAuthorization();
  }, []);

  async function getOfferDetails(offer_id) {
    let url = "offer/" + offer_id;
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setOfferData(response.data);
        /* Disable Edit for existing subscription offers */
        let exData = response.data.subscriptions;
        setExistingRecords(exData.length > 0 ? exData : []);    
      }
    });
  }

  async function getSubscriptionDuration() {
    let url = "offer-type";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubscriptionDuraions(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  async function getSubscriptionDays() {
    let url = "subscription-days";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubscriptionDays(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  async function getSubscriptionNames() {
    let url = "subscription-plan";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSubscriptions(response.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }

  return (
    <>
      <div>
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-sm-12 col-md-12 p-0">
              <h5 className="ml-2 pt-3 text-primary-color">
                View Offer - {offerData.description}
              </h5>
            </div>
            <div className="col-12 col-md-7 col-xl-4">
              <Breadcrumb className="text-primary-color">
                <Breadcrumb.Item>
                  <a href="/dashboard">Home</a>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                  <a href="subscription-offer" >Subscription Offers </a>
                </Breadcrumb.Item>
                <Breadcrumb.Item className="active-link">
                  View Offer
                </Breadcrumb.Item>
              </Breadcrumb>
            </div>
          </div>
        </div>

        <Form
          layout="horizontal"
          id="SaveOffers"
          ref={formRef}
          form={form}
        >
          <div className="row">
            <div className="col-12 mt-4">
              <div className="card offer-section ">
                <Card
                  type="inner"
                  title="Offer Details"
                  className="text-bold preview-content"
                  headStyle={{ backgroundColor: "#0D4835", color: "white" }}
                >
                  <Descriptions title={offerData.description}>
                    <Descriptions.Item label="Offer Starts On">
                      <Moment format="MMMM Do YYYY HH:mm:ss">
                        {offerData.start_date}
                      </Moment>
                    </Descriptions.Item>
                    <Descriptions.Item label="Offer Ends On">
                      <Moment format="MMMM Do YYYY HH:mm:ss">
                        {offerData.end_date}
                      </Moment>
                    </Descriptions.Item>
                    <Descriptions.Item label="Offer Type">
                      {offerData.is_subscription_offer === 1
                        ? "Subscription Based"
                        : "Initial Amount Based"}
                    </Descriptions.Item>
                  </Descriptions>
                </Card>
              
              {                 
                existingRecords.length > 0 ? (         
                <div className=" mt-4">
                  <div className="container">
                    <div className="row offer-detail card flex-row preview-content">
                      <div className="col-12 bg-primary-color offer-detail--header">
                        <p className="m-0 pl-3">Mapped Subscription(s)</p>
                      </div>

                    <div className="row container m-2">

                    {existingRecords.map((data, rowIndex) => {
                      return (
                      <Card
                      key={rowIndex}
                      headStyle={{ fontWeight: "bold" }}
                      bodyStyle={{ backgroundColor: "aliceblue", color:"black" }}
                      extra={
                        <Popconfirm
                          title={"Remove "+data.subscription_plan+" from this Offer?"}
                          okText="Yes"
                          cancelText="No"
                          onConfirm={() => deleteMappedSubscription(data.subscription_id)}
                        >
                          <DeleteFilled className="text-danger lead" title="Remove Subscription"/>
                        </Popconfirm>
                      }
                      size="small"
                      title={data.subscription_plan}
                      className="mr-3 card bg-secondary-color"
                      style={{
                        width: 150,
                      }}
                      >
                         <p>{data.offer_type}</p>
                         <p>{data.offer_amount}</p>
                      </Card>
                      );
                    })}
                    </div>
                      
                    </div>
                  </div>
                </div>
                ) : 
                (
                  <div></div>
                )
              }

                <div className=" mt-4">
                  <div className="container">
                    <div className="row offer-detail card flex-row preview-content">
                      <div className="col-12 bg-primary-color offer-detail--header">
                        <p className="m-0 pl-3">Select Subscription(s)</p>
                      </div>

                      <div className="container text-right pb-2">
                        <Button
                          className="btn bg-primary-color"
                          onClick={handleAddRow}
                          title="Add Subscription"
                        >
                          + Add more
                        </Button>
                      </div>

                      {formValues.map((data, rowIndex) => {
                        return (
                          <div
                            key={rowIndex}
                            className="row flex-row container justify-content-around pb-2 mt-1"
                          >
                            <div className="col-12 col-lg-2 col-xl-2 d-flex flex-column mb-2 mt-lg-0">
                              <label>Subscription <Text type="danger">*</Text></label>
                              <Select
                                name="subscription"
                                value={data.subscription_id || null}
                                placeholder="Select Subscription"
                                onSelect={(value, event) => {
                                  handleChange(event, rowIndex);
                                }}
                              >
                                {Object.values(subscriptions).map((item) => (
                                  <Select.Option
                                    name="subscription_id"
                                    key={item.id}
                                    value={item.id}
                                  >
                                    {item.plan}
                                  </Select.Option>
                                ))}
                              </Select>
                              <span className="text-danger">
                                {data.subscription_id_error ||
                                  serverErrors.subscription_id}
                              </span>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 d-flex flex-column mt-3 mt-lg-0">
                              <label>Required Duration <Text type="danger">*</Text></label>
                              <Select
                                name="req_duraion"
                                value={data.validity_in_month || null}
                                placeholder="Select Duration"
                                onSelect={(value, event) => {
                                  handleChange(event, rowIndex);
                                }}
                              >
                                {Object.values(subscriptionDays).map((item) => (
                                  <Select.Option
                                    name="validity_in_month"
                                    key={item.id}
                                    value={item.id}
                                  >
                                    {item.description}
                                  </Select.Option>
                                ))}
                              </Select>
                              <span className="text-danger">
                                {data.validity_in_month_error ||
                                  serverErrors.validity_in_month}
                              </span>
                            </div>
                            <div className="col-6 col-lg-2 col-xl-2 d-flex flex-column mt-3 mt-lg-0">
                              <label>Offer Type <Text type="danger">*</Text></label>
                              <Select
                                name="offer_type"
                                value={data.offer_type_id || null}
                                placeholder="Select Offer Type"
                                onSelect={(value, event) => {
                                  handleChange(event, rowIndex);
                                }}
                              >
                                {Object.values(subscriptionDuraions).map(
                                  (item) => (
                                    <Select.Option
                                      name="offer_type_id"
                                      key={item.id}
                                      value={item.id}
                                    >
                                      {item.type}
                                    </Select.Option>
                                  )
                                )}
                              </Select>
                              <span className="text-danger">
                                {data.offer_type_id_error ||
                                  serverErrors.offer_type_id}
                              </span>
                            </div>
                            <div className="col-12 col-lg-3 col-xl-3 d-flex flex-column mt-3 mt-lg-0">
                              <label>{data.offerAmountText || 'Amount'} <Text type="danger">*</Text></label>
                              <Input
                                name="offer_amount"
                                placeholder={data.offerAmountText}
                                value={data.offer_amount || null}
                                maxLength="10"
                                style={{ height: "32px" }}
                                onChange={(event) => {                             
                                  handleChange(event.target, rowIndex);
                                }}
                              ></Input>
                              <span className="text-danger">
                                {data.offer_amount_error ||
                                  serverErrors.offer_amount}
                              </span>
                            </div>
                            <div className="col-12 col-lg-2 col-xl-2 d-flex flex-column mt-3 mt-lg-0">
                              <label>CAP Limit <Text type="danger">*</Text></label>
                              <Input
                                name="cap_limit"
                                value={data.cap_limit || null}
                                style={{ width: "100%", height: "32px" }}
                                placeholder="CAP amount"
                                maxLength="10"
                                disabled={data.capAmountDisabled}
                                onChange={(event) => {
                                  handleChange(event.target, rowIndex);
                                }}
                              ></Input>
                              <span className="text-danger">
                                {data.cap_limit_error || serverErrors.cap_limit}
                              </span>
                            </div>
                            {
                              //Keep one input row initially
                              rowIndex == 0 ? (
                                <label className="text-white">Remove</label>
                              ) : (
                                <div className="d-flex flex-column">
                                  <label className="text-white">Remove</label>
                                  <Button
                                    className="ant-btn-dangerous text-bold ant-btn-round"
                                    onClick={() => deleteInputRow(rowIndex)}
                                    title="Remove"
                                  >
                                    x
                                  </Button>
                                </div>
                              )
                            }
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="mt-4 text-center">
                  <Space>
                    <Button
                      className="btn bg-danger btn text-white"
                      title="Cancel"
                      onClick={handleCancel}
                    >
                      Cancel
                    </Button>
                    <Button
                      className="bg-secondary-light-color btn"
                      title="Preview"
                      onClick={handlePreview}
                    >
                      Preview
                    </Button>
                    <Modal title="Preview Offer" open={showModal} onOk={closeModal} onCancel={closeModal} destroyOnClose={true} width={1000} okText="Close Preview">
                    <Descriptions title={offerData.description}>
                      <Descriptions.Item label="Starts-On" className="text-bold">{offerData.start_date}</Descriptions.Item>
                      <Descriptions.Item label="Ends-On" className="text-bold">{offerData.end_date}</Descriptions.Item>
                      <Descriptions.Item label="Offer Type" className="text-bold">
                      {offerData.is_subscription_offer === 1
                        ? "Subscription Based"
                        : "Initial Amount Based"}
                    </Descriptions.Item>
                    </Descriptions>
                    <Table columns={previewHeaders} dataSource={previewData} pagination={false} bordered={true}/>
                  </Modal>
                    <Button
                      className="bg-primary-color btn"
                      title="Save Changes"
                      onClick={handleSubmit}
                    >
                      Submit
                    </Button>
                  </Space>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
}
export default SubscriptionOfferForm;
