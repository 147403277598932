import { useState, useEffect } from "react";
import { DataTable } from "../../common/Table";
import { useSelector } from "react-redux";
import axios from "axios";
import { Form, Button, Input, Space, Tag } from "antd";
import { SearchOutlined, ClearOutlined } from "@ant-design/icons";
import { ApiEndPoint } from "../../../utils/commonutils";
import Moment from "react-moment";
import Highlighter from "react-highlight-words";

function PaymentSummary(props) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /* Table headers for listing */
  const tableHeading = [
    {
      title: "Partner",
      dataIndex: "partner",
      sorter: (a, b) => a.partner.localeCompare(b.partner),
      ...getColumnSearchProps("partner", "Partner"),
    },
    {
      title: "Subscription",
      dataIndex: "plan",
      filters: [
        { text: "Bronze", value: "Bronze" },
        { text: "Silver", value: "Silver" },
        { text: "Gold", value: "Gold" },
        { text: "Platinum", value: "Platinum" },
      ],
      onFilter: (value, record) => {
        return record.plan === value;
      },
    },
    {
      title: "Transaction Status",
      dataIndex: "transaction_status",
      align: "center",
      render: (value, record) =>
        value === 1 ? (
          <Tag color="green" title="Success">
            Success
          </Tag>
        ) : (
          <Tag color="red" title="Failed">
            Failed
          </Tag>
        ),
      filters: [
        { text: "Success", value: 1 },
        { text: "Failed", value: 0 },
      ],
      onFilter: (value, record) => {
        return record.transaction_status === value;
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      sorter: (a, b) => a.amount - b.amount,
      align: "right",
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      sorter: (a, b) => a.payment_date.localeCompare(b.payment_date),
      render: (value, row, index) => {
        return value != null ? (
          <Moment format="MMMM Do YYYY, h:mm:ss">{value}</Moment>
        ) : (
          <p>-</p>
        );
      },
    },
  ];

  const subscriptionData = [
    {
      partner_id: "1",
      partner: "Rodriguez Group",
      plan: "Platinum",
      transaction_status: 1,
      amount: 2000,
      payment_date: "2023-01-19 07:00:15",
    },
    {
      partner_id: "2",
      partner: "Spencer-Grant",
      plan: "Gold",
      transaction_status: 1,
      amount: 1500,
      payment_date: "2023-01-18 06:08:30",
    },
    {
      partner_id: "3",
      partner: "Harvey Ltd",
      plan: "Gold",
      transaction_status: 1,
      amount: 1500,
      payment_date: "2023-01-15 12:20:15",
    },
    {
      partner_id: "4",
      partner: "Green-Herzog",
      plan: "Bronze",
      transaction_status: 0,
      amount: 500,
      payment_date: "2023-01-14 10:10:45",
    },
    {
      partner_id: "5",
      partner: "Vicoria Wills",
      plan: "Silver",
      transaction_status: 0,
      amount: 1000,
      payment_date: "2023-01-13 07:10:45",
    },
    {
      partner_id: "6",
      partner: "Fisher Group",
      plan: "Platinum",
      transaction_status: 1,
      amount: 2000,
      payment_date: "2023-01-13 05:10:45",
    },
  ];

  return (
    <div className="container">
      <div className="mt-3 table-wrapper w-100 overflow-auto mb-3">
        <Form form={form} component={false}>
          <DataTable
            columns={tableHeading}
            rowClassName="editable-row"
            data={subscriptionData}
            bordered
            loading={loading}
            pagination={ props.pagination ? {
              position: ["bottomCenter"],
              defaultPageSize: 100,
              showSizeChanger: true,
              showQuickJumper: true,
            } : false }
          />
        </Form>
      </div>
    </div>
  );
}
export default PaymentSummary;