import { Col, Divider, Transfer } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import DynamicField from "./DynamicFeild";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
function MapRules({ rulesMapped, setSubscriptionRules }) {
  //initialize variables and contants
  const [isLoading, setLoading] = useState(false);
  const [featuresList, setFeaturesList] = useState([]);
  const navigate = useNavigate();
  let apiUrl = ApiEndPoint();
  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const [selectedKeys, setSelectedKeys] = useState([]); // -- to store selected keys
  //initialization section ended
  //Triggers when the page is loaded.
  useEffect(() => {
     //call to get available features
     getFeatureDetails();
  }, []);
  // Service call to get  available  features addeed.
  async function getFeatureDetails() {
    let url = "feature";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        const options = [];
        Object.values(response.data).map((type) => {
          options.push({
            key: type.id,
            title: type.name,
            description: type.name,
          });
        });
        setFeaturesList(options);
        setLoading(true);
      }
    });
  }
  //Codes to handle Transfer controll
  //this sets the target group and filteres already choosen features
  // const oriTargetKeys = featuresList
  //   .filter((item) => Number(item.key) % 3 > 1)
  //   .map((item) => item.key);
  //handles change in transfer controll
  const handleChange = (newTargetKeys, direction, moveKeys) => {
    setSubscriptionRules(newTargetKeys);
  };
  const handleSelectChange = (sourceSelectedKeys, targetSelectedKeys) => {
    setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
  };
  const handleScroll = (direction, e) => {
    //   console.log('direction:', direction);
    //   console.log('target:', e.target);
  };
  //Codes to handle Transfer controll ended
  return (
    <Transfer
      dataSource={featuresList}
      titles={["General Rules", "Rules Assigned For Subscription"]}
      targetKeys={rulesMapped}
      selectedKeys={selectedKeys}
      onChange={handleChange}
      onSelectChange={handleSelectChange}
      onScroll={handleScroll}
      render={(item) => item.title}
      oneWay
      style={{
        marginBottom: 5,
        paddingLeft: 250,
        paddingRight: 150,
      }}
      listStyle={{
        width: 500,
        height: 450,
      }}
    />
  );
}

export default MapRules;
