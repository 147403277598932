import { useState, useEffect, useRef } from "react";
import {
  Modal,
  Form,
  Image,
  Popconfirm,
  Space,
  Button,
  Upload,
  message,
  Spin,
  Select,
} from "antd";
import UseDurationForm from "./validate-duration/DurationForm";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEndPoint,getApiKey } from "../../../../utils/commonutils";
import { useSelector } from "react-redux";
import { DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import { flushSync } from "react-dom";

function SubscriptionDurationModal({
  open,
  setOpen,
  mode,
  defaultValues,
  loading,
  setLoading,
}) {
  const [form] = Form.useForm();
  const { values, errors, handleChange, handleSubmit } =
    UseDurationForm(saveDuration);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.user);
  const [logoVisible, setLogoVisible] = useState("block");
  const [serverErrors, setErrors] = useState([]);
  const [fileError, setFileError] = useState(0);
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const formRef = useRef();
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  const clearInputAndErros = () => {
    //clear input values
    values.days = "";
    //clear input erros
    errors.days = "";
    serverErrors.days = "";
  };
  const handleCancel = (e) => {
    clearInputAndErros();
    setOpen(false); //Close modal
  };
  function saveDuration() {
    setConfirmLoading(true);
    //API Call to add duration
    let response = "";
    let url =
      mode == "Edit"
        ? `subscription-days/${defaultValues.id}`
        : "subscription-days";
    let method = mode == "Edit" ? "PUT" : "POST";
    url = apiUrl + url;
    response = axios(url, {
      method: method,
      data: values,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setErrors([]);
        clearInputAndErros();
        message.success(response.message);
        message.destroy();
        setOpen(false); //Close modal
      } else if (response.status === 422) {
        /* Show server side validation errors */
        setErrors("");
        let validationErrors = response.data;
        var errorMsg = "Please fix the error(s)";
        /* Iterate errors and append to the error message */
        Object.keys(validationErrors).map((key, value) => {
          validationErrors[key].map((value, ind) =>
            setErrors((data) => ({
              ...data,
              [key]: value,
            }))
          );
        });
        setErrors(validationErrors);
        message.error(errorMsg);
        message.destroy();
      } else {
        setErrors([]);
        message.error("Oops!! Something went wrong");
        message.destroy();
      }
    });
    setConfirmLoading(false);
  }
  useEffect(() => {
    if (open) {
      if (mode == "Edit") {
        values.days = defaultValues.days;
        form.setFieldsValue(values);
        setLoading(false);
      }
    } else {
      values.days = "";
      form.setFieldsValue(values);
    }
  }, [defaultValues]);
  return (
    <Modal
      forceRender={true}
      title={mode + ` Duration Days`}
      open={open}
      okText={mode == "Add" ? "Save" : "Edit"}
      onOk={handleSubmit}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      closable={false}
      destroyOnClose={true}
      okButtonProps={{
        disabled: false,
      }}
    >
      <Spin size="large" spinning={loading}>
        <Form
          layout="vertical"
          name="form_in_modal"
          onSubmit={handleSubmit}
          id="saveSubscription"
          form={form}
          ref={formRef}
          initialValues={defaultValues}
        >
          <Form.Item label="Duration" required>
            <input
              type="text"
              name="days"
              value={values.days || ""}
              placeholder="Duration Days"
              maxLength={30}
              onChange={(event) => {
                handleChange(null, event);
              }}
              className="w-100"
            />
            <span className="text-danger">
              {errors.days || serverErrors.days}
            </span>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}

export default SubscriptionDurationModal;
