import { useState, useEffect } from "react";
import { validate } from "./NameValidation";

const NameForm = (callBack) => {
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [fileDetails, setFileDetails] = useState("");

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      callBack();
    }
  }, [errors, isSubmit, values, defaultValues, disabled]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    console.log(values);
    console.log(errors);
    setIsSubmit(true);
  };

  const handleChange = (value, event) => {
    setIsSubmit(false);
    setFileDetails("");
    let newValues = {};
    if (event != null) {
      newValues = { ...values, [event.target.name]: event.target.value ? event.target.value : value };
    } else if(value != null) {
      newValues = { ...values, [value.name]: value.value };
    }
    console.log(newValues);
    setErrors(validate(newValues));
    setValues(newValues);
  };

  const handleCancel = (event) => {
    setDisabled(true);
    setIsSubmit(false);
    setErrors("");
    if (event.type == "click") {
      setValues(defaultValues);
    }
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    values,
    defaultValues,
    errors,
    disabled,
  };
};
export default NameForm;
