import { useEffect, useState } from "react";
import { Form, Breadcrumb, Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { Outlet, useNavigate } from "react-router-dom";
import { Notification } from "../../../common/Notification";
import axios from "axios";
import { ApiEndPoint, getApiKey } from "../../../../utils/commonutils";
import store from "../../../../data/store";
import { setUserDetails } from "../../../../features/userSlice";
import PageHeaderBreadCrumb from "../../../common/breadcrumb/PageHeaderBreadCrumb";
function ManageSpecifications(props) {
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("");
  const navigate = useNavigate();
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  const breadcrumbsData = [
    {
      name: "home",
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "text-primary-color",
    },
    {
      name: "ManageSpec",
      pageName: "Specification Dashboard",
      pageUrl: "/admin/specifications/specifications-dashboard",
      className: "active-link",
    },
  ];
  const handleClick = (event) => {
    setMode("Add");
    navigate("/admin/specifications/manage-specifications");
  };
  useEffect(() => {
    setMode("");
  }, []);
  return (
    <div>
      <div className="catalog">
        <div className="">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-7 col-xl-4">
              <PageHeaderBreadCrumb
                pageTitle="Specification Dashboard"
                breadcrumbsData={breadcrumbsData}
              />
            </div>
            {}
            <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
              <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
                <Button
                  className="bg-primary-color ml-2 btn"
                  name="addplan"
                  onClick={handleClick}
                  title="Add new category"
                  style={
                    mode == "Add" ? { display: "none" } : { display: "inline" }
                  }
                  icon={<PlusOutlined />}
                >
                  Add Specification
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Outlet context={[mode, setMode]} />
    </div>
  );
}

export default ManageSpecifications;
