import {
  Button,
  Card,
  Col,
  Empty,
  Input,
  message,
  Popconfirm,
  Row,
  Skeleton,
  Space,
  Spin,
  Switch,
} from "antd";
import { useCallback, useEffect, useState } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { useNavigate, useOutletContext } from "react-router-dom";
import { ApiEndPoint, getApiKey } from "../../../utils/commonutils";
import axios from "axios";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
function ListSubscriptions() {
  const navigate = useNavigate();
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const [loading, setLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState("");
  const [planStatus, setPlanStatus] = useState(false);
  let counter = 0;
  const [mode, setMode] = useOutletContext();
  const setPlanActiveStatus = (event) => {
    setPlanStatus(event);
  };
  let duration_in_months = "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  /* Service Call to change subscription plan status */
  const changeSubscriptionPlanStatus = (record) => {
    let url = record.id ? `subscription/${record.id}` : "";
    url = apiUrl + url;
    let data = {};
    data["status"] = planStatus;
    let response = axios(url, {
      method: "POST",
      data: data,
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        message.success(response.message);
        message.destroy();
        getSubscriptionPlans();
      } else {
        message.error("Oops!!! Something went wrong");
        message.destroy();
      }
    });
  };
  /* Service Call to delete subscription plan */
  const removeSubscriptionPlan = (record) => {
    let url = record.id ? `subscription/${record.id}` : "";
    url = apiUrl + url;
    let response = axios(url, {
      method: "DELETE",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        message.destroy();
        getSubscriptionPlans();
      } else {
        message.error("Oops!!! Something went wrong");
        message.destroy();
      }
    });
  };

  /* Service Call to edit subscription plan */
  // const editSubscriptionPlan = useCallback((record) => {
  //   setMode("Edit");
  //   setPlanDetailsToEdit(record);
  //   navigate("/admin/subscriptions/create-subscription");
  // });
  let i = 1;
  useEffect(() => {
    setLoading(true);
    getSubscriptionPlans();
  }, []);
  const getSubscriptionPlans = useCallback(() => {
    let url = "subscription";
    url = apiUrl + url;
    response = axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setPlanDetails(response.data);
        setLoading(false);
        setMode("");
      }
    });
  });
  return (
    <Spin size="large" spinning={loading}>
      <div className="card w-100 mt-4 mb-4">
        <div className="table-content">
          <div className="container">
            <div className="row align-items-center pb-3"></div>
            <Row>
              {Object.keys(planDetails).length
                ? Object.values(planDetails).map((type) => {
                    duration_in_months = type.subscription_days / 30;
                    i++;
                    counter++;
                    return (
                      <Col className="p-1" key={`subscription/${i}Col`}>
                        <Card
                          title={[
                            <div className="sub-card-title">
                              <h4>{type.plan_name}</h4>
                            </div>,
                          ]}
                          key={`subscription/${type.id}tiles`}
                          className="card p-3"
                          style={{ width: 300 }}
                          actions={[
                            <Space
                              wrap
                              split={false}
                              key={`subscription/${i}space`}
                            >
                              {/* <Popconfirm
                                title={
                                  "Change the " +
                                  type.plan_name +
                                  " plan Status?"
                                }
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() =>
                                  changeSubscriptionPlanStatus(type)
                                }
                                key={`subscription/${i}popconfirm1`}
                              >
                                <Switch
                                  title="Change Plan Status"
                                  size="small"
                                  defaultChecked={type.is_active}
                                  name=""
                                  onChange={(e) => setPlanActiveStatus(e)}
                                  key={`subscription/${i}switch`}
                                />{" "}
                              </Popconfirm> */}
                              {/* ,
                              <EditOutlined
                                style={{ color: "blue" }}
                                onClick={() => editSubscriptionPlan(type)}
                                title="Edit Subscription plan"
                                key={`subscription/${i}edit`}
                              />
                              , */}
                              <Popconfirm
                                title={"Delete " + type.plan_name + " ?"}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={() => removeSubscriptionPlan(type)}
                                key={`subscription/${i}popconfirm`}
                              >
                                <DeleteOutlined
                                  style={{ color: "red" }}
                                  key={`subscription/${i}ellipsis`}
                                />
                              </Popconfirm>
                            </Space>,
                          ]}
                        >
                          <div
                            style={{
                              minHeight: 450,
                              maxHeight: 450,
                              overflow: "auto",
                            }}
                          >
                            {`Duration : ${duration_in_months} Months`}
                            <br />
                            {`Amount : ${"$" + type.amount}`}
                            <br />
                            {type.no_of_marketplaces
                              ? `Number Of Marketplaces: ${type.no_of_marketplaces}`
                              : ""}
                            <br />
                            {type.no_of_products
                              ? `Number Of Products: ${type.no_of_products}`
                              : ""}
                            <br />
                            {Object.keys(type.marketplaces).length > 0
                              ? "Marketplaces :"
                              : ""}
                            <ul
                              className="sub-card-features"
                              key={`subscription/${i}ul1`}
                            >
                              {Object.values(type.marketplaces).map(
                                (marketplace) => {
                                  counter++;
                                  return (
                                    <li key={counter + marketplace.name}>
                                      {marketplace.name}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                            {Object.keys(type.features).length > 0
                              ? "Features  :"
                              : ""}
                            <ul
                              className="sub-card-features"
                              key={`subscription/${i}ul2`}
                            >
                              {Object.values(type.features).map((feature) => {
                                counter++;
                                return (
                                  <li key={counter + feature.name}>
                                    {feature.name}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                : ""}
            </Row>
            {planDetails.length <= 0 && <Empty />}
          </div>
        </div>
      </div>
    </Spin>
  );
}
export default ListSubscriptions;
