import React, { useState, useCallback } from "react";
import Icons from "../../assets/icons/index";
import UserRegisterForm from "./validate-registerform/UserRegisterForm";
import PasswordChecklist from "react-password-checklist";
import { useNavigate } from "react-router-dom";
import { Notification } from "../common/Notification";
function UserRegisteration(props) {
  const { values, errors, handleChange, handleSubmit } =
    UserRegisterForm(register);
  const [passwordValid, setpasswordValid] = useState(false);
  const navigate = useNavigate();
  function register() {
    if(!passwordValid){
      Notification({ type: "error", message: "Please provide a strong and matching password" });
    }else{
      console.log("called call back");
    }
  }
  return (
    <div className="card auth">
      <h1 className="h4 text-uppercase">Create Account</h1>
      <form onSubmit={handleSubmit}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 card-input p-2">
              <label>
                Name<span className="text-danger">*</span>
              </label>
              <div className="input-icon">
                <img src={Icons.nameIcon} alt="name" />
                <input
                  type="text"
                  name="name"
                  placeholder="First and Last Name"
                  required=""
                  onChange={handleChange}
                  value={values.name || ""}
                />
              </div>
              <span className="text-danger">{errors.name}</span>
            </div>
            <div className="col-12 col-md-12 card-input p-2">
              <label>
                Email Address<span className="text-danger">*</span>
              </label>
              <div className="input-icon">
                <img src={Icons.emailIcon} alt="email" />
                <input
                  type="email"
                  name="email"
                  placeholder="Email Address"
                  required=""
                  onChange={handleChange}
                  value={values.email || ""}
                />
              </div>
              <span className="text-danger">{errors.email}</span>
            </div>
            <div className="col-12 col-md-12 card-input p-2">
              <label>
                Password<span className="text-danger">*</span>
              </label>
              <div className="input-icon">
                <img src={Icons.passwordIcon} alt="" />
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.password || ""}
                />
              </div>
              <span className="text-danger">{errors.password}</span>
            </div>
            <div className="col-12 col-md-12 card-input p-2">
              <label>
                Re-Enter your password<span className="text-danger">*</span>
              </label>
              <div className="input-icon">
                <img src={Icons.passwordIcon} alt="" />
                <input
                  type="password"
                  name="passwordAgain"
                  placeholder="Password"
                  onChange={handleChange}
                  value={values.passwordAgain || ""}
                />
              </div>
              <span className="text-danger">{errors.passwordAgain}</span>
            </div>

            <PasswordChecklist
              rules={["minLength", "specialChar", "number", "capital", "match"]}
              minLength={5}
              value={values.password || ""}
              valueAgain={values.passwordAgain || ""}
              onChange={function (isValid) {
                if (isValid) {
                  setpasswordValid(isValid);
                } else {
                  setpasswordValid(isValid);
                }
              }}
            />
          </div>
        </div>
        <div className="login-button mt-2">
          <button
            className="bg-primary-color"
          >
            Create Account
          </button>
        </div>
        <div className="p-2 mt-2">
          <label htmlFor="remember-me">
            By creating an account, you agree to My-Ecom Wizard's{" "}
            <a href="">Conditions of use</a> and <a href="">Privacy Notice</a>
          </label>
        </div>
      </form>
      <p className="pt-2 m-0 text-center border-top">
        Already have an Account?{" "}
        <a href="/" className="text-primary-color">
          login
        </a>
      </p>
    </div>
  );
}
export default UserRegisteration;
