import { useEffect, useState } from "react";
import { DataTable } from "../../common/Table";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  UserDeleteOutlined,
  SearchOutlined,
  ClearOutlined,
  CheckOutlined,
  CloseOutlined,
  UserOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Popconfirm,
  Space,
  Form,
  Input,
  Button,
  Switch,
  message,
  Avatar,
} from "antd";
import Highlighter from "react-highlight-words";
import { CSVLink } from "react-csv";
import { ApiEndPoint, getApiKey } from "../../../utils/commonutils";
import Moment from "react-moment";
import PageHeaderBreadCrumb from "../../common/breadcrumb/PageHeaderBreadCrumb";
import { PlusOutlined, DownloadOutlined } from "@ant-design/icons";

function ManagePartners() {
  const [partnerData, setPartnerData] = useState([]);
  const [exportData, setExportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [statusToggle, setstatusToggle] = useState(false);
  const [mailBtnDisable, setMailBtnDisable] = useState(false);
  const { user } = useSelector((state) => state.user);
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  const navigate = useNavigate();

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  /* Service call to retrieve partners */
  useEffect(() => {
    setLoading(true);
    let response = "";
    getPartners();
    async function getPartners() {
      let url = "admin/getPartner";
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      }).then((response) => {
        if (response.success === true && response.status === 200) {
          setPartnerData(response.data);
          prepareExportData(response.data);
        }
      });
      setLoading(false);
    }
  }, []);

  /* Export data to CSV */
  const prepareExportData = (data) => {
    let expData = [];
    Object.keys(data).forEach(function (key, index) {
      expData.push({
        Partner: data[index]["first_name"],
        Company: data[index]["company_name"],
        Email: data[index]["email"],
        Country: data[index]["country_name"],
        Phone: data[index]["phone"],
        Address: data[index]["address1"] + ", " + data[index]["address2"],
        About: data[index]["about"],
        Website: data[index]["website"],
        Partner_since: data[index]["created_at"],
        Active: data[index]["is_active"] == "1" ? "Yes" : "No",
      });
    });
    setExportData(expData);
  };

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  /* Table headers for listing */
  const columnHeading = [
    {
      title: "Partner",
      dataIndex: "partner_name",
      sorter: (a, b) => a.partner_name.localeCompare(b.partner_name),
      ...getColumnSearchProps("partner_name", "Name"),
      render: (value, record) => (
        <Space>
          {/* <a onClick={() => {handleView(record.partner_id)}}> */}
          <Avatar icon={<UserOutlined />} />
          <span className="ml-2">{record.partner_name}</span>
          {/* </a> */}
        </Space>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps("email", "Email"),
    },
    {
      title: "Phone",
      dataIndex: "phone",
      sorter: (a, b) => a.phone.localeCompare(b.phone),
      ...getColumnSearchProps("phone", "Phone"),
    },
    {
      title: "Country",
      dataIndex: "country_name",
    },
    {
      title: "Active",
      dataIndex: "is_active",
      align: "center",
      sorter: (a, b) => a.is_active - b.is_active,
      render: (value, record) => (
        <Switch
          onChange={(e) => handleStatus(e, record)}
          defaultChecked={value}
          checkedChildren={<CheckOutlined title="Active" />}
          unCheckedChildren={<CloseOutlined title="Inactive" />}
        ></Switch>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return partnerData.length >= 1 ? (
          <Space>
            <Popconfirm
              title={"Remove " + record.first_name + "?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => deletePartner(record)}
            >
              <Button
                type="danger"
                className="btn-danger"
                icon={<UserDeleteOutlined />}
                title="Remove Partner"
              />
            </Popconfirm>
            {record.status != "Closed" ? (
              <Button
                icon={<SettingOutlined />}
                className="bg-primary-color"
                title="Manage Partner"
                onClick={() => handleClick(record.partner_id)}
              />
            ) : null}
            {record.is_active == null ? (
              <Popconfirm
                title={"Do you want to resend the mail ?"}
                okText="Yes"
                cancelText="No"
                onConfirm={() =>
                  resendPartnerRegistrionEmail(record.partner_id)
                }
                disabled={mailBtnDisable}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<MailOutlined />}
                  size="small"
                  title="Partner registration mail"
                />
              </Popconfirm>
            ) : (
              ""
            )}
          </Space>
        ) : null;
      },
    },
  ];

  const updatePartnerStatus = (record) => {
    var url = "admin/updatePartner/";
    var param = record.partner_id;
    url = param ? url + param : url;
    url = apiUrl + url;
    let response = axios(url, {
      method: "POST",
      data: record,
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  const resendPartnerRegistrionEmail = (id) => {
    setMailBtnDisable(true);
    console.log("resendPartnerRegistrionEmail = ", [id]);
    let requestBody = {
      id: id,
    };
    try {
      async function resendRegistrionEmail() {
        var baseUrl = apiUrl + "admin/resendPartnerRegistrationMail/";
        console.log("baseUrl - ", [baseUrl]);
        response = await axios(baseUrl, {
          method: "POST",
          data: requestBody,
          headers: headers,
        }).then((response) => {
          if (response) {
            if (response.success === true && response.status === 200) {
              message.success(response.message);
            } else if (response.status == 422) {
              message.error("Oops!! Something went wrong");
              message.destroy();
            } else {
              message.error("Oops!! Something went wrong");
              message.destroy();
            }
          }
          setMailBtnDisable(false);
        });
      }
      resendRegistrionEmail();
      message.destroy();
    } catch (error) {
      throw new Error(error);
    }
  };
  const handleStatus = (value, record) => {
    statusToggle ? setstatusToggle(true) : setstatusToggle(false);
    //Setting status
    record.is_active = value;
    updatePartnerStatus(record);
    message.destroy();
  };

  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  const handleClick = (partnerId) => {
    navigate("/admin/manage-profile", {
      state: {
        partnerId: partnerId,
      },
    });
  };

  const handleView = (partnerId) => {
    console.log("partnerId-", partnerId);
    navigate("/admin/view-partner", {
      state: {
        partnerId: partnerId,
      },
    });
  };

  /* Service Call to delete partners */
  const deletePartner = (record) => {
    let url = "admin/remove/";
    let param = record.partner_id ? record.partner_id : "";
    url = param ? url + param : url;
    url = apiUrl + url;
    let response = axios(url, {
      method: "POST",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        const filteredData = partnerData.filter(
          (item) => item.partner_id !== record.partner_id
        );
        setPartnerData(filteredData);
      } else {
        message.error("Oops!!! Something went wrong");
      }
    });
  };

  const breadcrumbsData = [
    {
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "",
    },
    {
      pageName: "Partner Dashboard",
      pageUrl: "/admin/manage-partners",
      className: "active-link",
    },
  ];

  return (
    <div>
      <div className="row container col-12">
        <div className="col-6">
          <PageHeaderBreadCrumb
            pageTitle="Partner Dashboard"
            breadcrumbsData={breadcrumbsData}
          />
        </div>
        <div className="col-6 mt-5">
          {/* Search */}
          <div className="container text-right">
            <Space>
              <Button
                className="btn bg-secondary-color text-bold"
                title="Export to CSV"
                icon={<DownloadOutlined className="pr-1" />}
              >
                <CSVLink data={exportData} filename={"Partner-DataTable.csv"} />
                Export to CSV
              </Button>
              <Button
                icon={<PlusOutlined />}
                onClick={() => navigate("/admin/add-partner")}
                className="btn bg-primary-color"
              >
                Add Partner
              </Button>
            </Space>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="card mt-1 mb-4">
          <div className="table-content">
            <div className="container">
              <div className="row align-items-center bg-secondary-color card-header">
                <div className="col-12 col-md-12 col-lg-6 col-xl-6">
                  <h3 className="h6 w-100 mb-0">Partner Listing</h3>
                </div>
              </div>
              <div className="w-100 overflow-auto py-2">
                <Form form={form} component={false}>
                  <div className="w-100 overflow-auto py-2">
                    <div className="table-wrapper">
                      <DataTable
                        columns={columnHeading}
                        rowClassName="editable-row text-primary-color"
                        data={partnerData}
                        expandable={{
                          expandedRowRender: (record) => (
                            <table>
                              <thead className="ant-table-thead">
                                <tr className="ant-table-expanded-row ant-table-expanded-row-level-1">
                                  <th className="ant-table-cell">About</th>
                                  <th className="ant-table-cell">Website</th>
                                  <th className="ant-table-cell">Address</th>
                                  <th className="ant-table-cell">
                                    Partner-Since
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="ant-table-tbody">
                                <tr className="ant-table-expanded-row ant-table-expanded-row-level-1">
                                  <td className="ant-table-cell">
                                    {record.about}
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.website}
                                  </td>
                                  <td className="ant-table-cell">
                                    {record.address1}, {record.address2},{" "}
                                    {record.city}, {record.state},{" "}
                                    {record.zipcode}
                                  </td>
                                  <td className="ant-table-cell">
                                    <Moment format="MMMM Do YYYY">
                                      {record.created_at}
                                    </Moment>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          ),
                        }}
                        loading={loading}
                        pagination={{
                          position: ["bottomCenter"],
                          defaultPageSize: 100,
                          showSizeChanger: true,
                          showQuickJumper: true,
                        }}
                      />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ManagePartners;
