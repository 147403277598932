import React, { Component } from "react";
import FusionCharts from "fusioncharts";
import Charts from "fusioncharts/fusioncharts.charts";
import ReactFC from "react-fusioncharts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";
import GammelTheme from "fusioncharts/themes/fusioncharts.theme.gammel";

ReactFC.fcRoot(FusionCharts, Charts, FusionTheme, GammelTheme);

export const Chart = (props) => {
  const chartConfigs = {
    type: props.type,
    width: props.width || "100%",
    height: props.height || 400,
    dataFormat: "json",
    dataSource: props.dataSource,
  };
  return <ReactFC {...chartConfigs} />;
};