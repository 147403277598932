import { useLocation } from "react-router-dom";

const ViewPartner = () => {

    const location = useLocation;
    const partnerId = location.state ? location.state.partnerId : "";

    return (
        <>
        <div>
            Hello<p>{partnerId}</p>
        </div>
        </>
    );
}

export default ViewPartner;