import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Spin,
  Steps,
  Switch,
  InputNumber,
  Popover,
} from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { useCallback, useEffect, useRef, useState } from "react";
import FormItem from "antd/es/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import DynamicField from "./DynamicFeild";
import PlanForm from "./validate-plans/PlanForm";
import { useNavigate, useOutletContext } from "react-router-dom";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
function AddSubscription({
  errorStatus,
  setErrorStatus,
  planDetaileToEdit,
  setPlanDetailsToEdit,
  current,
  setCurrent,
  pageDetails,
  setPageDetails,
  subscriptionDetails,
  setSubscriptionDetails,
  setMarketplacesMapped,
  setSubscriptionRules,
  partnerOptions
}) {
  //initialization section started
  const navigate = useNavigate();
  const [formLayout, setFormLayout] = useState("horizontal");
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [serverErrors, setErrors] = useState({});
  let apiUrl = ApiEndPoint();
  const formRef = useRef();
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };
  const [subscriptionLevel, setLevels] = useState({});
  const [subscriptionDuration, setDuration] = useState({});
  const handleCancel = useCallback((event) => {
    // Modal.destroyAll();
  });

  const { Option } = Select;
  const [showTrialPeriod, setShowTrialPeriod] = useState(false);
  const [maxTrialUnit, setMaxTrialUnit] = useState(12);
  const [trialDurationUnit, setTrialDurationUnit] = useState("month");
  const trialPeriodRegex = /^[1-9]\d*$/;


  const handleTrialPeriodToggle = (value, event) => {
    if (value === true) {
      setTrialDurationUnit("month");
      values.trial_period = true;
      values.trial_duration = values.trial_duration
        ? values.trial_duration
        : null;
      values.trial_duration_unit = values.trial_duration_unit
        ? values.trial_duration_unit
        : "month";
    } else {
      values.trial_period = false;
      values.trial_duration = null;
      values.trial_duration_unit = null;
    }
    setShowTrialPeriod(value);
    var eventObject = {
      name: "trial_period",
      value: value,
    };
    handleChange(eventObject, null);
  };

  const handleTrialDurationChange = (value) => {
    var eventObject = {
      name: "trial_duration",
      value: value,
    };
    handleChange(eventObject, null);
  };

  const handleTrialUnitChange = (value) => {
    /* Setting max value of input based on month/day */
    setTrialDurationUnit(value);
    setMaxTrialUnit(value == "month" ? 12 : 365);
    /* Clear duration on duration unit change */
    form.setFieldsValue({
      trial_duration: "",
    });
    values.trial_duration = "";
    var eventObject = {
      name: "trial_duration_unit",
      value: value,
    };
    handleChange(eventObject, null);
  };

  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let headers = {
    Accept: "application/json",
    "Content-Type": "multipart/form-data",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  //initialization section ended
  //function call to save subscription plan details (works as callback after the validation in submition is done)
  const savePlan = useCallback(() => {
    setCurrent(0);
    setConfirmLoading(true);
    setConfirmLoading(false);
    setLoading(true);
    if (Object.keys(subscriptionDetails).length == 0 || (partnerOptions && Object.keys(subscriptionDetails).length > 0 )) {
      saveSubscriptionPlan();
    } else {
      setErrorStatus(false);
      setLoading(false);
      setCurrent(current + 1);
      setPageDetails({
        pagename: "MapMarketplaces",
      });
    }
    async function saveSubscriptionPlan() {
      const formData = new FormData();
      formData.append("subscription_plan_id", values.name);
      formData.append("amount", values.amount);
      formData.append("subscription_days_id", values.duration);
      formData.append("no_of_marketplaces", values.no_of_marketplaces);
      formData.append("no_of_products", values.no_of_products);
      formData.append("is_active", values.is_active ? values.is_active : 0);
      formData.append("item_update_frequency", values.item_update_frequency );
      formData.append("item_inventory_update_frequency", values.item_inventory_update_frequency );
      formData.append("order_fetch_frequency", values.order_fetch_frequency );
      formData.append("order_update_frequency", values.order_update_frequency );
      formData.append(
        "trial_period",
        values.trial_period ? 1 : 0
      );
      if (values.trial_period){
        formData.append(
          "trial_duration",
          values.trial_duration ? values.trial_duration : null
        );
        formData.append(
          "trial_duration_unit",
          values.trial_duration_unit ? values.trial_duration_unit : "month"
        );
      }

      let url = "subscription";
      let method = "POST";
      let response = "";
      url = apiUrl + url;
      response = await axios(url, {
        method: method,
        data: formData,
        headers: headers,
      })
        .then((response) => {
          if (response.success && response.status == 200) {
            setLoading(false);
            setErrorStatus(false);
            setErrors([]);
            message.success(response.message);
            message.destroy();
            setCurrent(current + 1);
            setPageDetails({
              pagename: "MapMarketplaces",
            });
            setSubscriptionDetails(response.data);
            setSubscriptionFormDetails(response.data);
          } else if (response.status == 422) {
            message.error("Please fix the error(s)");
            message.destroy();
            setErrors([]);
            let validationErrors = response.data;
            if(Array.isArray(validationErrors)){
              /* Iterate errors and append to the error message */
              let errorMessage = "";
              Object.keys(validationErrors).map((key, value) => {
                validationErrors[key].map((value, ind) =>
                  setErrors((data) => ({
                    ...data,
                    [key]: value,
                  }))
                );
              });
            }else{
              message.error(response.message);
            }
            setErrorStatus(true);
            setLoading(false);
          } else if (response.status == 401) {
            message.error(response.message);
            setLoading(false);
            message.destroy();
          }
        })
        .catch((error) => {
          setLoading(false);
          navigate("/");
        });
    }
  });

  const { values, errors, handleChange, handleSubmit } = PlanForm(savePlan);
  //executes when the page is loaded
  useEffect(() => {
    //loads name , duration master data
    getSubscriptionNames();
    getSubscriptionDurations();
    if (Object.keys(subscriptionDetails).length > 0) {
      setSubscriptionFormDetails(subscriptionDetails);
    }
  }, []);
  useEffect(() => {
    setErrorStatus(true);
  }, [values]);

  // useEffect(() => {
  //   let trialDuration = values.trial_duration;
  // }, [values]);

  //service call to initialize the page with values of predefined subscription Names
  async function getSubscriptionNames() {
    let url = "subscription-plan";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        setLevels((prevState) => {
          return { ...prevState, ...response.data };
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }
  //service call to initialize the page with values of predefined subscription duration
  async function getSubscriptionDurations() {
    let url = "subscription-days";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        setDuration((prevState) => {
          return { ...prevState, ...response.data };
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
  }
  const handleFailure = useCallback(() => {
    setErrorStatus(true);
  });
  const setSubscriptionFormDetails = (response) => {
    values.name = parseInt(response.subscription_plan_id);
    values.duration = parseInt(response.subscription_days_id);
    values.amount = response.amount;
    values.no_of_marketplaces = response.no_of_marketplaces;
    values.no_of_products = response.no_of_products;
    values.is_active = response.is_active;
    values.item_update_frequency = response.item_update_frequency;
    values.item_inventory_update_frequency = response.item_inventory_update_frequency;
    values.order_fetch_frequency = response.order_fetch_frequency;
    values.order_update_frequency = response.order_update_frequency
    form.setFieldsValue(values);
  };
  return (
    <Spin size="large" spinning={loading}>
      <Form
        {...formItemLayout}
        layout={formLayout}
        onFinish={(event) => {
          handleSubmit();
        }}
        id="SaveSubscriptions"
        onFinishFailed={handleFailure}
        ref={formRef}
        form={form}
      >
        <div className="container">
          <div className="row offer-detail">
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">
                Subscription<span className="text-danger">*</span>
              </label>
              <Select
                name="name"
                value={values.name ? values.name : null}
                size="large"
                style={{
                  alignItems: "initial",
                }}
                onSelect={(value, event) => {
                  handleChange(event, null);
                }}
                placeholder="Select Subscription"
              >
                {Object.values(subscriptionLevel).map((item) => (
                  <Select.Option name="name" key={item.id} value={item.id}>
                    {item.plan}
                  </Select.Option>
                ))}
              </Select>
              <span className="text-danger text-left">{errors.name || serverErrors.subscription_plan_id}</span>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">
                Subscription Duration<span className="text-danger">*</span>
              </label>
              <Select
                name="duration"
                value={values.duration ? values.duration : null}
                size="large"
                style={{
                  alignItems: "initial",
                }}
                onSelect={(value, event) => {
                  handleChange(event, null);
                }}
                placeholder="Select Duration"
              >
                {Object.values(subscriptionDuration).map((item) => (
                  <Select.Option name="duration" key={item.id} value={item.id}>
                    {item.description}
                  </Select.Option>
                ))}
              </Select>
              <span className="text-danger text-left">{errors.duration || serverErrors.duration}</span>
            </div>

            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">
                Subscription Amount<span className="text-danger">*</span>
              </label>
              <Input
                name="amount"
                value={values.amount}
                className="text-primary-color"
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Subscription Amount"
              />
              <span className="text-danger text-left">{errors.amount}</span>
            </div>
          </div>
          <div className="row offer-detail pt-2">

          <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">
                Number of Products<span className="text-danger">*</span>
              </label>
              <Input
                name="no_of_products"
                value={values.no_of_products  }
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Number of Products"
              />
              <span className="text-danger text-left">
                {errors.no_of_products}
              </span>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">
                Item update frequency<span className="text-danger">*</span>
              </label>
              <Input
                name="item_update_frequency"
                className="text-primary-color"
                value={values.item_update_frequency}
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Item update frequency"
              />
              <span className="text-danger text-left">
                {errors.item_update_frequency || serverErrors.item_update_frequency}
              </span>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
            <label className="text-left h1">
              Item inventory update frequency<span className="text-danger">*</span>
              </label>
              <Input
                name="item_inventory_update_frequency"
                value={values.item_inventory_update_frequency}
                className="text-primary-color"
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Item inventory update frequency"
              />
              <span className="text-danger text-left">{errors.item_inventory_update_frequency || serverErrors.item_inventory_update_frequency}</span>
            </div>
          </div>
          <div className="row offer-detail pt-2">
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
            <label className="text-left h1">
              Order fetch frequency<span className="text-danger">*</span>
              </label>
              <Input
                name="order_fetch_frequency"
                value={values.order_fetch_frequency}
                className="text-primary-color"
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Order fetch frequency"
              />
              <span className="text-danger text-left">{errors.order_fetch_frequency}</span>
            </div>
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
            <label className="text-left h1">
              Order update frequency<span className="text-danger">*</span>
              </label>
              <Input
                name="order_update_frequency"
                value={values.order_update_frequency}
                className="text-primary-color"
                maxLength={5}
                onChange={(event) => {
                  handleChange(null, event);
                }}
                placeholder="Order update frequency"
              />
              <span className="text-danger text-left">{errors.order_update_frequency}</span>
            </div>

            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">Is an active Plan?</label>
              <div className="pt-2">
                <Switch
                  name="is_active"
                  value={values.is_active}
                  className="float-left"
                  checked={values.is_active}
                  onChange={(value, event) => {
                    let eventObject = {
                      name: "is_active",
                      value: value,
                    };
                    handleChange(eventObject, null);
                  }}
                  defaultChecked
                />
                 <span className="text-danger text-left">
                {errors.is_active || serverErrors.is_active}
              </span>
              </div>
            </div>
          </div>

          <div className="row offer-detail pt-2">
            <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
              <label className="text-left h1">Trial Period Included?</label>
              <div className="pt-2 text-left">
                <Switch
                  name="trial_period"
                  value={values.trial_period}
                  className="mt-1"
                  checked={values.trial_period}
                  onChange={(value, event) =>
                    handleTrialPeriodToggle(value, event)
                  }
                />               
              </div>
              <span className="text-danger text-left">
                {errors.trial_period || serverErrors.trial_period}
              </span>
            </div>

            {showTrialPeriod ? (
              <div className="col-12 col-md-4 d-flex flex-column mt-3 mt-lg-0">
                <label className="text-left h1">
                  {"Trial Duration in " + trialDurationUnit}
                  <span className="text-danger">*</span>
                  <Popover
                    content={
                      "Please enter the " +
                      trialDurationUnit +
                      " between (1 & " +
                      maxTrialUnit +
                      ")"
                    }
                    title={"Trial Period in " + trialDurationUnit}
                  >
                    <InfoCircleTwoTone className="ml-2" />
                  </Popover>
                </label>
                <InputNumber
                  className="pl-0"
                  style={{ paddingTop: "8px", width: "75%" }}
                  name="trial_duration"
                  size="medium"
                  value={values.trial_duration}
                  addonBefore={
                    <Select
                      defaultValue="month"
                      onChange={handleTrialUnitChange}
                      name="trial_duration_unit"
                      value={values.trial_duration_unit || "month"}
                    >
                      <Option value="month">Month</Option>
                      <Option value="day">Day</Option>
                    </Select>                    
                  }
                  min={1}
                  max={maxTrialUnit}
                  maxLength={3}
                  placeholder={"Trial Duration in " + trialDurationUnit}
                  onChange={handleTrialDurationChange}
                />
                <span className="text-danger text-left">
                  {errors.trial_duration_unit || serverErrors.trial_duration_unit}
                  </span>
                <span className="text-danger text-left">
                  {errors.trial_duration || serverErrors.trial_duration}
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </Form>
    </Spin>
  );
}

export default AddSubscription;
