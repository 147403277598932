import { useState, useEffect } from "react";
import { validate } from "./RegisterValidation";

const UseRegForm = (callBack) => {
  const [values, setValues] = useState({});
  const [defaultValues, setDefaultValues] = useState(values);
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [countryData, setCountryData] = useState({});

  useEffect(() => {    
    if (Object.keys(errors).length === 0 && isSubmit) {
      callBack(handleCancel);
    }
  }, [errors, isSubmit, values, defaultValues, disabled]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values, countryData.countrySelected));
    setIsSubmit(true);
  };

  const handleChange = (event,type="") => {
    setIsSubmit(false);
    setDisabled(false);
    let newValues = {};
    if(type == "checkbox"){
      newValues = { ...values, [event.target.name]: event.target.checked };
    }else{
      newValues = { ...values, [event.target.name]: event.target.value };
    }
    //setErrors(validate(newValues));
    setValues(newValues);
    /* To handle cancel */
    if (event.type == "click" && event.target.className == 'editCancelCls') {      
      setDefaultValues(newValues);
    }
  };

  const handleCancel = (event) => {
    //event.preventDefault();    
    setDisabled(true);
    setIsSubmit(false);
    setErrors("");
    if (event && event.type == 'click'){
      setValues(defaultValues);   
      window.location.reload();
    }    
  };

  return {
    handleChange,
    handleSubmit,
    handleCancel,
    values,
    defaultValues,
    errors,
    disabled,
    countryData
  };
};
export default UseRegForm;
