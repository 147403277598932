import { Button, Form, message, Steps } from "antd";
import { items } from "fusioncharts";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import AddSubscription from "./AddSubscription";
import MapMarketplaces from "./MapMarketplaces";
import MapRules from "./MapRules";
import {
  DollarCircleOutlined,
  ToolOutlined,
  SettingOutlined,
  BankOutlined,
  ScheduleOutlined
} from "@ant-design/icons";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import axios from "axios";
import { useLocation } from "react-router-dom";

function SubscriptionDetails() {
  //initialization started
  const [pageDetails, setPageDetails] = useState({
    pagename: "SubscriptionPlan",
  });
  const location = useLocation();
  const partnerRequirement = location.state ? location.state.userData : "";
  const partnerId = location.state ? location.state.partnerId : "";
  const mktplc = location.state ? location.state.marketplaces : [];console.log(location.state)
  const [subscriptionDetails, setSubscriptionDetails] = useState([]);
  const [marketplacesMapped, setMarketplacesMapped] = useState(mktplc);
  const [marketPlaces, setMarketPlaces] = useState(mktplc);
  const [rulesMapped, setSubscriptionRules] = useState([]);
  const [errorStatus, setErrorStatus] = useState(true);
  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const navigate = useNavigate();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };
  let apiUrl = ApiEndPoint();
  const getAnswerByQuestion = (targetQuestion) => {
    const foundQuestion = partnerRequirement.find(question => question.question === targetQuestion);
    if(targetQuestion == "Marketplaces"){
      const marketplaces = (foundQuestion)?foundQuestion.answer.split(', '):'';
      return marketplaces ? marketplaces.length : null;
    }
  
    // If the question is found, return its answer; otherwise, return null
    return foundQuestion ? foundQuestion.answer : null;
  };

  if(partnerRequirement){
    subscriptionDetails.no_of_products = getAnswerByQuestion("Number of items");
    subscriptionDetails.item_update_frequency = getAnswerByQuestion("Item update frequency");
    subscriptionDetails.item_inventory_update_frequency = getAnswerByQuestion("Item inventory update frequency");
    subscriptionDetails.order_fetch_frequency = getAnswerByQuestion("Order fetch frequency");
    subscriptionDetails.order_update_frequency = getAnswerByQuestion("Order update frequency");
    subscriptionDetails.no_of_marketplaces = getAnswerByQuestion("Marketplaces");
  }

  


  //initilization ended
  //init steps
  const steps = [
    {
      title: `Add Subscription Plan`,
      content: (
        <AddSubscription
          errorStatus={errorStatus}
          setErrorStatus={setErrorStatus}
          current={current}
          setCurrent={setCurrent}
          pageDetails={pageDetails}
          setPageDetails={setPageDetails}
          subscriptionDetails={subscriptionDetails}
          setSubscriptionDetails={setSubscriptionDetails}
          setMarketplacesMapped={setMarketplacesMapped}
          setSubscriptionRules={setSubscriptionRules}
          partnerOptions = {partnerRequirement ? true : false}
        />
      ),
      icon: <DollarCircleOutlined />,
    },
    {
      title: "Map Marketplaces",
      content: (
        <MapMarketplaces
          marketplacesMapped={marketplacesMapped}
          setMarketplacesMapped={setMarketplacesMapped}
        />
      ),
      icon: <BankOutlined />,
    },
    {
      title: "Map Features",
      content: (
        <MapRules
          rulesMapped={rulesMapped}
          setSubscriptionRules={setSubscriptionRules}
        />
      ),
      icon: <ScheduleOutlined />,
    },
  ];
  //handles next button click
  const next = () => {
    if (pageDetails.pagename == "SubscriptionPlan") {
      document
        .getElementById("SaveSubscriptions")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
    if (!errorStatus) {
      if (current != 1) {
        setCurrent(current + 1);
      }
      if (current == 0) {
        setPageDetails({
          pagename: "MapMarketplaces",
        });
      } else if (current == 1) {
        setPageDetails({
          pagename: "MapMarketplaces",
        });
        //map marketplace
        if (pageDetails.pagename == "MapMarketplaces") {
          if (Object.keys(marketplacesMapped).length > 0) {
            let marketplacePostData = {};
            marketplacePostData["marketplaces"] = marketplacesMapped;
            mapSubscriptionMarketplace(marketplacePostData);
          } else {
            message.error("Please Choose atlease one marketplace");
            message.destroy();
          }
        }
      }
    }
  };
  //handles previous button click
  const prev = () => {
    setCurrent(current - 1);
    if (current == 2) {
      setPageDetails({
        pagename: "MapMarketplaces",
      });
    }
    if (current == 1) {
      setPageDetails({
        pagename: "SubscriptionPlan",
      });
    }
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
    icon: item.icon,
  }));
  //function call when the final save is clicked.
  const onFinalSave = () => {
    //map features
    if (Object.keys(rulesMapped).length > 0) {
      let featuresPostData = {};
      featuresPostData["subscription_id"] = subscriptionDetails.id
        ? subscriptionDetails.id
        : "";
      featuresPostData["features"] = rulesMapped;
      mapSubscriptionFeature(featuresPostData);
      message.success("Subscription Details are Saved!");
      message.destroy();
      setErrorStatus(false);
      partnerRequirement ? navigate("/admin/manage-profile",{
        state: {
          partnerId: partnerId
        },}) :navigate("/admin/subscriptions/manage-subscription");
    } else {
      message.error("Please Choose atlease one feature");
      message.destroy();
    }
  };
  //service call to save marketplace subscription mapping
  async function mapSubscriptionMarketplace(marketplacePostData) {
    let url = `map-subscription-marketplace/${subscriptionDetails.id}`;
    url = apiUrl + url;
    let response = await axios(url, {
      method: "POST",
      data: marketplacePostData,
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        message.success(response.message);
        setCurrent(2);
        setPageDetails({
          pagename: "MapRules",
        });
      } else {
        message.error(response.message.marketplaces);
        setPageDetails({
          pagename: "MapMarketplaces",
        });
      }
    });
  }
  //service call to save feature subscription mapping
  async function mapSubscriptionFeature(featuresPostData) {
    let url = "map-subscription-feature";
    url = apiUrl + url;
    let response = await axios(url, {
      method: "POST",
      data: featuresPostData,
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        message.success(response.message);
      } else {
        message.error(response.message);
        message.destroy();
      }
    });
  }

  //executes when the page is loaded
  useEffect(() => {
    setPageDetails({
      pagename: "SubscriptionPlan",
    });
  }, []);


  return (
    <div
      className="card w-100 mt-4 mb-4"
      style={{ maxHeight: 1000, overflowY: "auto" }}
    >
      <div className="table-content">
        <div className="container">
          <div className="row align-items-center pb-3"></div>
          <Steps
            size="small"
            current={current}
            style={{ paddingLeft: 20, paddingTop: 20, xs: 24, xl: 12 }}
          >
            {items.map((item) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                icon={item.icon}
              />
            ))}
          </Steps>
          <div className="steps-content">{steps[current].content}</div>
          <div
            className="steps-action"
            style={{ float: "right", paddingBottom: 10 }}
          >
            {current > 0 && (
              <Button
                style={{
                  margin: "0 8px",
                  borderRadius: "15px",
                }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
            {current < steps.length - 1 && (
              <Button
                type="primary"
                style={{
                  borderRadius: "15px",
                }}
                onClick={() => next()}
              >
                Next
              </Button>
            )}
            {current === steps.length - 1 && (
              <Button
                type="primary"
                style={{
                  borderRadius: "15px",
                }}
                onClick={() => {
                  onFinalSave();
                }}
              >
                Done
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionDetails;
