import { Button, Steps } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useOutletContext, useLocation } from "react-router-dom";
import SpecCategoryMapForm from "./SpecCategoryMapForm";
import SpecificationForm from "./SpecificationForm";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CheckOutlined,
} from "@ant-design/icons";

//Spec detail context - A parent component to share data to child components
const EditSpecContext = React.createContext();

function AddSpecification(props) {
  const stateData = useLocation();
  const navigate = useNavigate();
  const [mode, setMode] = useOutletContext();
  const [current, setCurrent] = useState(0);
  const [errorStatus, setErrorStatus] = useState(true);
  const [pageDetails, setPageDetails] = useState({
    pagename: "specification-add",
  });
  const [checked, setChecked] = useState(false);
  const [valueType, setValueType] = useState();
  const [specName, setSpecName] = useState("");
  const [specId, setSpecId] = useState("");
  const [mappedCategories, setMappedCategories] = useState([]);
  const [editSpecInfo, setEditSpecInfo] = useState([]);

  useEffect(() => {
    let stateSpecs = stateData.state ? stateData.state : {};
    setMode(Object.keys(stateSpecs).length > 0 ? "Edit" : "Add");
    setEditSpecInfo(stateSpecs);
  }, []);

  const steps = [
    {
      title: "Specification",
      content: (
        <SpecificationForm
          setPageDetails={setPageDetails}
          setErrorStatus={setErrorStatus}
          setCurrent={setCurrent}
          checked={checked}
          setChecked={setChecked}
          valueType={valueType}
          setValueType={setValueType}
          specName={specName}
          setSpecName={setSpecName}
          setSpecId={setSpecId}
        />
      ),
    },
    {
      title: "Map Specification with Category",
      content: (
        <SpecCategoryMapForm
          setPageDetails={setPageDetails}
          mappedCategories={mappedCategories}
          setMappedCategories={setMappedCategories}
          specId={specId}
        />
      ),
    },
  ];
  const next = () => {
    if (pageDetails.pagename == "specification-add") {
      document
        .getElementById("SaveSpecifications")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onFinalSave = () => {
    if (pageDetails.pagename == "specification-map") {
      document
        .getElementById("SaveSpecMap")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    color: "rgba(0, 0, 0, 0.45)",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    borderRadius: "8px",
    border: `1px dashed `,
    marginTop: 16,
  };

  return (
    <>
      <div className="catalog">
        <Steps
          size="small"
          current={current}
          style={{ borderColor: "#0d4835" }}
          className="border bg-light p-5"
        >
          {items.map((item) => (
            <Steps.Step
              key={item.title}
              title={item.title}
              className="text-bold"
            />
          ))}
        </Steps>
      </div>
      <EditSpecContext.Provider
        value={{ editSpecData: editSpecInfo, mode: mode, specName: specName }}
      >
        <div style={contentStyle}>{steps[current].content}</div>
      </EditSpecContext.Provider>
      <div
        className="steps-action"
        style={{ float: "right", paddingBottom: 10 }}
      >
        {current > 0 && (
          <Button
            icon={<ArrowLeftOutlined />}
            style={{
              margin: "0 8px",
              borderRadius: "15px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            icon={<ArrowRightOutlined />}
            style={{
              borderRadius: "15px",
            }}
            onClick={() => next()}
            className="bg-primary-color"
          >
            Save & Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            icon={<CheckOutlined />}
            onClick={() => onFinalSave()}
            style={{
              borderRadius: "15px",
            }}
            className="bg-primary-color"
          >
            Done
          </Button>
        )}
      </div>
    </>
  );
}
export default AddSpecification;
export { EditSpecContext };
