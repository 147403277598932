import logo from "../assets/images/ecom-brand-logo.png";
import ProfileMenu from "./ProfileMenu";
import SearchBox from "./SearchBox";
import { useDispatch, useSelector } from "react-redux";
import MainMenu from "./MainMenu";
import { useNavigate } from "react-router-dom";
import { useGetUserDetailsQuery } from "../services/authService";
import { setCredentials } from "../features/auth/authSlice";
import { useEffect } from "react";

function Header() {
  // user object
  const { auth } = useSelector((state) => state);
  const dispatch = useDispatch();
  // automatically authenticate user if token is found
  const { data, isFetching } = useGetUserDetailsQuery("userDetails", {
    pollingInterval: 900000, // 15mins
  });
  useEffect(() => {
    if (data) {
      if(data.data != null && (data.data.token == auth.userToken)){
          dispatch(setCredentials(data.data));
      }
    }
  }, [data, dispatch]);
  const navigate = useNavigate();
  const onClick = (event) => {
    event.preventDefault();
    let name = event.target.name;
    if (name == "home") {
      if (auth.userInfo.role == "Partner" && auth.userInfo.role == "Manager") {
        navigate("/partner/dashboard");
      } else {
        navigate("/dashboard");
      }
    }
  };
  return (
    <header className="header">
      <nav className="navbar navbar-expand-lg navbar-light ">
        <a className="navbar-brand" href="" name="home" onClick={onClick}>
          <img src={logo} className="img-fluid" name="home" onClick={onClick} />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {auth.userToken !== null ? <MainMenu /> : null}
          <div className="menu-2">
            {
              //user !== null ? <SearchBox /> : null
            }
            {auth.userToken !== null ? <ProfileMenu /> : null}
          </div>
        </div>
      </nav>
    </header>
  );
}
export default Header;
