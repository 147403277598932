export const validate = (formValues, countryCodeSelected) => {
    let errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const regexName = /^[a-zA-Z_\s-]+$/;
    const regexcompany_name = /^[a-zA-Z0-9-_,. ]+$/;
    countryCodeSelected = '+'+countryCodeSelected;
    
    var phone = formValues.phone;
    var phoneNumber = '';
    if (phone){
        //Split phone-number by space (Ex: +91 99651-48523)
        var spliltPhone = phone.split(' ');
        //Remove country code from the input to validate phone number
        spliltPhone.shift();
        //Join reamining numbers
        var phoneNumber = spliltPhone.join('');
        //Remove special charcters +-<space>
        const specialChars = {'+':'','(':'',')':'','-':''};
        phoneNumber = phoneNumber.replace(/[ )(+-]/g, s => specialChars[s]);
    }

    if (!formValues.first_name){
        errors.first_name = "First Name cannot be blank!"
    }
    else if (!regexName.test(formValues.first_name)) {
        errors.first_name = "First Name is invalid!";
    }
    if (!formValues.last_name){
        errors.last_name = "Last Name cannot be blank!";
    }
    else if (!regexName.test(formValues.last_name)) {
        errors.last_name = "Last Name is invalid!";
    }
    if (!formValues.email){
        errors.email = "Email cannot be blank!";
    }
    else if (!regexEmail.test(formValues.email)) {
        errors.email = "This is not a valid email!";
    }
    if (!formValues.phone || formValues.phone == countryCodeSelected || ( phoneNumber && phoneNumber.length == 0) ){
        errors.phone = "Phone cannot be blank!";
    }
    else if (phoneNumber.length != 0 && phoneNumber.length < 9){
        errors.phone = "Phone number is too short!"
    }
    if (!formValues.company_name){
        errors.company_name = "Company cannot be blank!";
    }
    else if (!regexcompany_name.test(formValues.company_name)) {
        errors.company_name = "Company Name is invalid";
    }
    if (!formValues.country_id){
        errors.country_id = "Country cannot be blank!";
    }
    if (!formValues.partner_policy){
        errors.partner_policy = "Please accept the partner policy!";
    }
    return errors;
}
