import { Form, Select, Spin, message, notification } from "antd";
import axios from "axios";
import { useCallback, useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiEndPoint,
  ApiCallErrorMessage,
  ApiHeaders,
} from "../../../../utils/commonutils";
import { EditSpecContext } from "./AddSpecification";

function SpecCategoryMapForm({
  setPageDetails,
  mappedCategories,
  setMappedCategories,
  specId,
}) {
  const { editSpecData, mode, specName } = useContext(EditSpecContext);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [spinning, setSpinning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [leafCategoryList, setCategoryList] = useState([]);
  const layout = {
    labelCol: {
      span: 10,
    },
    wrapperCol: {
      span: 12,
    },
  };
  let response = "";
  let apiCallErrorMessage = ApiCallErrorMessage();
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiHeaders = ApiHeaders(userToken);
  const OPTIONS = [];
  const { Option } = Select;

  useEffect(() => {
    async function listChildCategories() {
      let url = `category?leaf_node=true`;
      url = apiUrl + url;
      response = await axios(url, {
        method: "GET",
        data: "",
        headers: apiHeaders,
      })
        .then((response) => {
          if (response.success == true && response.status == 200) {
            const options = [];
            Object.values(response.data).map((type) => {
              options.push({
                label: type.id,
                value: type.name,
              });
            });
            setCategoryList(options);
            setLoading(true);
          } else {
            message.error(apiCallErrorMessage);
          }
        })
        .catch((error) => {});
    }
    listChildCategories();

    setPageDetails({
      pagename: "specification-map",
    });
    form.setFieldsValue({
      category_id: mappedCategories,
    });
  }, []);

  useEffect(() => {
    if (Object.keys(editSpecData).length > 0) {
      let editCategories = editSpecData.categories;
      let catOptions = [];
      Object.values(editCategories).map((data) => {
        OPTIONS.push(<Option key={data.id}>{data.name}</Option>);
      });
      setMappedCategories(catOptions);
    }
    form.setFieldsValue({
      category_id: OPTIONS,
    });
  }, [editSpecData]);

  function prepareCategoryIds(categories) {    
    let finalCatArray = [];
    let objCatArray = [];
    categories.map((data) => {
      if (typeof data == "object") {
        objCatArray.push(data.key);
      }
    });
    finalCatArray = objCatArray.length > 0 ? objCatArray : categories;
    return finalCatArray;
  }

  async function onFinish(values) {
    console.log(values.category_id);
    let categoryIds = prepareCategoryIds(values.category_id);
    setLoading(true);
    setSpinning(true);
    let url = "map-category-specification";
    url = apiUrl + url;
    let requestBody = {
      category_id: categoryIds,
      specification_id: specId,
    };
    response = await axios(url, {
      method: mode == "Edit" ? "PUT" : "POST",
      headers: apiHeaders,
      data: requestBody,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        setTimeout(() => {
          navigate("/admin/specifications/specifications-dashboard");
        }, 2000);
      } else if (response.status === 422) {
        setSpinning(false);
        let validationErrors = response.data;
        let msgHeading = response.message;
        let errors = [];
        let listErrors = [];
        Object.keys(validationErrors).map((key, value) => {
          validationErrors[key].map((val, ind) => {
            form.setFields([
              {
                name: key,
                errors: [val],
              },
            ]);
            errors.push([val]);
            listErrors = errors.map((err) => <li key={err}>{err}</li>);
          });
        });
        notification.error({
          duration: 5,
          message: `${msgHeading}`,
          description: <ul>{listErrors}</ul>,
          placement: "topRight",
        });
        setTimeout(() => {
          notification.destroy();
        }, 5000);
      } else {
        setSpinning(false);
        message.error(apiCallErrorMessage);
      }
    });
  }

  const onSelectChange = useCallback((value) => {
    setMappedCategories(value);
  });
  let leafCategoryControl;
  if (loading) {
    Object.values(leafCategoryList).map((data) => {
      OPTIONS.push(<Option key={data.label}>{data.value}</Option>);
    });
    leafCategoryControl = (
      <Select
        id="mapCatSelect"
        mode="multiple"
        style={{ width: "80%" }}
        name="category_id"
        placeholder="Select Categories"
        onChange={onSelectChange}
        value={mappedCategories}
      >
        {OPTIONS}
      </Select>
    );
  } else {
    leafCategoryControl = <Spin size="large" />;
  }

  return (
    <div style={{ minHeight: 250 }}>
      <Spin spinning={spinning}>
        <Form
          {...layout}
          form={form}
          name="map_specification_form"
          layout="horizontal"
          initialValues={{
            size: "small",
          }}
          style={{ paddingTop: 75 }}
          id="SaveSpecMap"
          onFinish={onFinish}
        >
          <Form.Item
            label={"Select Categories to Map with "+specName}
            name="category_id"
            rules={[
              {
                required: true,
                message:
                  "Specification must be mapped with atleast one category",
              },
            ]}
          >
            {leafCategoryControl}
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
}

export default SpecCategoryMapForm;
