import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import "./style.css";
import Select from "react-select";
import countryList from "react-select-country-list";

const CountrySelector = ({
  label,
  required,
  id,
  leftIcon,
  onChange,
  defaultValue,
  disabled,
  placeholder,
  Validation,
  ValidationMessage,
}) => {
  const options = countryList().getData();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const handleCountrySelect = (val) => {
    setSelectedCountry(val);
    onChange(val.value);
    setSelectedCountryCode(val.value);
  };

  useEffect(() => {
    const filteredSelectedCountries = options.filter(
      (country) => country.value === defaultValue
    );
    setSelectedCountry(filteredSelectedCountries);
    setSelectedCountryCode(defaultValue);
  }, [defaultValue]);

  const inputPadding = selectedCountry ? "28px" : "6px";

  return (
    <div className="mew-input-container">
      {label && (
        <label htmlFor={id}>
          {label}
          {required && <span className="text-danger">*</span>}
        </label>
      )}
      <div
        className={`mew-country-selector ${
          leftIcon && !selectedCountry ? "mew-left-icon" : ""
        }`}
      >
        {leftIcon && !selectedCountry && (
          <span className="mew-select-left-icon">
            <img src={leftIcon} alt="Country" />
          </span>
        )}
        <div className="mew-flag-img">
          <ReactCountryFlag
            countryCode={selectedCountryCode ? selectedCountryCode : null}
            style={{ width: "18px", height: "20px" }}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={selectedCountry ? selectedCountry : null}
          />
        </div>
        <div className="mew-country-select-container">
          <Select
            isSearchable={true}
            options={options}
            value={selectedCountry}
            defaultValue={defaultValue}
            isDisabled={disabled}
            placeholder={placeholder}
            onChange={handleCountrySelect}
            styles={{
              control: (base, state) => ({
                ...base,
                paddingLeft: leftIcon ? 38 : inputPadding,

                borderColor: state.isFocused ? "#40a9ff" : "#E2E8F0",
                boxShadow: state.isFocused
                  ? "0 0 0 2px rgba(24, 144, 255, 0.2)"
                  : "none",
                height: 42,
                paddingTop: 2,
                "&:hover": {
                  border: "1px solid #40a9ff",
                },
                cursor: "pointer",
              }),
              dropdownIndicator: (base) => ({
                ...base,
                display: "none",
              }),
              placeholder: (base) => ({
                ...base,
                color: "#A0AEC0",
              }),

              option: (provided, { isSelected, isFocused }) => ({
                ...provided,
                cursor: "pointer",
                fontWeight: isSelected ? "600" : "inherit",
                backgroundColor: isSelected
                  ? "#e6f7ff"
                  : isFocused
                  ? "#D8F2F0"
                  : "inherit",
                color: isSelected ? "rgba(0, 0, 0, 0.85)" : "inherit",
                "&:active": {
                  backgroundColor: isSelected ? "#e6f7ff" : "inherit",
                },
              }),
            }}
          />
        </div>
      </div>
      {Validation ? (
        <div className="mew-validationMessage">
          <span className="text-danger">{ValidationMessage}</span>
        </div>
      ) : null}
    </div>
  );
};

export default CountrySelector;
