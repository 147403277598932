import { Button, Checkbox, Form, Input, Select, Steps, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import CategoryMapForm from "./CategoryMapForm";
import MarketplaceForm from "./MarketplaceForm";

function AddMarketplaceCategory(props) {
  const navigate = useNavigate();
  const [mode, setMode] = useOutletContext();
  const [current, setCurrent] = useState(0);
  const [errorStatus, setErrorStatus] = useState(true);
  const [pageDetails, setPageDetails] = useState({
    pagename: "marketplace-category-add",
  });
  const [adsource, setAdsource] = useState();
  const [catName, setCatName] = useState("");
  const [catCode, setCatCode] = useState("");
  const [catPath, setCatPath] = useState("");
  const [mappedCategories, setMappedCategories] = useState([]);
  const steps = [
    {
      title: "Add Marketplace Category",
      content: (
        <MarketplaceForm
          pageDetails={pageDetails}
          setPageDetails={setPageDetails}
          setErrorStatus={setErrorStatus}
          setCurrent={setCurrent}
          adsource={adsource}
          setAdsource={setAdsource}
          catName={catName}
          setCatName={setCatName}
          catCode={catCode}
          setCatCode={setCatCode}
          catPath={catPath}
          setCatPath={setCatPath}
        />
      ),
    },
    {
      title: "Map with E-Comm Wizard Category",
      content: (
        <CategoryMapForm
          pageDetails={pageDetails}
          setPageDetails={setPageDetails}
          setErrorStatus={setErrorStatus}
          mappedCategories={mappedCategories}
          setMappedCategories={setMappedCategories}
        />
      ),
    },
  ];
  const next = () => {
    if (pageDetails.pagename == "specification-add") {
      document
        .getElementById("SaveMarketplaceCategory")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
    // if (!errorStatus) {
    //   setCurrent(current + 1);
    // }
  };
  const prev = () => {
    setCurrent(current - 1);
  };
  const onFinalSave = () => {
    if (pageDetails.pagename == "specification-map") {
      document
        .getElementById("SaveSpecMap")
        .dispatchEvent(new Event("submit", { bubbles: true }));
    }
  };
  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));
  const contentStyle = {
    color: "rgba(0, 0, 0, 0.45)",
    backgroundColor: "rgba(0, 0, 0, 0.02)",
    borderRadius: "8px",
    border: `1px dashed `,
    marginTop: 16,
  };
  useEffect(() => {
    setMode("Add");
  }, []);
  return (
    <>
      <div className="catalog">
        <Steps
          size="small"
          current={current}
          style={{ paddingLeft: 20, paddingTop: 30, borderColor: "#0d4835" }}
        >
          {items.map((item) => (
            <Steps.Step key={item.title} title={item.title} />
          ))}
        </Steps>
      </div>
      <div style={contentStyle}>{steps[current].content}</div>
      <div
        className="steps-action"
        style={{ float: "right", paddingBottom: 10 }}
      >
        {current > 0 && (
          <Button
            style={{
              margin: "0 8px",
              borderRadius: "15px",
            }}
            onClick={() => prev()}
          >
            Previous
          </Button>
        )}

        {current < steps.length - 1 && (
          <Button
            type="primary"
            style={{
              borderRadius: "15px",
            }}
            onClick={() => next()}
            className="bg-primary-color"
          >
            Next
          </Button>
        )}
        {current === steps.length - 1 && (
          <Button
            type="primary"
            onClick={() => onFinalSave()}
            style={{
              borderRadius: "15px",
            }}
            className="bg-primary-color"
          >
            Done
          </Button>
        )}
      </div>
    </>
  );
}

export default AddMarketplaceCategory;
