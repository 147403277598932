

function Footer() {
  return (
      <div className="footer text-center mb-2 mt-3">
        <span className="pb-3">
          Developed by{" "}
          <a href="https://www.digitalmesh.com/" target="_blank" title="Digital Mesh Softech India P Limited">
          Digital Mesh Softech India P Limited
          </a>
        </span>
      </div>
  );
}

export default Footer;
