
import Header from "./Header";
import Footer from "./Footer";
import Sidebar from "./Sidebar";
import { Outlet } from "react-router-dom";

function MainLayout() {
  return (
    <div>
      <Header />
      <Sidebar />
        <div className="main-content">
          <div className="container">
            <Outlet />
          </div>
        </div>
      <Footer />
    </div>
  );
}

export default MainLayout;
