import { useEffect, useState } from "react";
import { validate } from "./RegisterPartnerValidations";
import * as countriesAndTimezones from "countries-and-timezones";
import { ApiEndPoint, ApiHeaders } from "../../../../utils/commonutils";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { message } from "antd";

const AddPartnerForm = () => {
  const [values, setValues] = useState({
    emailVerified: true,
  });
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [countryData, setCountryData] = useState({});
  const [timezones, setTimezones] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const navigate = useNavigate();
  let apiUrl = ApiEndPoint();
  let headers = ApiHeaders();

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit) {
      const requestBody = {
        first_name: values.firstName,
        last_name: values.lastName,
        email: values.email,
        phone: values.phoneNumber,
        company_name: values.company,
        country_id: values.country,
        timezone: values.timezone
      };
      registerPartner();
      async function registerPartner() {
        setSpinning(true);
        const url = apiUrl + "partner/register";
        const response = await axios(url, {
          method: "POST",
          data: requestBody,
          headers: headers,
        }).then((response) => {
          if (response.success === true && response.status === 200) {
            message.success(response.message);
            setTimeout(() => {
              navigate("/admin/manage-partners");
            }, 2000);            
          } else {
            setSpinning(false);
            message.error(response.message);
          }
        });
      }
    }
  }, [errors]);

  const handleSubmit = (event) => {
    if (event) event.preventDefault();
    setErrors(validate(values));
    setIsSubmit(true);
  };

  const handleChange = (event) => {
    setIsSubmit(false);
    const newValues = { ...values, [event.target.name]: event.target.value };
    setValues(newValues);
  };

  const handleCountry = (countryCode) => {
    if (countryCode) {
      values.country_code = countryCode.toLowerCase();
      const countryObj = {
        target: { id: "phoneNumber", name: "country", value: countryCode },
      };
      handleChange(countryObj);
      const countryInfo = countriesAndTimezones.getCountry(countryCode);
      const countryTimezones = countryInfo ? countryInfo.timezones : [];
      setTimezones(countryTimezones);
    } else {
      const newValues = { ...values, ["country"]: "" };
      setValues(newValues);
    }
  };

  const handlePhone = (value, country, e) => {
    handleChange(e);
  };

  const handleTimezoneChange = (event) => {
    if (event) {
      const newValues = { ...values, ["timezone"]: event };
      setValues(newValues);
    } else {
      const newValues = { ...values, ["timezone"]: "" };
      setValues(newValues);
    }
  };

  return {
    handleChange,
    handleSubmit,
    handlePhone,
    handleCountry,
    handleTimezoneChange,
    values,
    errors,
    disabled,
    countryData,
    timezones,
    spinning,
  };
};
export default AddPartnerForm;
