import { Space, Input, Button, message, Popconfirm } from "antd";
import { DataTable } from "../../../common/Table";
import {
  SearchOutlined,
  ClearOutlined,
  ApiFilled,
  EditFilled,
  DeleteFilled,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useEffect, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import {
  ApiEndPoint,
  ApiHeaders,
  ApiCallErrorMessage,
} from "../../../../utils/commonutils";
import axios from "axios";

function SpecificationDashboard(props) {
  const [loading, setLoading] = useState(false);
  const [searchedColumnText, setSearchedColumnText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [mode, setMode] = useOutletContext();
  const [specData, setSpecData] = useState([]);
  const [pageDetails, setPageDetails] = useState({
    pagename: "specification-map",
  });
  const navigate = useNavigate();

  let response = "";
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiHeaders = ApiHeaders(userToken);
  let apiUrl = ApiEndPoint();
  let apiCallErrorMessage = ApiCallErrorMessage();

  useEffect(() => {
    setMode("");
    getSpecData();
  }, []);

  async function removeSpecification(record) {
    let url = "specification/" + record.id;
    url = apiUrl + url;
    response = await axios(url, {
      method: "DELETE",
      data: "",
      headers: apiHeaders,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        const filteredData = specData.filter((spec) => spec.id !== record.id);
        setSpecData(filteredData);
      } else {
        message.error(apiCallErrorMessage);
      }
    });
  }

  const editSpecification = (record) => {
    setMode("Edit");
    navigate("/admin/specifications/manage-specifications", { state: record });
  };

  async function getSpecData() {
    let url = "specification";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: apiHeaders,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setSpecData(response.data);
      } else {
        message.error(apiCallErrorMessage);
        message.destroy();
      }
    });
  }

  /* Search */
  const getColumnSearchProps = (dataIndex, title) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${title}`}
          value={selectedKeys[0]}
          autoFocus
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() =>
            handleSearchColumn(selectedKeys, confirm, dataIndex)
          }
          style={{ width: 188, marginBottom: 0, display: "block" }}
        />
        <Space style={{ marginTop: 5 }}>
          <Button
            type="primary"
            onClick={() => handleSearchColumn(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            type="danger"
            onClick={() => handleResetColumn(clearFilters, confirm)}
            icon={<ClearOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[searchedColumnText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });
  const tableHeading = [
    {
      title: "Spec-ID",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
      ...getColumnSearchProps("id", "id"),
    },
    {
      title: "Name",
      dataIndex: "name",
      ...getColumnSearchProps("name", "Specification"),
    },
    {
      title: "Required?",
      dataIndex: "required",
      render: (value, record) => {
        return record.is_required == 1 ? "Yes" : "No";
      },
      filters: [
        { text: "Yes", value: 1 },
        { text: "No", value: 0 },
      ],
      onFilter: (value, record) => {
        return record.is_required === value;
      },
    },
    {
      title: "Field-Type",
      dataIndex: "fildType",
      render: (value, record) => {
        return record.field_type == "single" ? "Single" : "Multiple";
      },
      filters: [
        { text: "Single", value: "single" },
        { text: "Multiple", value: "multi" },
      ],
      onFilter: (value, record) => {
        return record.field_type === value;
      },
    },
    {
      title: "Values",
      dataIndex: "values",
      render: (value, record) => {
        let specValues = record.values;
        return specValues.length ? specValues.join(", ") : "-";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      align: "center",
      render: (_, record) => {
        return specData.length ? (
          <Space>
            <Button
              key={"edit_" + record.id}
              title="Edit Specification details"
              icon={<EditFilled />}
              onClick={() => editSpecification(record)}
              className="text-primary-color ant-btn-circle text-primary"
            />
            <Popconfirm
              title={"Remove " + record.name + "?"}
              okText="Yes"
              cancelText="No"
              onConfirm={() => removeSpecification(record)}
            >
              <Button
                key={"delete_" + record.id}
                title="Delete Specification"
                icon={<DeleteFilled />}
                className="text-primary-color ant-btn-circle text-danger"
              />
            </Popconfirm>
          </Space>
        ) : null;
      },
    },
  ];
  const handleSearchColumn = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumnText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleResetColumn = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchedColumnText("");
    setSearchedColumn("");
  };

  return (
    <div className="container">
      <div className="border-info card mb-4 w-100">
        <div className="table-content">
          <div className="container">
            <div className="mt-3 mb-3 table-wrapper w-100 overflow-auto mt-1">
              <DataTable
                columns={tableHeading}
                data={specData}
                bordered
                loading={loading}
                pagination={{
                  position: ["bottomCenter"],
                  defaultPageSize: 100,
                  showSizeChanger: true,
                  showQuickJumper: true,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    /* Map spec with categories modal */
  );
}

export default SpecificationDashboard;
