export const validate = (formValues) => {
  let errors = {};
  const regexName = /^[^-\s][a-zA-Z_\s-]+$/;
  const amount = /^[0-9.]+$/;
  const regexTrialDuration = /^[1-9]\d*$/;
  if (!formValues.name) {
    errors.name = "Subscription cannot be blank!";
  }
  if (!formValues.duration) {
    errors.duration = "Subscription Duration cannot be blank!";
  }
  if (!formValues.amount) {
    errors.amount = "Subscription Amount cannot be blank!";
  } else if (!amount.test(formValues.amount)) {
    errors.amount = "Subscription Amount is invalid!";
  }
  if (!formValues.no_of_products) {
    errors.no_of_products = "Number of Products cannot be blank!";
  } else if (!amount.test(formValues.no_of_products)) {
    errors.no_of_products = "Number of Products is invalid!";
  }
  if (!formValues.item_inventory_update_frequency) {
    errors.item_inventory_update_frequency = "Item inventory update frequency cannot be blank!";
  } else if (!amount.test(formValues.item_inventory_update_frequency)) {
    errors.item_inventory_update_frequency = "Item inventory update frequency is invalid!";
  }
  if (!formValues.item_update_frequency) {
    errors.item_update_frequency = "Item update frequency cannot be blank!";
  } else if (!amount.test(formValues.item_update_frequency)) {
    errors.item_update_frequency = "Item update frequency is invalid!";
  }
  if (!formValues.order_fetch_frequency) {
    errors.order_fetch_frequency = "Order fetch frequency cannot be blank!";
  } else if (!amount.test(formValues.order_fetch_frequency)) {
    errors.order_fetch_frequency = "Order fetch frequency is invalid!";
  }
  if (!formValues.order_update_frequency) {
    errors.order_update_frequency = "Order update frequency cannot be blank!";
  } else if (!amount.test(formValues.order_update_frequency)) {
    errors.order_update_frequency = "Order update frequency is invalid!";
  }
  if (
    formValues.trial_period &&
    (formValues.trial_duration == "" || formValues.trial_duration == null)
  ) {
    errors.trial_duration = "Trial Duration cannot be blank!";
  } else if (
    formValues.trial_duration &&
    !regexTrialDuration.test(formValues.trial_duration)
  ) {
    errors.trial_duration = "Please enter a whole number!";
  }
  return errors;
};
