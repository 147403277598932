import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";

function PageHeaderBreadCrumb(props) {
  const navigate = useNavigate();
  const breadCrumbs = props.breadcrumbsData;
  const handleRedirect = (event, pageUrl) => {  
    event.preventDefault();
    navigate(pageUrl);
  }
  return (
    <div>
      <div className="container">
        <h1 className="h4 mb-0 mt-4 mb-2 text-primary-color">
          {props.pageTitle}
        </h1>
      </div>
      <div className="container pb-3">
        <Breadcrumb className="text-primary-color">
          {breadCrumbs.map((data, row) => {
            return (
              <Breadcrumb.Item className={data.className} key={row}>
                {data.pageUrl ? (
                  <a href="" name={data.pageName} pagelink={data.pageUrl} onClick={(event) => handleRedirect(event, data.pageUrl)}>
                  {" "}
                  {data.pageName}{" "}
                </a>
                ) : (
                  data.pageName
                )}
              </Breadcrumb.Item>
            );
          })}
        </Breadcrumb>
      </div>
    </div>
  );
}
export default PageHeaderBreadCrumb;
