export const validate = (feature) => {
    let featureErrors = {};
    const regexFeature = /^[a-zA-Z0-9][a-zA-Z0-9@#$&%()\-.+,"'_ ]*$/;

    if (!feature){
        featureErrors.error = "Subscription feature cannot be blank!";
    }
    else if (!regexFeature.test(feature)) {
        featureErrors.error = "Please enter a valid Subscription feature!";
    }
    else if (feature.length > 50){
        featureErrors.error = "Subscription feature must not be greater than 50 characters!";
    }
    return featureErrors;
}