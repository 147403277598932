import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import { ArrowUpOutlined } from "@ant-design/icons";
import Icons from "../../../assets/icons";

function SubscriptionOverview(props) {
  const { user } = useSelector((state) => state.user);
  const [subscriptionData, setSubscriptionData] = useState([]);
  const navigate = useNavigate();

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  /* Service call to retrieve Subscription details */
  useEffect(() => {
    function getSubscriptionData() {
      let url = "partner-subscription";
      url = apiUrl + url;
      response = axios(url, {
        method: "GET",
        data: "",
        headers: headers,
      })
        .then((response) => {
          if (response.success == true && response.status === 200) {
            var subscriptionArray = [];
            var subscriptionObj = response.data[0];
            Object.keys(subscriptionObj).forEach(function (key, index) {
              var countObj = subscriptionObj[key];
              subscriptionArray.push({
                subcription: key,
                subcription_count: countObj.subscription_count,
              });
            });
            setSubscriptionData(subscriptionArray);
          } else if (response.status == 401) {
            Notification({
              type: "error",
              message: "Sorry , Your session has been expired!",
            });
          }
        })
        .catch((error) => {
        });
    }
    getSubscriptionData();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="row card-listing">
            {subscriptionData.map((data, rowIndex) => {
              return (
                <div className="col-6 col-sm" key={rowIndex}>
                  <div className="container my-2 my-md-0">
                    <div className="row card py-3 px-1">
                      <div className="col-12 d-flex flex-column d-sm-block align-items-center text-center">
                        <p className="text-bold text-primary-color h5 text-uppercase">{data.subcription}</p>
                        <h4 className="text-bold"> {data.subcription_count}</h4>
                      </div>
                        <div className="text-center">
                          <span className="small text-primary-color m-0 pl-2">
                          <img src={Icons.upIcon} alt="" />
                          +1.01% this week
                        </span>
                        </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscriptionOverview;
