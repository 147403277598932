import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { Breadcrumb, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import SubscriptionOverview from "../subscription/SubscriptionOverview";
import PaymentSummary from "./PaymentSummary";

function PaymentDashboard() {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();

  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";

  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

  /* Service call to retrieve markerplaces */
  useEffect(() => {
    //setLoading(true);
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-12 col-sm-12 col-md-12 p-0">
            <h4 className="ml-2 mb-0 pt-3 text-primary-color">
              Payment Dashboard
            </h4>
          </div>
          <div className="col-12 col-md-7 col-xl-4">
            <Breadcrumb className="text-primary-color">
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>
                <a href="manage-partners">Payment Dashboard</a>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
            <div className="d-flex align-items-center justify-content-start justify-content-md-end mt-3">
              <Button className="bg-primary-color ml-2 mb-2 btn" title="Export">
                Export
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="card border-info p-2">
        <SubscriptionOverview size="small" />
      </div>

      <div className="border-info card mb-4 mt-3 w-100">
        <div className="table-content">
          <PaymentSummary pagination={true} />
        </div>
      </div>
    </div>
  );
}

export default PaymentDashboard;
