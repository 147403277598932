import { useState, useEffect } from "react";
import { validate } from "./UserRegisterValidation";

const UserRegisterForm = (callBack) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmit  ) {
      callBack();
    }
  }, [errors, callBack, isSubmit, values]);
  const handleSubmit = (event) => {
    event.preventDefault();
    setErrors(validate(values));
    setIsSubmit(true);
  };
  const handleChange = (event) => {
    event.persist();
    setIsSubmit(false);
    const newValues = { ...values, [event.target.name]: event.target.value };
    setErrors(validate(newValues));
    setValues(newValues);
  };

  return {
    handleChange,
    handleSubmit,
    values,
    errors
  };
};
export default UserRegisterForm;
