
function PageNotFound(props) {
  return (
    <div>
      <p>Page Not Found</p>
    </div>
  );
}

export default PageNotFound;
