import axios from "axios";
import { useState, useEffect, useCallback, useRef } from "react";
import Select from "react-select";
import { GetAxiosCall,ApiEndPoint,getApiKey } from "../../utils/commonutils";
export default function GetCountryList(props) {
  const [select, setSelect] = useState("");
  const [stateResult, setStateResult] = useState("");
  const selectRef = useRef();
  let response = "";
  let apiUrl = ApiEndPoint();
  useEffect(() => {
    selectRef.current.addEventListener("change", props.onChange);
  }, [props.onChange]);
  let countryCode = props.countryCode ? props.countryCode : "";
  let url = "country/getCountry";
  //axios initialization started
  url = countryCode ? url + countryCode : url;
  let header = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
  };
  let body = "";
  let baseUrl = apiUrl + url;
  //axios initialization ended
  useEffect(() => {
    //response = GetAxiosCall("GET", url, "", countryCode);
    //axios call to server
    async function fetchData() {
      response = await axios(baseUrl, {
        method: "GET",
        data: body,
        headers: header,
      }).then((response) => {
        setStateResult(response);
      });
    }
    fetchData();
  }, []);
  let options = [{ label: "Select Country", value: "",key:1 }];
  if (stateResult && stateResult.success == true) {
    let i = 1;
    stateResult.data.map((response) => {
      i++;
      let country = {
        label: response.country_name,
        value: response.country_id,
        country_code: response.country_code.toLowerCase(),
        key:i
      };
      options.push(country);
    });
  }
  return (
    <select
      ref={selectRef}
      value={props.value}
      name={props.name}
      onChange={props.handleChange}
      disabled={props.disabled}
      defaultValue={props.defaultValue}
      style={props.style}
    >
      {options.map((option) => (
        <option key={option.key} value={option.value} country_code={option.country_code}>
          {option.label}
        </option>
    ))}
    </select>
  );
}
