export const validate = (formValues) => {
    let errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!formValues.userName) {
        errors.userName = "Username is required!";
    } else if (!regexEmail.test(formValues.userName)) {
        errors.userName = "Invalid Username";
    }
    if (!formValues.password) {
        errors.password = 'Password is required!';
    } 
    // else if (formValues.password.length < 8) {
    //     errors.password = 'Password must be 8 or more characters';
    // }
    return errors
};