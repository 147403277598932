import React from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Form, Button, Select, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

function DynamicField({ values, handleChange }) {
    return (
        <Form.List name="fields">
            {(fields, { add, remove }) => {
                return (
                    <div>
                        {fields.map((field, index) => (
                            <div key={field.key}>
                                <Form.Item
                                    label="Feature"
                                    rules={[{ required: false }]}
                                >
                                    <TextArea name={"features" + field.key}
                                        value={values.subscription_Feature}
                                        onChange={(event) => { handleChange(null, event) }}
                                        style={{ width: "90%" }}
                                        placeholder="Feature" />
                                    {fields.length >= 1 ? (
                                        <Button
                                            type="danger"
                                            size="small"
                                            className="dynamic-delete-button"
                                            style={{ paddingLeft: "5px !important",margin:"0 3px",borderRadius: "25px" }}
                                            onClick={() => remove(field.name)}
                                            icon={<MinusCircleOutlined />}
                                            placeholder="Remove Feature"
                                        >
                                        </Button>
                                    ) : null}
                                </Form.Item>
                            </div>
                        ))}
                        <Form.Item>
                            <Button
                                type="dashed"
                                onClick={() => add()}
                                style={{ width: "75%" }}
                                placeholder="Add Feature"
                            >
                                <PlusOutlined /> Add Feature
                            </Button>
                        </Form.Item>
                    </div>
                );
            }}
        </Form.List>
    );
}

export default DynamicField;
