import axios from "axios";
import { data } from "autoprefixer";
import Icons from "../assets/icons/index";
const apiKey = "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9";
const apiCallErrorMessage = "Oops! Something went wrong, please try again";

export function ApiEndPoint() {
 //const url = "http://localhost:8000/api/v1/";
 const url = "https://admin-api.myecomwizard.com/api/v1/";
 //const url = "http://api22.digitalmesh.co.in/api/v1/";
 //const url = "https://admin.digitalmesh.co.in/api/v1/";
  return url;
}
export function getApiKey() {
  return apiKey;
}
export function ApiCallErrorMessage() {
  return apiCallErrorMessage;
}
export function ApiHeaders(token, accept="application/json", contentType="application/json"){
  const headers = {
    Accept: accept,
    "Content-Type": contentType,
    "Api-key": apiKey,
    Authorization: `Bearer ${token}`,
  }
  return headers;
}
const APICall = async (method, url, body, params, authCode) => {
  url = params ? url + params : url;
  let baseUrl = "http://localhost:8000/api/v1/" + url;
  let header = authHeader(authCode);
  const result = await axios(baseUrl, {
    method: method,
    data: body,
    headers: header,
  });
  return result;
};
const authHeader = (authCode) => {
  if (authCode) {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
      Authorization: `Bearer ${authCode}`,
    };
  } else {
    return {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Api-key": "DM-a0e381ee-deb5-428c-8eb3-67d4399eeda9",
    };
  }
};

export function GetAxiosCall(method, url, body, params, authCode) {
  const response = APICall(method, url, body, params, authCode);
  const ParseResponse = response.then((response) => response);
  console.log(ParseResponse);
}
export function PostAxiosCall(method, url, body, params, authCode) {
  const response = APICall(method, url, body, params, authCode);
  console.log(response);
}

/***
 * Function to check valid profile logo of partner
 * If not a valid image, default profile icon is returned
 */
export function checkValidProfileLogo(logoUrl) {
  let profileImage = Icons.accountIcon;
  if (logoUrl) {
    //define image formats
    var supportedTypes = ["png", "jpeg", "jpg"];
    //split the url into parts  that has dots before them
    var parts = logoUrl.split(".");
    //get the last part
    var fileExtension = parts[parts.length - 1];
    //check if extension matches with the supported image type
    if (supportedTypes.indexOf(fileExtension) !== -1) {
      profileImage = logoUrl;
    }
  }
  return profileImage;
}
