import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Tabs,
} from "antd";
import { UserOutlined, SafetyOutlined, PlusOutlined, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import ManageRoles from "./ManageRoles";
import ManagePermissions from "./ManagePermissions";
import PageHeaderBreadCrumb from "../../common/breadcrumb/PageHeaderBreadCrumb";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiCallErrorMessage,
  ApiEndPoint,
  ApiHeaders,
} from "../../../utils/commonutils";
import axios from "axios";

function ManageUsers(props) {
  const [showModal, setShowModal] = useState(false);
  const [newOption, setNewOption] = useState("Role");
  const [serverErrors, setServerErrors] = useState([]);
  const userToken = localStorage.getItem("Access-Token") || "";
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const handleModalCancel = () => {
    setShowModal(false);
    setServerErrors([]);
    /* Clear form field */
    clearFormField();
  };

  const clearFormField = () => {
    form.setFields([
      {
        name: "name",
        value: ""
      }
    ]);
  }

  const onFinish = async (values) => {    
    const optionUrl = newOption == "Role" ? "admin/roles" : "admin/permissions";
    const url = ApiEndPoint() + optionUrl;
    const response = await axios(url, {
      method: "post",
      data: values,
      headers: ApiHeaders(userToken),
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        setServerErrors([]);
        clearFormField();
        setShowModal(false);
        message.success(response.message);
        setTimeout(() => {
          navigate("/admin/manage-users");
        }, 1000);
      } else if (response.status === 422) {        
        form.setFields([
          {
            name: "name",
            errors: [response.data.name]
          }
        ]);
      } else {
        message.error(ApiCallErrorMessage());
      }
    });
  };

  const handleNew = (e) => {
    setShowModal(true);
    setNewOption(e.key == 1 ? "Role" : "Permission");
    setActiveTab(e.key);
  };

  const newOptions = [
    {
      key: "1",
      icon: <UserOutlined />,
      label: "Role",
    },
    {
      key: "2",
      icon: <SafetyOutlined />,
      label: "Permission",
    },
  ];

  const dropDownMenu = <Menu onClick={handleNew} items={newOptions}></Menu>;

  const breadcrumbsData = [
    {
      name: "home",
      pageName: "Home",
      pageUrl: "/dashboard",
      className: "text-primary-color",
    },
    {
      name: "ManageCategory",
      pageName: "Manage Users",
      pageUrl: "/admin/manage-users",
      className: "active-link",
    },
  ];

  return (
    <>
      <div className="catalog">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 col-md-7 col-xl-4">
              <PageHeaderBreadCrumb
                pageTitle="Manage Users"
                breadcrumbsData={breadcrumbsData}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Tabs
          className="container"
          activeKey={activeTab}
          defaultActiveKey="1"
          onTabClick={(value) => setActiveTab(value)}
          tabBarExtraContent={
            <Dropdown overlay={dropDownMenu}>
              <Button className="bg-primary-color" icon={<PlusOutlined />}>
                Add New
              </Button>
            </Dropdown>
          }
          items={[UserOutlined, SafetyOutlined].map((Icon, i) => {
            const id = String(i + 1);
            const tabName = id == 1 ? "Manage Roles" : "Manage Permissions";
            return {
              label: (
                <span>
                  <Icon />
                  {tabName}
                </span>
              ),
              key: id,
              children: id == 1 ? <ManageRoles /> : <ManagePermissions />,
            };
          })}
        />
      </div>

      <Modal
        title={"Add " + newOption}
        name="rolePermission"
        okText="Save"
        onCancel={handleModalCancel}
        open={showModal}
        destroyOnClose={true}
        footer={[
          <>
            <Button
              title="Cancel"
              form="rolePermissionForm"
              icon={<SaveOutlined />}
              key="cancel"
              className="bg-secondary-color"
              onClick={() => handleModalCancel()}
            >
              Cancel
            </Button>
            <Button
              title="Save"
              form="rolePermissionForm"
              icon={<CloseOutlined />}
              key="save"
              htmlType="submit"
              className="bg-primary-color"
            >
              Save
            </Button>
          </>,
        ]}
      >
        {serverErrors.length > 0 ? (
          <ul className="pb-2 text-danger">
            {serverErrors.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
        ) : null}
        <Form
          form={form}
          name="rolePermissionForm"
          labelCol={{
            span: 7,
          }}
          wrapperCol={{
            span: 20,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            key={"serviceLevelForm"}
            label={newOption}
            name="name"
            rules={[
              {
                required: true,
                message: `${newOption} cannot be blank!`,
              },
            ]}
          >
            <Input
              key={{ newOption }}
              value={null}
              placeholder={"Enter " + newOption}
              style={{
                width: 200,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ManageUsers;
