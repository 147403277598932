import {
  Avatar,
  Card,
  Checkbox,
  Col,
  Empty,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Image
} from "antd";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApiEndPoint,getApiKey } from "../../../utils/commonutils";
import store from "../../../data/store";
import { setUserDetails } from "../../../features/userSlice";
import Meta from "antd/lib/card/Meta";
function MapMarketplaces({ marketplacesMapped, setMarketplacesMapped}) {
  //initialize variables and contants
  //const [selectedItems, setSelectedItems] = useState([]);
  const navigate = useNavigate();
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let response = "";
  const OPTIONS = [];
  const { Option } = Select;
  const [isLoading, setLoading] = useState(false);
  const [marketplaceList, setMarketplaceList] = useState([]);
  
  /* Setting header */
  var headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    "Api-key": getApiKey(),
    Authorization: `Bearer ${userToken}`,
  };

 
  //initialization section ended
  //Triggers when the page is loaded.
  useEffect(() => {
    //call to get available marketplaces
    getMarketplaceDetails();
    
  }, []);
  //service call to get available marketplaces
  async function getMarketplaceDetails() {
    let url = "marketplace";
    url = apiUrl + url;
    response = await axios(url, {
      method: "GET",
      data: "",
      headers: headers,
    }).then((response) => {
      if (response.success == true && response.status == 200) {
        setLoading(true);
        setMarketplaceList(response.data);
      }
    });
  }


  //hangles the select of marketplaces
  const setSelectedItems = useCallback((checkedValues) => {
    setMarketplacesMapped(checkedValues);
  });
  useEffect(() => {
    setLoading(false);
  }, [marketplaceList]);
  let i = 1;
  if (Object.keys(marketplaceList).length) {
    return (
      <Spin size="large" spinning={isLoading}>
        <Checkbox.Group
          style={{
            width: "100%",
          }}
          value={marketplacesMapped}
          onChange={setSelectedItems}
          defaultValue={[4, 3]}
        >
          <Row>
            {Object.values(marketplaceList).map((type) => {
              i++;
              return (
                <Col className="p-1" key={`marketplace/${i}Col`} xs={12} xl={8}>
                  <Card
                    style={{
                      maxWidth: 550,
                      maxHeight: 250,
                      overflowX: "auto",
                      overflowY: "auto",
                      minHeight: 183,
                    }}
                    title={[
                      <Space align="center">
                        <Checkbox
                          key={`marketplace/${i}checkBox`}
                          value={type.id}
                        />
                        {type.name}
                      </Space>,
                    ]}
                    key={`marketplace/${i}tiles`}
                    className="card p-3"
                  >
                    <Skeleton
                      loading={isLoading}
                      key={`marketplace/${i}skelton`}
                      avatar
                      active
                    >
                      <Meta
                        avatar={
                          type.logo ? (
                            // <Avatar
                            //   src={type.logo}
                            //   shape={"square"}
                            //   style={{ width: 155, height: 50 }}
                            //   key={`marketplace/${i}img`}
                            // />
                            <Image 
                            src={type.logo}
                            className="ml-4 w-50 rounded"
                            width={100}
                            >
                          </Image>
                          ) : (
                            ""
                          )
                        }
                        description={type.description ? type.description : ""}
                        key={`marketplace/${i}meta`}
                      />
                    </Skeleton>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Checkbox.Group>
      </Spin>
    );
  } else {
    return <Empty />;
  }
}

export default MapMarketplaces;
