import {
  configureStore,
  applyMiddleware
} from "@reduxjs/toolkit";
import userReducer from "../features/userSlice";
import partnerReducer from "../features/partnerSlice";
import authReducer from "../features/auth/authSlice";
import { authApi } from "../services/authService";
import thunk from "redux-thunk";
export default configureStore(
  {
    reducer: {
      user: userReducer,
      partner: partnerReducer,
      auth: authReducer,
      [authApi.reducerPath]: authApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(authApi.middleware),
  }
);
