import { Button, Result } from "antd";
import { useSelector } from "react-redux";
import { NavLink, Outlet } from "react-router-dom";
import Header from "../layouts/Header";

const ProtectedRoute = () => {
  const { auth } = useSelector((state) => state);
  let user = auth.userToken ? true : false;
  // show unauthorized screen if no user is found in redux store
  if (!user) {
    return (
      <>
      <Header/>
      <Result
        status="403"
        title="Session Expired !"
        subTitle={`Please login to gain access.`}
        extra={
          <Button href="/" type="primary">
            Login
          </Button>
        }
      />
      </>
    );
  }
  // returns child route elements
  return <Outlet />;
};
export default ProtectedRoute;
