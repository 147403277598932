import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  notification,
  Select,
} from "antd";
import { useCallback, useEffect, useState, useContext } from "react";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { ApiEndPoint, ApiHeaders } from "../../../../utils/commonutils";
import axios from "axios";
import { EditSpecContext } from "./AddSpecification";

function SpecificationForm({
  setPageDetails,
  setErrorStatus,
  setCurrent,
  checked,
  setChecked,
  valueType,
  setValueType,
  specName,
  setSpecName,
  setSpecId,
}) {
  /* Get edit-spec-data from Context */
  const { editSpecData, mode } = useContext(EditSpecContext);
  let response = "";
  let apiUrl = ApiEndPoint();
  let token = localStorage.getItem("Access-Token");
  let userToken = token ? token : "";
  let apiHeaders = ApiHeaders(userToken);
  const [form] = Form.useForm();
  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  var specUrl = "specification";
  var apiMethod = "POST";
  var spec_name = specName;
  var spec_field_type = valueType;
  var spec_required = checked;
  //Determine create or update based on edit data and mode passed in context
  if (Object.keys(editSpecData).length > 0 && mode == 'Edit') {
    specUrl = "specification/" + editSpecData.id;
    apiMethod = "PUT";
    spec_name = editSpecData.name;
    spec_field_type = editSpecData.field_type;
    spec_required = editSpecData.spec_required;
  }

  useEffect(() => {
    setPageDetails({
      pagename: "specification-add",
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: spec_name,
      field_type: spec_field_type,
      is_required: spec_required,
      values: editSpecData.values,
    });
    setValueType(spec_field_type);
  }, [editSpecData]);

  const onFinish = (values) => {
    saveSpecifications(values);
  };
  async function saveSpecifications(values) {
    let url = apiUrl + specUrl;
    response = await axios(url, {
      method: apiMethod,
      headers: apiHeaders,
      data: values,
    }).then((response) => {
      if (response.success === true && response.status === 200) {
        message.success(response.message);
        setCurrent(1);
        setSpecId(response.data.id);
        setErrorStatus(false);
      } else if (response.status === 422) {
        let validationErrors = response.data;
        let msgHeading = response.message;
        let errors = [];
        let listErrors = [];
        Object.keys(validationErrors).map((key, value) => {
          validationErrors[key].map((val, ind) => {
            form.setFields([
              {
                name: key,
                errors: [val],
              },
            ]);
            errors.push([val]);
            listErrors = errors.map((err) => <li key={err}>{err}</li>);
          });
        });
        notification.error({
          duration: 5,
          message: `${msgHeading}`,
          description: <ul>{listErrors}</ul>,
          placement: "topRight",
        });
        setTimeout(() => {
          notification.destroy();
        }, 5000);
      } else {
        message.error("Oops! Something went wrong, please try again");
      }
      message.destroy();
    });
  }
  const onChange = (e) => {
    setChecked(e.target.checked);
  };
  const onSelectChange = useCallback((value) => {
    setValueType(value);
    setErrorStatus(true);
  });
  const onInputChange = useCallback((event) => {
    setErrorStatus(true);
    setSpecName(event.target.value);
  });
  const handleFailure = useCallback(() => {
    setErrorStatus(true);
  });

  function renderMultipleSpecValues() {
    return (
      <Form.List
        name="values"
        wrapperCol={{
          offset: 2,
          span: 8,
        }}
        rules={[
          {
            validator: async (_, values) => {
              if (!values || values.length == 0) {
                return Promise.reject(
                  new Error("Atleast one Specification value is required")
                );
              }
            },
          },
        ]}
      >
        {(values, { add, remove }, { errors }) => (
          <>
            {values.map((field, index) => (
              <Form.Item label={"Specification Value"} key={field.key}>
                <Form.Item
                  {...field}
                  validateTrigger={["onChange", "onBlur"]}
                  rules={[
                    {
                      required: true,
                      message: "Specification value cannot be blank",
                    },
                    {
                      pattern: /^[a-zA-Z_\s-]+$/,
                      message: "Specification Name can only include letters",
                    },
                  ]}
                  noStyle
                >
                  <Input
                    placeholder="Specification Value"
                    className="specValueText"
                    maxLength={20}
                    wrappercol={{
                      offset: 8,
                      span: 8,
                    }}
                    style={{ display: "inline-block", width: "60%" }}
                  />
                </Form.Item>
                {values.length > 1 ? (
                  <MinusCircleOutlined
                    title="Remove"
                    className="dynamic-delete-button pl-2"
                    onClick={() => remove(field.name)}
                  />
                ) : null}
              </Form.Item>
            ))}
            <Form.Item
              wrapperCol={{
                offset: 8,
                span: 8,
              }}
            >
              <Button
                id="addBtn"
                className="bg-primary-color"
                onClick={() => add()}
                title="Add Specification Value"
                style={{ width: "20%" }}
                placeholder="Add Values"
              >
                <PlusOutlined /> Add
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
          </>
        )}
      </Form.List>
    );
  }

  return (
    <Form
      {...layout}
      id="SaveSpecifications"
      form={form}
      layout="horizontal"
      initialValues={{
        size: "small",
      }}
      style={{ paddingTop: 50 }}
      onFinish={onFinish}
      labelCol={{
        span: 8,
      }}
      wrapperCol={{
        span: 8,
      }}
      onFinishFailed={handleFailure}
    >
      <Form.Item
        label="Specification Name"
        name="name"
        rules={[
          {
            required: true,
            message: "Specification Name is required",
          },
          {
            pattern: /^[a-zA-Z_\s-]+$/,
            message: "Specification Name can only include letters",
          },
        ]}
      >
        <Input
          type="text"
          name="name"
          maxLength={30}
          className="w-100"
          placeholder="Specification Name"
          onChange={onInputChange}
          value={specName}
        />
      </Form.Item>
      <Form.Item
        label="Field Type"
        name="field_type"
        rules={[
          {
            required: true,
            message: "Field Type is required",
          },
        ]}
      >
        <Select
          id="specValId"
          placeholder="Specification Value Type"
          className="w-100"
          onChange={onSelectChange}
          value={valueType}
        >
          <Select.Option value="single">Single Value</Select.Option>
          <Select.Option value="multi">Multi Values</Select.Option>
        </Select>
      </Form.Item>
      {valueType == "" || valueType == "multi"
        ? renderMultipleSpecValues()
        : ""}
      <Form.Item label="Required ?" name="is_required">
        <Checkbox
          name="is_required"
          checked={checked}
          value={checked}
          onChange={onChange}
        />
      </Form.Item>
      <Form.Item
        wrapperCol={{
          ...layout.wrapperCol,
          offset: 4,
        }}
      ></Form.Item>
    </Form>
  );
}

export default SpecificationForm;
