import { Form, Button, Input, Divider } from "antd";
import { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";

const DomainDetails = ({ domainValues, setDomainValues, handleSubmit }) => {
  const [formLayout, setFormLayout] = useState("horizontal");
  const [form] = Form.useForm();  
  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 12 },
  };

  const handleValueChange = (test, event) => {
    const newValues = {
      ...domainValues,
      [event.target.name]: event.target.value,
    };
    setDomainValues(newValues);
  };

  return (
    <>
    <Divider className="mt-5"/>
      <div className="card mt-2">
        <div className="container">
          <div className="row align-items-center">
            <div className="bg-secondary-color container pt-3">
              <h5>Domain Setup</h5>
            </div>
            <div className="container">
            <Form
              labelAlign="left"
              {...formItemLayout}
              layout={formLayout}
              name="SaveDomainDetails"
              onFinish={handleSubmit}              
            >
              <Form.Item
                label="Domain URL"
                name="domainUrl"
                className="text-left mt-2 h1 pb-1"
                style={{ marginTop: "1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter domain url!",
                  },
                  { type: "url", warningOnly: true, message: "invalid url" },
                ]}
              >
                <Input
                  name="domain_url"
                  maxLength={150}
                  className="w-100"
                  placeholder="Please enter domain url"
                  onChange={(event) => {
                    handleValueChange(null, event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Database Name"
                name="dbName"
                className="text-left mt-2 h1 pb-1"
                style={{ marginTop: "1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter database name!",
                  },
                ]}
              >
                <Input
                  type="text"
                  name="database_name"
                  placeholder="Please enter database name"
                  maxLength={50}
                  className="w-100"
                  onChange={(event) => {
                    handleValueChange(null, event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Database Port"
                name="dbPort"
                className="text-left mt-2 h1 pb-1"
                style={{ marginTop: "1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter database port!",
                  },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Port must be a number!",
                  },
                ]}
              >
                <Input
                  name="database_port"
                  placeholder="Please enter database port"
                  maxLength={4}
                  className="w-100"
                  onChange={(event) => {
                    handleValueChange(null, event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Database Username"
                name="databaseUsername"
                className="text-left mt-2 h1 pb-1"
                style={{ marginTop: "1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter database username!",
                  },
                ]}
              >
                <Input
                  type="text"
                  name="database_username"
                  placeholder="Please enter database username"
                  maxLength={50}
                  className="w-100"
                  onChange={(event) => {
                    handleValueChange(null, event);
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Database Password"
                name="databasePassword"
                className="text-left mt-2 h1 pb-1"
                style={{ marginTop: "1%" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter database password!",
                  },
                ]}
              >
                <Input.Password
                  type="text"
                  size="small"
                  name="database_password"
                  placeholder="Please enter database password"
                  maxLength={128}
                  className="w-100"
                  onChange={(event) => {
                    handleValueChange(null, event);
                  }}
                />
              </Form.Item>
              <div
                className="text-center mb-4 mt-4"
              >
                <Button type="primary" htmlType="submit" className="text-bold" icon={<SaveOutlined />} style={{ borderRadius: "15px" }}>
                  Save Domain and send mail
                </Button>
              </div>
            </Form>
            </div>
          </div>
        </div>
      </div>      
    </>
  );
};
export default DomainDetails;
